/**
 * @file
 * @author  fangsimin
 * @date    2020-02-09 21:29:35
 * @last Modified by    fangsimin@baidu.com
 * @last Modified time  2021-06-25 15:32:10
 */

import state from './state-ja';
import country from './country-ja';
export default {
    permissions: {
        legalReTip: '会社所在地',
        legalPerson: '法人所在地',
        companyLegalPerson: '会社の法人所在地',
        roleList: '役割リスト',
        roleName: '役割',
        rolePrivileges: '役割権限',
        announcementEmail: '情報共有メール'
    },
    newDashboradBreadCrumbs: {
        account: 'ホーム',
        accounts: 'アカウント',
        companys: '会社',
        campaign: 'キャンペーン',
        campaigns: 'キャンペーン',
        ad: '広告',
        billCampaign: '活动'
    },
    monthBill: {
        agencyAccountName: '代理店アカウント',
        monthBillTitle: '月次請求',
        settlementCurrency: '請求金額',
        settlementCurrencyRemark: '税込請求金額（決済通貨）',
        montlyExchangeRate: '為替レート',
        settlementSpendWithTax: '税込',
        spendPlusTax: '費用',
        consumptionTax: '消費税',
        consumptionTaxRemark: '消費税',
        spendPlusTaxRemark: '費用(税抜)',
        settlementSpendWithTaxRemark: '請求金額(税込)，請求金額(税込)=費用(税抜)+消費税',
        monthlyExchangeRate: '為替レート',
        agentName: '代理店アカウント',
        updataTitile: '月次明細更新日：翌月3日',
        monthlyExchangeRateRemark: '適用為替レートについて：The Federal Reserve System、前月の平均為替レートで計算されます。<a href="http://www.federalreserve.gov/releases/h10/hist/default.htm">http://www.federalreserve.gov/releases/h10/hist/default.htm</a>'

    },
    menuData: {
        oppoSDK: 'oem 报表',
        oppoSDKTitle: 'oem 报表',
        popInCreativeMakerTitle: 'popIn Creative Maker',
        receivablesTitle: 'Discovery 請求額',
        receivables: 'Discovery 請求額',
        monthlyBill: '対外確定請求',
        monthlyBillTitle: '対外確定請求',
        msgCenter: '通知の受信',
        notification: 'お知らせ',
        notificationTitle: 'お知らせ',
        monthBillTitle: '月次請求',
        amConfigTitle: '設定',
        amConfig: '設定',
        readCreateTitle: 'READタグを発行',
        discoveryReadCreateTitle: 'READタグを発行',
        minBid: '広告最適化するため、目標入札は{minBid}以上設定してください。',
        brandReason: '広告主名・ブランド名はプレミアム媒体で配信できないので、担当窓口までお問い合わせください。',
        imgReason: 'NG理由：画像或いはクリエティブタイトル。',
        campaignReason: 'NG理由：配信先LP内容。',
        textOnImageReason: '広告規約違反のため、担当者に連絡して詳細をもらってください。',
        msnRejectionReason: 'この広告キャンペーンは一部メディアの審査基準に満たさないため審査落ちしました',
        sexualRelatedReason: 'この広告キャンペーンはセクシュアルに関するポリシーにより審査落ちしました',
        tobaccoProductsReason: 'この広告キャンペーンは未成年に関するポリシーにより審査落ちしました',
        textOnImageMediaType: 'MSN審査カテゴリフィルター',
        tagReason: 'このキャンペーンは一部のプレミアム媒体にブロックされた。理由：商材{tagName}',
        uploadWaring: 'The platform service will go under regular maintenance for 6 hours in the near future, ',
        uploadWaringMore: 'more.',
        uploadText: 'The platform service will go under regular maintenance for 6 hours, starting between 10:00 pm on January 15, 2022 to 4:00 am on January 16, 2022 in the EST time zone. We recommend planning your campaign management accordingly.<br><br>Converted Time Zones:<br><br>UTC+8: 11:00 am on January 16, 2022  to 5:00 pm on January 16, 2022<br>UTC+0:  3:00 am on January 16, 2022 to 9:00 am on January 16, 2022',
        multiDimension: 'マルチレポート',
        multiDimensionTitle: 'マルチレポート',
        wrongList: '一括追加した {wrongList}ドメインは間違っています。',
        downList: 'ドキュメントをダウンロードするには、ここをクリックしてください。',
        domainSuccess: 'アップロードされたドメイン{correctLenght}',
        searchSimilarBtnTitle: '一括審査',
        searchSimilar: '一括審査',
        stripeActionTitle: 'Stripe',
        PaymentManagement: '支払管理',
        PaymentManagementTitle: '支払管理',
        ROIAnalysisTitle: 'ROI分析',
        campaignTitle: '新規キャンペーン',
        dashboardTitle: 'ダッシュボード',
        new_dashboardTitle: 'ダッシュボード',
        auditTitle: '広告レビュー',
        ecommerceToolTitle: 'Eコマースツール',
        lPManagementTitle: 'LP管理',
        oPManagementTitle: 'OP管理',
        productManagementTitle: '商品管理',
        domainManagementTitle: 'ドメイン管理',
        testTitle: 'テスト',
        visualTestTitle: 'ビューアブルテスト',
        visualTestBannerTitle: 'バナービューアブルテスト',
        generateLocalPageWrapper: 'ランニングページ生成',
        generateLocalPageWrapperTitle: 'ランニングページ生成',
        checkStatusTitle: '広告コールバック',
        administrator: '管理者',
        financialTitle: '財務管理',
        monthlyBillRelease: 'Monthly Bill Release',
        monthlyBillSave: 'Monthly Bill Save',
        creditLineTitle: '財務リスク',
        roiMgrTitle: 'ROIレポート',
        incomeTitle: '売掛金',
        payTitle: '支払い金額',
        offlineTitle: 'オフライン',
        applyTitle: 'タイトルを適用',
        agencyManagementTitle: '代理店管理',
        permissionTitle: '権限管理',
        accountTitle: 'アカウント管理',
        userTitle: 'ユーザー権限',
        bdOpenAccountTitle: 'BDアカウント追加',
        roleTitle: '役割リスト',
        balance: '利用可能な残高',
        adListTitle: '広告',
        dataReportTitle: 'データレポート',
        amTitle: '運用ダッシュボード',
        amDashboardTitle: '分析',
        icafeTitle: 'トラッカー',
        sampleTitle: 'サンプル',
        adTooltipText: '少なくとも1つのキャンペーンを選択してください',
        pixel: 'ピクセル',
        pixelTitle: 'ピクセル',
        pixelConversion: 'コンバージョン',
        conversionListTitle: 'CV＆READリスト',
        readListTitle: 'READ',
        conversionCreateTitle: '新しいコンバージョン',
        pixelCreate: '新しいコンバージョン',
        account: 'アカウント',
        accounts: 'アカウント',
        rechargeTitle: 'チャージ',
        payActionTitle: 'チャージ',
        stripeAction: 'stripe',
        showbilling: '請求を表示',
        helpCenterTitle: 'ヘルプセンター',
        gettingStartTitle: '開始',
        createAndManageCampaignTitle: 'キャンペーン管理',
        priceAndBillTitle: '入札&チャージ',
        qaCenterTitle: 'FAQ',
        faqTitle: 'FAQ',
        adPolicyTitle: '広告ポリシー',
        reportAndOptimizingTitle: 'レポート＆最適化',
        diagnosisTitle: '広告診断',
        bidAnalysisTitle: '入札分析',
        pageBidTitle: 'AD BID構成',
        monthlyReportTitle: '毎月の運用報告',
        customerManagementTitle: '広告主管理',
        wraningWrod: '注意：入札は通常のネットワーク範囲を超えています。',
        cpcWraningWrod: 'CPCを{cpc}から{cpcData}に調整し、調整振幅は通常レベルより高いです。続けますか？',
        dayWraningWrod: '毎日の予算を{day}から{dayData}に調整しましたが、調整は通常のレベルを超えています。続行するかどうかを確認してください。',
        conversions: 'Conversions',
        conversionsTitle: 'Discovery Conversion',
        siteTip: 'キャンペーン下のあるsite CPCが{siteCpc}より低い場合には、自動的に{siteCpc}に調整されます。',
        cpcTips: '重要：こちらのアカウントの設定通貨は',
        cpcTips2: 'です'
    },
    login: {
        emailLogin: 'メールアドレスでログイン',
        thirdPartyLogin: 'サードパーティログイン',
        forgetPwd: 'パスワードを忘れました',
        haveNoAccount: '',
        register: '開始',
        email: 'Email',
        pwd: 'パスワード',
        loginText: 'ログイン',
        pwdBefore: '旧パスワード',
        pwdAfter: '新パスワード',
        confirmPwd: 'パスワード確認',
        account: 'アカウント名',
        loginEmail: 'ログインEmail'

    },
    button: {
        subimtAudit: '入稿',
        subimtAuditTitle: '（審査は1〜3営業日)',
        backCheck: 'Check',
        confrimReject: 'Confrim',
        search: 'Search',
        addCreative: '新クリエイティブを追加',
        trash: '削除',
        reachOfLandingPage: 'Landing Page 誘導率',
        checkoutConversion: '購買動作CVR',
        budgetSpend: '予算超過率',
        offerConversion: 'Offer CVR',
        purchaseConversion: '購入CVR',
        apiToken: 'ApiTokenをコピーする',
        filterCols: '表示項目変更',
        export: 'エクスポート',
        reset: 'リセット',
        apply: '更新',
        confirm: '更新',
        cancel: '戻る',
        previous: '戻る',
        next: '次へ',
        submit: '提出',
        audit: '申請',
        revise: 'キャンペーン修正',
        total: 'トータル',
        edit: '編集',
        operate: '操作',
        detail: '詳細',
        authorize: '許諾',
        adminAuthorize: 'Admin権限付与',
        advertiseAuthorize: '広告主ロールの権限のみ',
        ignore: '無視',
        add: '追加',
        query: '検索',
        recreate: 'キャンペーン再作成',
        createConversion: 'CV目標作成',
        createCampaign: '広告を作成する',
        UpdateConversion: 'CV目標を更新',
        archived: 'アーカイブ',
        editMore: 'より編集する',
        delete: '削除',
        pass: 'OK',
        copy: 'コピー',
        import: '挿入',
        create: '作成',
        history: 'ヒストリー',
        pageAudit: '審査',
        preview: 'プレービュー',
        rename: '名前修正',
        sendReport: 'レポートを送信',
        save: '保存',
        notSave: '保存しない',
        openEditor: '編集ツールを開く',
        deleteTitle: '削除するかどうか',
        deletePage: 'ヒント',
        innerStatus: '休眠状態'
    },
    dashboard: {
        totalBalance: '利用可能残高',
        balanceTip1: '一部のアカウントの利用可能残高が少なくなっており、配信停止の可能性があるため、早急にチャージを行ってください。',
        balanceTip2: '一部アカウントの利用可能残高が使い切られました。配信を継続する場合は、早急にチャージを行ってください。',
        balanceTip3: '一部アカウントの利用可能残高が少ない、または使い切られました。配信を継続する場合は、早急にチャージを行ってください。',
        totalBalanceTip1: 'アカウントの利用可能残高が少ないです。残り<span style="color: #e92754;">{num}</span>日で配信停止します、クライアントに連絡してください',
        totalBalanceTip2: 'アカウントの利用可能残高が使い切りました。配信継続する場合、クライアントに連絡してください',
        balanceStatus: '残高状況',
        sufficientBalance: '利用可能残高十分',
        lowBalance: '残高の利用可能日数１週間',
        tightBalance: '残高の利用可能日数３日間',
        insufficientBalance: '利用可能残高不足',
        totalBalanceTip: '総利用可能残高 = 累計チャージ + クレジットライン + クーポン - 累計cost。アカウントに実際チャージした金額は請求書を参考してください。',
        searchDetail: '詳しく',
        collapseDetail: '閉じる',
        smbLearningCompletedTip: 'SmartBidの学習が完了したため、cpcの修正はできかねます',
        maxcvCpcTip: 'MaxCVご利用の場合はcpcの修正できかねます',
        siteBlockAccountContentTip: '注意：アカウントベースでドメインをブロックした場合、キャンペーンベースでのdomainブロックの解除ができなくなります。',
        siteBlockCampaignContentTip: '注意：キャペーンベースでドメインをブロックした場合、アカウントでのdomainブロックの解除ができなくなり、キャンペーンでdomainを解除してください。',
        viewSelected: '選択項目の確認',
        adReview: '{num}個の広告を選択',
        assetCampaign: '{num}個のクリエイティブを選択',
        assetReview: '{num}個のキャンペーンを選択',
        accountReview: '{num}個のアカウントを選択',
        campaignReview: '{num}個のキャンペーンを選択',
        specifyCampaignTip1: 'キャンペーンIDが正しくありません。',
        specifyCampaignTip2: 'このキャンペーンはすでに新しい活動として継承されました。他のIDを指定してください。',
        specifyCampaignTip3: 'このキャンペーンは過去6日間、CV<3でデータ継承の条件を満たしていません。',
        specifyCampaignTip4: 'このキャンペーンでは、過去14日間にcv≥3のクリエティブは存在しないため、他のIDを指定してください。',
        specifyCampaignTip5: '国、プラットフォーム、OS、ブラウザの設定が一致していないため、学習効果を保証できません。本当に提出しますか？',
        assignMatch: '継承元ID指定',
        inheritCampaignId: '廣告活動ID',
        inherit: 'データ継承',
        matchType: 'マッチング方法',
        autoMatch: '自動マッチング',
        exceedLimitTip: 'このアカウントはデータ継承の最大キャンペーン数を超えています。',
        notInheritTip: 'はデータ継承の使用条件を満たしていません。削除後も残りのキャンペーン編集を続けますか？',
        notInheritTip2: 'このキャンペーンはデータ継承の使用条件を満たしていません',
        matchDegree: '適合性',
        excellent: '優れる',
        fine: '良い',
        noMatch: '適合のデータが見つかりませんでした',
        matching: 'マッチングしています',
        inheritEnd: '継承が終了しました',
        dateHourTip: '-時間別データの検索範囲は過去1年間の中で最大14日間です。',
        ocpcphase: '目標CPAに合わせて入札価格を自動調整中',
        ocpcNewPhase: '目標CPAに合わせて入札価格を自動調整中',
        dateRemind: 'CV関連データの最新更新時刻:{month}/{data}/{year} {hour}:{minute} {meridian} <span class="date-remind-color">({timezone})</span> 次のデータ更新までは最大40分程度かかります。',
        test: '<span>123</span>',
        downloadTypeDay: '日別レポート',
        downloadTypeSummary: '期間指定レポート',
        accountFilter: 'アカウントフィルター',
        customerSource: '顧客ソース',
        vcpm: 'vCPM',
        country: '国フィルター',
        readChart: 'READ 分析',
        readChartTitle: 'READ 报表',
        total: '合計',
        account: 'アカウント',
        allAccounts: 'すべてのアカウント',
        colManagement: 'アカウント',
        colCampaigns: 'キャンペーン',
        colAds: '広告',
        colCost: '料金',
        colTrafficAcquisition: 'トラフィック獲得',
        colAudienceMetrics: 'オーディエンスメトリクス',
        mcv: 'MCV（旧タグ）',
        cv: 'CV',
        cvr: 'CVR',
        mcvr: 'MCVR（旧タグ）',
        mcpa: 'MCPA',
        adSize: 'サイズ',
        colTargeting: 'ターゲティング',
        dimensionFilter: '寸法フィルター',
        conversiontype: 'コンバージョンタイプ',
        newConversiontype: 'Discovery Conversion (MCV)',
        mediagoCv: 'MediaGo Conversion (Lead&purchase)',
        currencyType: 'マルチ通貨スイッチャー',
        colPerformance: 'パフォーマンス',
        payPal: 'PayPal',
        StripeVisa: 'Stripe-Visa',
        StripeMastercard: 'Stripe-Mastercard',
        productName: '商品名',
        otherPage: 'ドメインの解析を待っています',
        blocked: '（アカウントレベルでブロック）',
        adListColSize: '広告サイズチェックボックス',
        auditPublisher: 'レビューの詳細メディア分類',
        domainSspColumn: 'ドメインタブssp列',
        dashboardDomainBlock: 'ドメインブロック',
        uniformlyFlag: 'ペーシング',
        campaign: 'キャンペーン',
        campaignName: 'キャンペーン名',
        status: 'Status',
        adStatus: 'Ad Status',
        campaignStatus: 'キャンペーンの状況',
        spend: 'Cost',
        impression: 'vimp',
        click: 'クリック数',
        clickRate: 'CTR',
        startTime: '開始時間',
        endTime: '開始時間',
        searchPlaceholder: 'キャンペーン/アカウントの検索',
        filterBtn: 'フィルター',
        refreshBtn: '更新',
        viewSelectedBtn: '選択したビュー',
        editSelectedBtn: '選択したものを編集',
        review: 'レビューの詳細',
        discarded: 'このキャンペーンはアーカイブされています',
        reviewResult: 'レビュー結果',
        sppNotWork: 'こちらのキャンペーンは何らかの不具合が発生しており、配信できない状態です。お手数ですが担当者までお問い合わせください。',
        reviewAssetWaiting: '審査には最大3営業日程かかる場合がありますので予めご了承ください。',
        reviewAssetNotPass: 'こちらの広告は審査落ちとなっております。<br/>詳細は右側の「レビューの詳細」アイコンにてご確認ください。',
        reviewAssetReason: 'The creative isn\'t able to run due to policy restrictions',
        reviewAssetReason1: 'The creative can be run, but can not be shown in all situations due to policy restrictions',
        reviewAssetNotPass1: 'こちらの広告は、配信可能ですが極一部の配信先媒体で審査落ちとなっております。',
        discardedAsset: 'This asset has been archived',
        newReviewNotPass1: 'こちらのキャンペーンは、配信可能ですがキャンペーン配下の一部の広告が極一部の配信先の媒体で審査落ちとなっております。',
        newReviewAssetWaiting: 'こちらの広告は、配信可能ですが極一部の配信先媒体で審査落ちとなっております。',
        newReviewNotPass: 'こちらのキャンペーンは、配下の一部の広告のみ審査落ちとなっております。<br/>審査通過済みの広告については配信可能です。',
        reviewWaiting: 'この広告は審査中です。<br/>3営業日以内に審査を終了します',
        reviewHasRemain: 'ステータスが審査中の広告数{wait_audit_asset_num}/{asset_num} <br/>',
        reviewWaiting1: '一部のクリエイティブが現在審査中です。<br/>審査には最大3営業日程かかる場合がありますので予めご了承ください。<br/>なお、審査通過済みのクリエイティブは配信可能な状態です。',
        reviewNotPass: 'この広告は拒否されました，<br/>右クリック&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;AMに連絡してください',
        reviewNotPass1: 'このキャンペーンには、承認されていないクリエイティブがあります,<br/>右クリック&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;AMに連絡してください',
        // reviewNotPass: 'このキャンペーンの一部の広告は拒否されました。右側のボタンをクリックして詳細を確認してください',
        auditDetails: '詳細',
        campaignFilter: 'キャンペーンフィルター',
        adSetFilter: 'キャンペーンフィルター',
        adFilter: 'キャンペーンフィルター',
        datetimeRange: '開始&終了',
        and: 'and',
        selectDate: '日付を選択',
        on: 'On',
        off: 'Off',
        CPC: 'CPC',
        totalBudget: '総予算',
        roas: 'ROAS',
        dailyBudget: '日予算',
        campaignDetails: 'キャンペーン詳細',
        campaignCopy: 'キャンペーン複製',
        adSetsDetails: '広告セットの詳細',
        adsDetails: '広告詳細',
        campaignEdit: 'キャンペーン修正',
        adCopy: 'コピー広告',
        adEdit: '広告編集者',
        creative: '素材',
        utcStandard: 'UTC標準時',
        objective: '目的',
        accountsName: 'アカウント名',
        adCreator: 'Ad作成者',
        // // 監査
        assetTitle: 'タイトル',
        asset: '画像',
        assetName: '素材名',
        adInformation: 'Ad Information',
        accountName: 'アカウント名',
        adName: 'Ad Name',
        submitTime: '送信時間',
        start: '開始時間',
        userId: 'User ID',
        reviewItems: '審査',
        pass: '通過',
        fail: '一部の広告はNGです。',
        invalidReason: '理由',
        comments: 'レビューアによるコメント',
        reviewer: '審査担当',
        reviewTime: '審査時間',
        colCfgTitle: '表示項目',
        colDimension: '寸法',
        colAdPerformance: '広告パフォーマンス',
        colContentPerformance: 'コンテンツパフォーマンス',
        campaignId: 'キャンペーンID',
        accountId: 'アカウントID',
        colStatus: '投下状態',
        colStart: '開始',
        colEnd: '終了',
        colSubmissionTime: '送信時間',
        colSpend: 'Cost',
        // colBalance: 'キャンペーンバランス',
        colBalance: '残り総予算',
        colTotalBudget: '総予算',
        colDailyBudget: '日予算',
        colDetail: '詳細',
        colDelivery: 'Delivery',
        colOther: 'その他',
        colCpc: 'CPC',
        colAvgCpc: '平均CPC',
        colVCpm: 'vCPM',
        colImp: 'Viewed imp',
        colClick: 'Click',
        colCtr: 'CTR',
        colConversion: 'CV',
        newColConversion: 'CV',
        cvViewContent: '商品LPに遷移',
        cvAppInstall: 'インストール',
        cvCompleteRegistration: '登録完了',
        cvAddToCar: 'カートに追加',
        cvAddPaymentInfo: '支払い情報を登録',
        cvSearch: '検索',
        cvStartCheckout: 'チェックアウトを開始します',
        cvPurchase: '購入完了',
        cvAddToWishlist: 'お気に入りに追加',
        cvLead: 'リード',
        cvOther: 'その他',
        colAccount: 'アカウント情報',
        conversions: 'CV',
        conversion: 'CV',
        conversionRate: 'CVR',
        colConversionRate: 'CVR',
        costPerConversion: 'CPA',
        colConversionPrice: 'CPA',

        newConversion: 'CV',
        newConversions: 'CV',
        newConversionRate: 'CVR',
        newColConversionRate: 'CVR',
        newColAgency: '代理店',
        newColAgencyInput: '代理店名/代理店ID',
        newColAgencyName: '代理店名',
        newColAgencyId: '代理店ID',
        newCostPerConversion: 'CPA',
        newColConversionPrice: 'CPA',
        campaignMultiEdits: {
            selectIdsNum: '編集しているキャンペーン数1 | 編集しているキャンペーン数 {num}',
            selectAssetIdsNum: '編集しているクリエティブ数 {num} | 編集しているクリエティブ数 {num}',
            dialogTitle: 'バルク編集',
            status: 'Status',
            bid: '入札',
            perCampaign: 'キャンペーンあたり',
            dailySpendCap: '日予算',
            lifetimeSpendCap: '総予算',
            bulkEditSuccess: '正常に更新されました',
            assetBulkEditSuccess: '正常に更新されました',
            bulkArchivedSuccess: '正常に更新されました',
            assetBulkArchivedSuccess: '正常に更新されました'
        },
        accountList: {
            searchPlaceholder: 'アカウント名/アカウントIDを検索する'
        },
        adList: {
            head: '広告リスト',
            adNum: '作成済み：$num条',
            id: '広告ID',
            status: '掲載状態',
            fileName: 'ファイル名',
            name: '素材名',
            image: 'クリエイティブ',
            size: 'サイズ',
            title: 'タイトル',
            brandName: 'ブランド名',
            spend: 'Cost',
            impression: 'vimp',
            ctr: 'CTR',
            url: 'URL',
            searchPlaceholder: 'タイトル/広告ID入力/URL',
            click: 'Click',
            webUrl: 'URL',
            cpc: 'CPC',
            campaignId: 'キャンペーンID',
            campaignName: 'キャンペーン名',
            assetId: 'Asset ID',
            tableFooterMsg: '{n} 広告作成中',
            searchAssetId: 'タイトル/Asset Id/URL',
            failedMsg: '画像変換失敗'
        },
        domain: {
            domainName: 'Site',
            spend: 'cost',
            impression: 'インプレッション',
            click: 'Click',
            ctr: 'CTR',
            vcpm: 'vCPM',
            acpc: '平均CPC',
            suggestedCpc: '推奨CPC',
            cpc: 'CPC',
            searchPlaceholder: 'ドメイン検索',
            blockBtn: 'ブロック',
            blockDisableBtn: 'ブロック禁止',
            resumeBtn: '再開',
            resumeDisableBtn: '再開禁止',
            ssp: 'SSP',
            domainNameRemark: 'ドメイン数値更新時間差は2時間になります。'
        },
        domainTitle: 'Sites',
        // data report column setting
        colBreakDownBy: '内訳',
        colCampaignAds: 'キャンペーン/広告',
        suffixBreakDownBy: '最大で２つ選択できます',
        colData: 'データ',
        colEarning: '売上',
        yesterday: '昨日',
        today: '今日',
        last7days: '過去7日間',
        last30days: '過去30日',
        archivedMsg: 'アーカイブしてもよろしいですか？（アーカイブしたキャンペーンもしくはクリエイティブを編集できませんが、データは保存されます。）',
        archivedMsg2: 'このキャンペーンをアーカイブしてもよろしいですか？（アーカイブしたキャンペーンの編集はできませんが、でーたは保存されます。）',
        archivedAssetMsg: 'このクリエイティブをアーカイブしてもよろしいですか？（アーカイブしたクリエィティブの編集はできませんが、データは保存されます。）',
        filterList: 'キャンペーンリストの設定',
        adFilterList: '広告リストの設定',
        hideArchivedCmps: 'アーカイブキャンペーンを隠す',
        hideArchivedAds: 'アーカイブキャンペーンを隠す',
        onOffBoth: 'すべてのキャンペーンを表示します',
        adOnOffBoth: 'すべてのキャンペーンを表示します',
        showAllCampaigns: 'すべてのキャンペーンを表示（アーカイブキャンペーンを含む）',
        onlyArchivedCmps: 'アーカイブキャンペーンを表示するだけです',
        showAllAds: 'すべてのキャンペーンを表示（アーカイブキャンペーンを含む）',
        onlyArchivedAds: 'アーカイブキャンペーンを表示するだけです',
        selectedCampaigns: '{num}が選択されました',
        adInfo: 'キャンペーン数{num}',
        domainCampaignTip: '{num}キャンペーン数',
        domainAccountTipAll: '全部アカウント',
        domainAccountTip: '{num}アカウント数',
        mediaBlackWhiteListTitle: '媒体アカウント/Site白名单',
        mediaType: '広告主',
        selectedZipCode: '郵便番号: {num} 个',
        campaignSetOcpc: 'OCPC',
        campaignSetEcpc: 'ECPC',
        bidByDomain: 'bid by domainCPC',
        suggested: '推奨CPC',
        readChartObj: {
            avgReadTime: '平均読了時間',
            avgReadRate: '平均読了率',
            totalReadTime: '総読了時間',
            readPart: 'READ分布',
            readAnalysis: 'READ解析',
            pv: 'PV数',
            readRate: '読了率',
            readUserPart: 'READ ユーザー層',
            tiaoReadPart: '流し見層',
            shuReadPart: '熟読層',
            yueReadPart: '閲覧層',
            popInAvgReadRate: 'popIn流入時平均読了率',
            inAvgReadRate: '通常流入時',
            contentAnalysis: 'コンテキスト解析',
            clickNumber: 'クリック数',
            imp: '表示回数',
            ctr: 'CTR',
            outMediaAnalysis: '外部メディアでのコンテンツ消費スタイル解析',
            normalReadContent: '普段読んでいる記事コンテンツ',
            normalReadTimePeriod: '普段記事を読む時間帯',
            timePeriod: '時間帯',
            category: 'カテゴリー'
        }
    },
    create: {
        newCountryLang: {
            Japanese: 'Japanese',
            English: 'English',
            Chinese: 'Chinese',
            Thai: 'Thai',
            Malay: 'Malay',
            Indonesian: 'Indonesian',
            Korean: 'Korean',
            allLanguageSplit: '言語選択機能',
            LanguageErrTip: '言語を選択してください'
        },
        maxCvTipFirst: '1.キャンペーン開始後１週間前後の日予算は、目標CPAの5~10倍で設定することをおすすめします',
        maxCvTipLast: '2.MAXCV使用時はCPA高騰を防ぐために、日予算調整は1日1回、さらに調整幅は30%以内に抑えることをおすすめします',
        dataInheritance: 'Data Inheritance',
        dataInheritanceInternal: 'Data Inheritance（internal）',
        imgUploadSizeUpperLimit: 'CRサイズ変更',
        smartBidmaxcv: 'SmartBid Max CV',
        smartBidmaxcvTip: 'SmartBid Max CV について- \nCV数の最大化を重視したアルゴリズムです。ご予算内で可能な限り多くのCV獲得を目指します。',
        submitTitle: '画像・タイトル等の広告クリエイティブにおいて、氏名・写真・談話および肖像・商標・著作物等を無断で使用していない。',
        requiredTitle: '(必須)',
        picSize: '画像サイズ',
        displaySize: 'Banner Ads Upload',
        anySize: 'ネイティブ画像（サイズ任意）',
        recommendedSize: 'バナー配信先画像サイズ',
        plsSelectOne: '画像サイズを選択してください',
        plsSize: '[{size}]の画像で入稿してください',
        campaignCategoryInfo: 'Define a category for your campaign content.The effectiveness of campaign optimization is highly related to the category, please select carefully.',
        campaignCategory: 'Campaign Category',
        createCampaignSetps: 'Create A Campaign',
        addAds: 'Add Creatives',
        conversionGoal: 'Conversion Goal',
        basicSettings: 'General Settings',
        campaignTargeting: 'Campaign Targeting',
        marketingObjective: 'Marketing Objective',
        scheduleAndBudget: 'Schedule and Budget',
        smartBid: 'Smart Bid',
        smartBidLearning: 'Smart Bid (Learning)',
        smartBidLearningTip: 'MediaGo is learning how to best deliver your campaign. Performance may improve further. Please avoid editing your ad set during the learning phase. This phase uses Fixed Bid.',
        smartBidLearningCompleted: 'Smart Bid (Learning Completed)',
        smartBidLearningCompletedTip: 'The learning phase has complete for this campaign. Smart Bid is already in use.',
        singleUpload: '手動入稿',
        bulkUpload: '一括入稿',
        uploadFile: 'インポート',
        uploadCsvFile: 'CSVファイルをインポート',
        downloadTemplateCsv: 'テンプレートをダウンロード',
        csvUploadTip: '下記リンクよりバルクシートのテンプレートをダウンロードしてください。<br>バルクシートをインポートする際に、テンプレート内の３行目の記入例を削除してください。<br>《入稿項目》<br>入稿URL、UTMトラッキングコード（必須ではない）、カテゴリー、タイトル、広告主名、画像。<br>バルクシートのフォーマットは以下です。<br>・ファイル形式：.csv<br>・⽂字コード︓UTF-8<br>・コンマ区切り',
        dragUpload: 'ドラッグ＆ドロップ',
        plsUploadCsv: 'CSVファイルをアップロードしてください',
        uploadRecord: '	インポートログ',
        csvRecordTip: 'キャンペーンあたり登録可能なクリエティブ(asset)数：最大100',
        uploadTime: 'インポート時間',
        uploadRemark: '備考',
        file: 'ファイル名',
        uploadStatus: 'ステータス',
        audienceTip: '※推奨：全て',
        magnitudeEvaluation: 'ボリューム予測',
        thirdPartyTracking: '3rdパーティートラッキング',
        tagType: 'ラベルタイプ ',
        eventType: 'イベントタイプ',
        url_code: 'リンク/コードを追加します',
        click: 'Click',
        viewImperssion: 'ビジュアルディスプレイ',
        trackingTips: 'ここでは、サードパーティの追跡を追加して、広告プログラムデータをキャプチャし、それを外部プラットフォームに送信して、マーケティング目標を達成できます。 サードパーティのトレースでサポートされているマクロは、UTMでサポートされているマクロと同じです。 もっと理解する注：JavaScriptタグを使用する場合、一部のトラフィックはこのタイプの追跡をサポートしていないため、追跡データは実際のデータとは異なる場合があります。',
        jp: '日本',
        au: 'オーストラリア',
        gb: 'イギリス',
        sg: 'シンガポール',
        my: 'マレーシア',
        th: 'タイ',
        hk: '香港',
        tw: '台湾',
        kr: '韓国',
        id: 'インドネシア',
        preview: 'プレビュー',
        collapse: '閉じ',
        expand: 'を',
        de: 'ドイツ',
        fr: 'フランス',
        it: 'イタリア',
        es: 'スペイン',
        at: 'オーストリア',
        br: 'Brazil',
        mx: 'Mexico',
        nl: 'Netherlands',
        pl: 'Poland',
        se: 'Sweden',
        be: 'Belgium',
        pt: 'Portugal',
        ch: 'Switzerland',
        cz: 'Czechia',
        no: 'Norway',
        dk: 'Denmark',
        hu: 'Hungary',
        deLang: 'ドイツ語',
        itLang: 'イタリア語',
        frLang: 'フランス語',
        // campaign
        createCampaign: 'スケジュールと予算',
        accountName: 'アカウント名',
        campaign: 'キャンペーン',
        objective: '目的',
        promotionObjective: 'キャンペーン目的',
        completeConversion: 'コンバージョン',
        purchaseOrder: '購入完了・申込完了',
        willingnessToInteract: 'リード獲得',
        fillForm: 'サービス申込',
        signUp: '会員登録',
        downLoadData: '資料請求',
        sendMessage: 'Web問合せ',
        browseWebpage: '誘導効率化（CVtagによる計測実施）',
        reputation: '認知',
        enhanceBrand: 'ブランド認知・誘導数最大化',
        objectiveTraffic: 'トラフィック',
        objectiveAwareness: '認知',
        objectiveConversions: 'CV',
        objectiveLead: 'リード',
        campaignName: 'キャンペーン名',
        campaignStatus: 'キャンペーンの状況',
        startAndEnd: '開始&終了',
        startTime: '開始',
        endTime: '終了',
        setTime: '時間を設定',
        immediately: 'すぐに開始',
        indefinitely: '終了日設定なし配信',
        dayparting: '配信時間帯指定',
        selectDay: 'キャンペーンスケジュールの日付と時間を選択',
        dailySpendCap: '日予算',
        dailyCapTip: '日予算の下限は2000円です。\n※設定された日予算は20%程度上回る可能性がございます。',
        lifetimeSpendCap: '総予算',
        lifetimeSpendCapTip: '毎月または合計キャンペーン予算を選択できます',
        lifetimeBudget: '総予算',
        monthlyBudget: '月予算',
        pacing: '配信速度',
        // ad set
        search: '検索...',
        English: '英語',
        Spanish: 'スペイン語',
        Japanese: '日语',
        All: 'すべての',
        lang: '言語',
        countryUsa: '米国',
        countryCanada: 'カナダ',
        adSetTitle: '目標設定',
        adSets: '広告セット',
        adSetsName: '広告セット名',
        adSetsStatus: '状態',
        adFormat: '広告フォーマット',
        placement: '配置',
        size: 'サイズ',
        homepage: 'ホームページ',
        audience: 'コンテキスト',
        audienceAsiaTip: '注：選択されたTAはアジアでボリュームは制限されています。',
        audienceAmericaTip: '注：選択されたTAは欧米でボリュームは制限されています。',
        industry: 'カテゴリー',
        industryPh: 'カテゴリを選択してください',
        industryInfo: `上記のランディングページの製品またはサービスのカテゴリを説明するには、最大2セットのフレーズを選択してください。正確な説明は、アルゴリズムが自動的に適切なトラフィックをキャンペーンに適応させるのを助けます。
        * カテゴリを任意に変更しないでください。`,
        location: '国',
        state: '州',
        us_state: '州',
        ca_state: '地域',
        au_state: '州',
        gb_state: '地域',
        jp_state: '都道府県',
        tw_state: '地域',
        id_state: '地域',
        kr_state: '地域',
        hk_state: '地域',
        sg_state: '地域',
        my_state: '地域',
        th_state: '地域',
        // 必须按照缩写_placeholder的格式填写 有多个地方用到
        us_placeholder: '州/郵便番号',
        ca_placeholder: '地域',
        au_placeholder: '州',
        gb_placeholder: '地域',
        jp_placeholder: '地域',
        tw_placeholder: '地域',
        id_placeholder: '地域',
        kr_placeholder: '地域',
        hk_placeholder: '地域',
        sg_placeholder: '地域',
        my_placeholder: '地域',
        th_placeholder: '地域',
        targetBid: '入札条件',
        contentCategory: 'コンテンツカテゴリー',
        device: '端末',
        os: 'OS',
        osv: 'Win10 AD-Targeting',
        userPackage: 'ユーザーパッケージ',
        domainwhitelist: 'モバイルドメイン',
        moreBtn: 'もっと',
        impURL: 'サードパーティートラッキング',
        osvTip: 'これを選択することで、Windows 10をターゲットにします。',
        amountSmall: '注意：ターゲットを絞っているプラ​​ットフォームシステムブラウザの組み合わせはトラフィックが少ないため、広告配信の有効性に影響を与える可能性があります。別のプラットフォームシステムブラウザの組み合わせを試すことをお勧めします。',
        noAmount: '注意：選択したターゲットシステムとブラウザの組み合わせのトラフィックはありません。再選択してください。',
        platform: 'プラットフォーム',
        browser: 'ブラウザ',
        gender: '性别',
        carrier: 'キャリア',
        include: '含む',
        exclude: '除外',
        only: '（）のみ',
        zipcode: 'Zip dode',
        // 需要有一些失败提醒
        locationTip: '完全に情報を入力してください。',
        locationTip1: ' 必須',
        locationCheck: '現地のIPのみ',
        locationCheckTip: '選択すると、当地域のIPのみターゲットします',
        // ad
        allSelect: '全て',
        allSelect1: 'すべて選択',
        createAds: 'クリエイティブ名',
        adName: '広告主名',
        adAssetName: 'クリエイティブ名',
        adStatus: 'ステータス',
        brandName: '広告主名',
        headline: 'タイトル',
        asset: '画像とクリエイティブ名',
        webUrl: '広告遷移先LP',
        upload: 'アップロード',
        reUpload: '再サップロード',
        utmSuffix: 'UTM(トラッキングコード)',
        urlPreview: 'CR組み合わせプレビュー',
        // imageDescription: 'CTRを上げるには、16：9の比率で画像をアップロードし、オブジェクトを中央に保つことをお勧めします（画像の境界から適切な距離を保ちます）。最大画像サイズは2MBです。画像のサイズは600x400ピクセルより大きくする必要があります。',
        imageDescription: '画像の最大サイズは2MB、最小解像度は600x400ピクセルです。',
        // CAMPAIGN_ID: 'Campaign id',
        // AD_ID: 'Ad id',
        // AD_NAME: 'Ad name',
        // PAGE_TYPE: 'Page type, DHP, HP, etc.',
        // AD_STYLE: '広告様式、 infopane, river, 等',
        // REQ_ID: 'リクエストID、削除',
        // BID_TIME_STAMP: '広告の塗りつぶしタイムスタンプ（精度、goでサポートされている精度に基づく）',
        // TRACKING_ID: 'UTM(トラッキングコード) id',
        // CPC: 'この広告のCPC'
        more: '詳細を確認してください',
        utmInfos: 'キャンペーンレベルの追跡用の追跡コードを入力してください。',
        titleInfo: 'タイトルにdynamic macroを設定できます。',
        titleTemplate1: 'この値は、ユーザーが閲覧している地域（州を含む）に置き換えられます',
        titleTemplate2: 'この値は月に置き換えられます。例：1月',
        utmInfoHeader: '推奨されるトラッキングコード:',
        titleInfoHeader: 'そのような:',
        assetPreview: '素材プレビュー',
        adList: 'Ad List',
        adHead: {
            id: 'AD ID',
            name: '素材名',
            image: '画像',
            title: 'タイトル',
            brandName: '広告主名',
            url: '広告遷移先LP',
            searchPlaceholder: 'タイトル/素材名'
        },
        assetAditionInfo: 'より多くのトラフィックがすぐに来ています...',
        closedText: '近日公開...',
        delAdMsg: 'このアセットを削除してもよろしいですか？ （関連する広告はアーカイブされますが、データは保存されます）',
        mdyAdMsg: 'すべてのサイズを維持することをお勧めします。アルゴリズムは、最適化するために最もパフォーマンスの高い広告を選択できます。',
        disableSizeMsg: 'すべてのサイズを維持することをお勧めします。アルゴリズムは、最適化するために最もパフォーマンスの高い広告を選択できます。',
        importZipCode: '郵便番号をインポートします',
        importDomainList: 'ドメインホワイトリストをインポートします',
        selectCsv: 'CSVをアップロード',
        downloadTemplate: 'テンプレート',
        previewTip: 'のコンテンツレコメンドは様々な形式、サイズに適用可能です。設置場所、広告（画像、タイトル、広告主名）はサイトの形式に応じてアレンジ可能です。',
        domainTip: '必要なドメインホワイトリストはCSVファイルを介してアップロードできますが、これらのホワイトリストはプラットフォームの既存のドメインと一致する場合にのみ有効になり、正常に一致したホワイトリストはモバイルターゲットトラフィックに対してのみ有効であることに注意してください。',
        fixedCpc: '固定CPC',
        notCut: '画像を切れないようにする',
        notCutTip: 'チェックしてない場合、画像は掲載される枠に応じてトリミングされる可能性があります。設定後、修正できないので、慎重に設定をお願いします。',
        OCPC: 'OCPC',
        ECPC: 'ECPC',
        ocpcTip: '目標CPAに基づいて入札CPCが自動調整されます。この機能を選択するとドメイン別のCPC入札機能は無効となります。[サポートエリア：日本]',
        cpaMaxChangeTip: 'CPA変動幅を入力してください。より良いCPA獲得するため、自動化学習段階で上限を超える可能性があります。',
        ecpcTip: 'より高いコンバージョン率を得るために、システムはCPC *入札価格の範囲内で動的に入札価格を調整します。この機能を選択すると、ドメインごとの入札単価制もこのクリック単価の変動によって変わります。[サポートされている地域：米国、カナダ、英国、オーストラリア]',
        cpaTypeTip: 'より迅速に機械学習が進むようにするために、CPAタイプを選択した後は設定を変更しないことをお勧めいたします。',
        fisrtStepCpc: '第一段階CPC',
        secondStepTargetCpa: '第二段階目標CPA',
        secondStepTargetMcpa: '第二段階目標MCPA',
        cpaMaxChange: 'CPA変動幅上限',
        cpcChange: 'Bid Multipliers Range',
        targetCpa: '目標CPA',
        cpaType: 'CPA Type',
        targetCpaRequired: '目標CPAを入力してください',
        targetCpanumbered: '目標CPAは数値で入力してください',
        bidType: '入札方法',
        fixedBid: '固定CPC',
        smartBidText: 'OCPC',
        smartBidTargetCPA: 'SmartBid Target CPA',
        smartBidTargetCPATip: 'Target CPAの設定金額が低いため、自動入札は利用できません。',
        smartBidNext: 'Smart Bid',
        smartBid: 'OCPC(サポートエリア：日本・台湾）',
        phaseCpc: '第一段階　固定CPC',
        smartBidTips: 'oCPC：目標CPAと配信実績に基づいて入札CPCが広告リクエスト毎に自動で調整される機能です。\nこの機能を選択するとメディア毎のCPC入札機能は無効となります。[サポートエリア：日本]\n第1段階−モデル学習：自動入札に必要な配信データを収集する期間です。固定CPC価格で入札を行います。\n第2段階−自動入札：第1段階で蓄積された配信データと設定された目標CPAを基に入札CPC価格が自動で調整されます',
        smartBidNextTips: '-Smart Bid（β版）について-目標CPAに応じて入札CPC価格を自動で最適化させる機能です。運用工数削減、広告効果の効率化が期待できます。',
        smartTargetCpaTips: '目標CPA：本キャンペーンにおける目標のCPA(1コンバージョン獲得に対する目標価格)を記入してください。\n設定された目標CPAと配信実績データに基づき配信の最適化を行いますので適切な価格を設定してください。\n※コンバージョン計測にはピクセルタグの設置が必要になります。\n※コンバージョン計測を行わない場合はキャンペーンの目的をCV以外に設定しなおしてください。',
        upToAdsLimitMsg: 'The maximum number of ads for this campaign is {limit}.',
        previewImgTip: '画像比率 ',
        previewImgTitle: 'タイトル',
        previewImgName: 'ブランドネーム',
        theThirdPartyPixelPrompt: '活動データを取得し、マーケティング目標を達成するために外部のプラットフォームに送信するために、第三者のトラッキングリンクを追加することができます。サードパーティのトラッキングがサポートするマクロはUTMのものと同じである。',
        PixelPromptMore: '詳しくはクリックしてください。',
        postback: 'CV計測方法',
        postbackNull: 'None（popIn CV計測無し）',
        mPostbackNull: 'None（MediaGo CV計測無し）',
        postback91App: '91App',
        discovery: 'popIn CVタグ',
        mediago: 'MediaGo CVタグ',
        postbackTip: 'popInのCV・MCVタグを使う場合、「popIn CVタグ」を選択してください。',
        assetInfo: '複数の画像のアップロードをサポート</br>推奨サイズ: 16:9</br>格式：JPG、JPEG、PNG、GIF</br>画像容量：1MB</br>商品/ロゴなどに関する重要な情報が画像の80%以内に収まるようお願いします。',
        assetSingleInfo: '推奨サイズ: 16:9</br>格式：JPG、JPEG、PNG、GIF</br>画像容量：1MB</br>商品/ロゴなどに関する重要な情報が画像の80%以内に収まるようお願いします。',
        assetInfoTip: '画像サイズ：1MB以内',
        gifSizeTip: '；GIF画像サイズ：10MB以内',
        createCampaignBtnText: 'キャンペーン作成',
        campaignNoAssetsTips: 'The Campaign has not uploaded creative yet',
        addAssetsBtnText: 'クリエティブ追加'
    },
    audit: {
        review: 'プレビュー',
        auditStatus: 'Review Status',
        detail: '詳細',
        reAudit: '再監査',
        reviewAds: '再監査',
        status: 'Status',
        inReview: '審査中',
        limited: '一部媒体先審査NG',
        preliminaryReview: '予備審査中',
        secondReview: '第二審査中',
        rejected: '拒否されました',
        rejectedSelect: '一部のキャンペーンは拒否されています。',
        manuallyApproved: '手動で承認',
        bdApproved: 'Baidu承認済み',
        msApproved: 'Publisher承認済み',
        bdRejected: 'Baidu拒否',
        msRejected: 'Publisher拒否',
        adId: 'Ad Id',
        campaignName: 'キャンペーン名',
        accountName: 'アカウント名',
        submittedTime: '送信時間',
        startTime: '開始時間',
        adName: 'Ad Name',
        operate: '操作',
        startReviewing: 'レビューを開始',
        searchPlaceholder: '広告主または広告名の検索',
        adInfo: 'Ad Info',
        userId: 'Ad Info',
        reviewItems: 'レビューアイテム',
        pass: 'Pass',
        fail: '失敗',
        reviewResult: 'レビュー結果',
        conclusion: '結論',
        approved: '承認',
        abnormal: 'アラート',
        description: '説明',
        comments: 'コメント',
        skip: 'スキップ',
        submitAndNext: '送信して次へ',
        reviewer: '審査担当',
        reviewTime: '審査時間',
        reviewed: '審査',
        reviewedLines: '審査: {num}件',
        team: '審査チーム',
        result: '審査結果',
        reason: 'NG理由'
    },
    auditEn: {
        confirmation: 'confirmation',
        searchSimilar: '一括審査',
        assetType: '素材カテゴリー',
        nativeAsset: 'Native',
        displayAsset: 'Display',
        mediaType: '配信先',
        location: 'ロケーション',
        language: '言語',
        languageTip: '英語のクリエイティブはスペイン語のトラフィックでは実行できません。',
        audience: 'コンテキスト',
        industry: 'カテゴリ',
        startTime: '開始時間',
        brandName: 'ブランド名',
        headline: 'タイトル',
        asset: '画像',
        landingPage: '広告遷移先LP',
        webUrl: 'Website URL',
        mApproved: 'In Machine Review',
        preliminaryReview: '審査中',
        secondReview: '二次審査',
        rejected: 'NG',
        manuallyApproved: '手動で承認',
        bdApproved: 'Baidu承認済',
        msApproved: 'Publisher承認済',
        bdRejected: 'BaiduNG',
        msRejected: 'PublisherNG',
        operate: '操作',
        startReviewing: 'レビュー開始',
        searchPlaceholder: 'キャンペーン/アカウント/審査担当',
        adId: 'Ad Id',
        assetId: 'Asset Id',
        assetName: '素材名',
        campaignName: 'キャンペーン名',
        accountName: 'アカウント名',
        submittedTime: '送信時間',
        assetSubmissionTime: 'クリエティブ送信時間',
        campaignSubmissionTime: 'Campaign Submission Time',
        reviewItems: '審査対象',
        pass: '通過',
        fail: '失敗',
        reviewResult: '結果を確認します',
        conclusion: '結果',
        approved: '承認済',
        description: '説明',
        comments: 'コメント',
        skip: 'スキップ',
        submitAndNext: '送信して次へ',
        reviewer: '審査担当',
        reviewTime: '審査時間',
        reviewed: '審査済',
        reviewedLines: '審査済: {num} 件',
        team: '審査チーム',
        result: '審査結果',
        reason: 'NG理由',
        status: 'Status',
        detail: '詳細',
        review: 'レビュー',
        reAudit: '再審査',
        adInfo: '広告情報',
        adName: '広告名',
        operateRecord: 'レコードを確認します',
        baiduBrainScanRecords: 'Baidu Brain Scan Records',
        refused: 'Refused',
        brainApproved: 'Approved',
        suspected: 'Suspected',
        others: 'Others',
        modify: 'Modify',
        baiduBrainScan: 'Baidu Brain Scan',
        baiduBrainScanResult: 'Baidu Brain Scan Result',
        baiduBrainRejectReason: 'Baidu Brain Reject Reason',
        baiduBrainReviewTag: 'Baidu Brain Review Tag',
        noData: 'No Data',
        lowLevelPornographic: 'Low Level Pornographic;',
        highLevelPornographic: 'High Level Pornographic;',
        lowLevelPolitics: 'Low Level Politics;',
        highLevelPolitics: 'High Level Politics;',
        lowLevelViolenceAndTerrorism: 'Low Level Violence And Terrorism;',
        highLevelViolenceAndTerrorism: 'High Level Violence And Terrorism;',
        lowLevelProhibited: 'Low Level Prohibited;',
        highLevelProhibited: 'High Level Prohibited;',
        lowLevelDisgustingPicture: 'Low Level Disgusting Picture;',
        highLevelDisgustingPicture: 'High Level Disgusting Picture;',
        submit: '送信',
        cancel: 'キャンセル',
        confirm: '確認',
        skipInfo: 'あなたはコンテンツを失います。問題ないですか？',
        leaveInfo: 'あなたはコンテンツを失います。問題ないですか？',
        reviewNotComplete: 'レビューは未完了です',
        requestError: 'ネットワークエラー、再試行してください',
        discarded: 'アーカイブ',
        pass1next: '通過して次へ',
        passAction: '通過',
        geoEdge: 'Geo Edge',
        displayAllImg: '監査されたすべての画像を表示する',
        setSensitive: '危ない系広告カテゴリー',
        baiduBrain: 'Baidu Brain'
    },
    creditLine: {
        currencyConverterTip: '適用為替レートについて：The Federal Reserve System、前月の平均為替レートで計算されます。<a>http://www.federalreserve.gov/releases/h10/hist/default.htm</a>',
        tradingCurrencies: '取引通貨',
        accountName: 'アカウント名',
        accountId: 'アカウントID',
        status: 'アカウントのステータス',
        companyName: '会社名',
        availableBalance: '利用可能な残高',
        availableBalanceRemark: '合計残高=アカウント残高+クレジットライン+合計クーポン消費量+合計有効クーポン残高（取引通貨）',
        balance: 'アカウント残高',
        balanceRemark: 'アカウント残高=累積リチャージ-累積支出（cpc）（取引通貨）',
        totalRecharge: '累積リチャージ',
        totalRechargeRemark: '累積リチャージ量',
        creditLinetotalRechargeRemark: '累積リチャージ量（取引通貨）',
        totalSpend: '総支出',
        untaxedCopy: 'トータル消化は税抜金額です。実際請求書・インボイスの税込金額と差があります。',
        totalSpendRemark: '過去の累積支出額（取引通貨）',
        creditBalance: 'クレジット残高',
        creditBalanceRemark: 'アカウント残高+クーポンの総消費量> = 0の場合、クレジット残高=クレジットライン。アカウント残高+クーポン合計消費量<0の場合、クレジット残高=クレジットライン+アカウント残高+クーポン合計消費量（取引通貨）',
        creditLine: '信用与信枠',
        creditLineRemark: 'アカウントに設定した追加のクレジット制限の合計（取引通貨）',
        balanceNote: '利用可能な残高$200以下',
        balanceNote1: '広告配信に影響しないよう、お早めにチェージしてください。',
        filter: 'フィルター',
        charge: 'チャージ',
        edit: '編集',
        editTitle: 'クレジット編集',
        chargeTitle: 'チャージ',
        currencyTitle: '決済通貨種類',
        tradingCurrencyLable: '取引通貨種類',
        contractualTitle: '契約主体',
        agent: '代理店',
        userName: '運用担当',
        note: 'Memo',
        operationTime: '操作時間',
        amount: 'チャージ金額',
        chargeTime: 'チャージ時間',
        editRecordTitle: 'クレジット編集履歴',
        chargeRecordTitle: 'チャージ記録',
        opt: '操作',
        couponBalance: '有効クーポン残金',
        couponBalanceRemark: '全有効クーポン残金（取引通貨）',
        couponSpend: '消化済みクーポン料金',
        couponSpendRemark: '消化済みクーポン料金（取引通貨）',
        couponTitle: 'クーポン',
        couponAmount: 'クーポン残金',
        effectiveTime: '有効開始時間',
        expireTime: '有効期限',
        offline: 'オフライン',
        offlineTip: '実行したらこのクーポンは利用できなくなります。実行しますか？',
        profitRate: '利益率（見込）',
        profitRateRemark: '当アカウントの見込み利益率を設定する。利益率の数値は実際の入札に影響します。',
        profitRateTitle: '利益率を設定',
        profitRateRecordTitle: '利益率修正記録',
        curProfitRate: '今の利益率',
        transactionCurrency: '取引通貨',
        settletmentCurrency: '決済通貨',
        couponCost: 'Coupon消化分',
        adjustmentAmount: '調整額',
        receivablesWithTax: '調整後請求額(税込み)',
        adjustmentAmountRecord: '調整金額履歴',
        receivablesWithoutTax: '調整後請求額(税抜き)',
        currentCoupons: 'Coupon消化分',
        selectMonth: '時間帯選択',
        amountSetting: '調整設定',
        setAmount: '調整金額設定',
        searchText: 'アカウント/会社/代理店名で検索',
        searchMonthlyText: 'Search Account/Agency Name',
        amountRecords: '調整金額履歴',
        spendPlusTaxRemark: '選択期間内、累計税抜額(調整前)',
        couponCostRemark: '選択期間内、Coupon金額',
        adjustmentAmountRemark: '選択期間内、調整額(税抜)',
        consumptionTaxRemark: '選択期間内、調整後消費税',
        monthlyExchangeRateRemark: '請求月平均レート',
        receivablesWithTaxRemark: '選択期間内、累計税込額(調整後)',
        receivablesWithoutTaxRemark: '選択期間内、累計税抜額(調整後)',
        currentUntaxedSpend: '調整前請求額(税抜き)',
        settlementCurrency: '決済通貨',
        rebate: 'マージン',
        monthText: '毎月3日以降に従来の金額を修正してはならない',
        adjustedText: '調整が必要な金額を完全に入力してください',
        changeRecords: '調整履歴',
        remarkNote: '備考',
        changeDate: '調整時間帯',
        amountEdit: '調整設定',
        release: '公開'
    },
    roiMgr: {
        serviceType: 'サービス種類',
        category: '業種',
        companyName: '会社名',
        accountName: 'アカウント名',
        accountId: 'アカウントID',
        income: 'Baidu当期売上',
        incomeRemark: 'Baidu当期売上＝アカウント当期消化-当期消化済みクーポンコスト',
        spend: 'アカウント当期消化',
        spendRemark: '選ばれた期間帯の消化',
        cost: '現在のトラフィックコスト',
        costRemark: '選択期間でのMSNへの支払い金額（CPM）',
        profit: '現在の利益',
        profitRemark: '現在の利益=Baidu 現在の収益-現在のトラフィックコスト',
        profitRate: '利益率（実質）',
        profitRateRemark: '利益率=当期利益/Baidu当期収益',
        impression: '表示',
        click: 'クリック数',
        filter: 'フィルター',
        coupon: '現状クーポンコスト',
        couponRemark: '選択期間でのクーポン利用金額',
        rebateRatio: '払い戻し率',
        rebateRatioRemark: 'ROIレポートページでは、代理店とその広告主の両方がリベート率を示しています。',
        source: '顧客ソース',
        sourceCompany: '顧客ソース会社',
        accountType: 'アカウントタイプ',
        rebate: '現在のリベート費用',
        rebateRemark: '現在のリベートコスト=Baidu現在の収入×払い戻し比率',
        launchDays: '配信期間',
        averageDailySpend: 'デイリー消化額',
        maximumDailySpend: '最高配信金額'
    },
    // 可视化测试
    visualTest: {
        adminEmailCode: '確認コードを取得する'
    },
    financiaIncome: {
        accountId: 'アカウントID',
        companyName: '会社名',
        accountName: 'アカウント名',
        timezone: 'アカウントタイムゾーン',
        email: 'アカウント保有者のEメール',
        submitTime: '作成時刻',
        spend: 'アカウントの現在配信費用',
        spendRemark: '選択された期間内のアカウントの累積費用',
        coupon: '現在のクーポン',
        couponRemark: '選択された期間でのクーポン利用額',
        receivablesRemark: '売掛金=売掛金の現在の消費-現在のクーポン費用',
        recharge: '現在のリチャージ金額',
        receivables: '売掛金',
        businessManager: '営業担当'
    },
    financiaPay: {
        accountType: 'アカウントの種類',
        accountId: 'アカウントID',
        source: '顧客情報',
        rebateRatio: '払い戻し率',
        rebateRatioRemark: '支払い可能なページでは、代理店の広告主のリベート率は0です（ここには表示されていません）。',
        companyName: '会社名',
        accountName: 'アカウント名',
        timezone: 'アカウントタイムゾーン',
        email: 'アカウント保有者のEメール',
        cost: '現在のトラフィックコスト',
        businessManager: '営業担当',
        costRemark: '選択した期間でのMSNへの支払い金額（CPM）',
        rebate: '現在のリベートコスト',
        sspName: 'メディア',
        rebateRemark: '現在のリベート費用（支払い可能）：エージェントの下の各広告アカウントの現在のリベート費用の累積合計。代理店の広告口座が発生した消費のリベート額は、代理店会社自体に支払われるものとします。'
    },
    dataReport: {
        account: 'アカウント',
        device: '端末',
        os: 'OS',
        platform: 'プラットホーム',
        browser: 'ブラウザ',
        audience: 'コンテキスト',
        industry: 'カテゴリー',
        location: '位置',
        // country: '国',
        country: '地域',
        stateregion: '州/地域',
        language: '言語',
        publisher: '広告主',
        campaignName: 'キャンペーン',
        assetName: 'クリエティブ名',
        adsize: '画像サイズ',
        spend: '支払う',
        viewedImp: 'vimp',
        click: 'クリック数',
        ctr: 'CTR',
        colAvgCpc: '平均CPC',
        colVcpm: 'vCPM',
        colDate: '日付',
        conversions: 'CV',
        conversionRate: 'CVR',
        conversionPrice: 'CPA',
        colConversionPrice: 'CPA',
        colConversionRate: 'CVR',
        colCtr: 'CTR',
        needAccount: 'CTR',
        needCampaign: 'キャンペーンを選択してください',
        timePre: '最終更新日',
        conversionTimePre: '最新のコンバージョンデータ',
        timeAfter: '（レポートタイムゾーンは広告アカウントと同じです）',
        targetBid: 'ターゲット入札',
        fixedBid: '固定CPC',
        OCPC: 'OCPC',
        ECPC: 'ECPC'
    },
    amDashboard: {
        // 运营配置
        currentSsp: '配信先SSP',
        // black&white
        kwTitle: '広告主アカウント単位で、ブラックリスト・ホワイトリストを設定',
        kwSetting: 'ブラックリスト・ホワイトリスト設定',
        kwTag: 'KW設定',
        filterType: 'フィルター',
        // blackList: 'Black List',
        // whiteList: 'White List',
        insertList: 'KWリストを登録',
        selectCsv: 'CSVファイルを選択してください',
        editDate: '修正日時',
        operationId: '編集者',
        objectName: '修正内容',
        insert: 'をアップロード',
        edit: 'を修正',
        importWhite: 'KWホワイトリストをインポートします',
        importBlack: 'KWブラックリストをインポートします',
        kwWarning: '下記KWのフォーマットは間違っています。ご確認の上もう一度アップロードをお願いします',
        dialogTitle: 'アップロード結果',
        dialogSubTitle: 'アップロード済みKW数：{num}',
        downloadCurrentCSV: 'download current CSV',
        companyName: '会社名',
        // country: '国',
        country: '地域',
        language: '言語',
        date: '日時',
        roas: 'ROAS',
        // remainingBudget: 'キャンペーン残高',
        remainingBudget: '残り総予算',
        taboolaBind: 'Taboola Ad Pull',
        configableCpc: '設定可能CPCの下限',
        accountListSetting: 'アカウントリスト設定',
        hideClosedAccount: 'OFFしたアカウントを非表示とする',
        hideBalanceAccount: 'トータルバランス<= 0でアカウントを隠す',
        CPC: 'CPC',
        totalBudget: '総予算',
        dailyBudget: '日予算',
        budget: '予算',
        campaignName: 'キャンペーン名',
        accountName: 'アカウント名',
        status: 'Status',
        campaignStatus: 'キャンペーンの状況',
        spend: '現在の収益',
        impression: 'インプ数',
        click: 'Click',
        clickRate: 'CTR',
        startTime: '開始時間',
        endTime: '終了時間',
        iImpression: '表示回数',
        cost: '費用',
        iCtr: 'iCTR',
        iCpm: 'ICPM（収益）',
        viewableRate: 'ビューアブル率',
        vCtr: 'vctr',
        colCfgTitle: '列',
        campaignId: 'キャンペーンID',
        accountId: 'アカウントID',
        colStatus: 'Status',
        colStart: '開始',
        colEnd: '終了',
        colSpend: '現在の収益',
        colTotalBudget: '総予算',
        colDailyBudget: '日予算',
        colDetail: '詳細',
        colDelivery: 'デリバリー（確認待ち）',
        colCpc: 'CPC',
        colAvgCpc: '平均CPC',
        colVCpm: 'VCPM（収益）',
        colImp: 'インプ数',
        colClick: 'クリック数',
        colCtr: 'vCTR',
        colConversion: 'CV',
        colConversionRate: 'CVR',
        colConversionPrice: 'CPA',
        colIimpression: '表示回数',
        colCost: '費用',
        colIctr: 'CTR',
        colIcpm: 'CPM',
        colViewableRate: 'ビューアブル率',
        blackWhiteConfig: 'ブラックリストホワイトリスト',
        colProfitRate: '利益率',
        colSpendRate: '予算消化率',
        colLastSpend: '前期の収入',
        colCompareRate: '比較レート',
        colAmResponsible: 'AM権限',
        spendRemark: '収益対比=（現在の収益 - 前期の収益）/最終期間の収益',
        spendRateRemark: '日次予算消化率',
        profitRateRemark: '利益率',
        totalCampaign: 'トータルキャンペーン',
        abnormalCampaign: 'アブノーマルキャンペーン',
        campaignOptLog: 'キャンペーン操作履歴',
        campaignOptCfg: 'キャンペーン運用構成',
        pageIdBlockCfg: 'ページIDブラックリスト',
        campaignAlertCfg: '異常なリマインダー',
        colIndustry: 'カテゴリー',
        tradeCategory: '業種別',
        icafe: {
            newItem: 'New Tracker'
        },
        serviceRecord: '広告主操作履歴',
        minCpcRecordText: '最低CPCは{old}から{new}へ修正します',
        accountBlackWhiteConfigTitle: '広告アカウント単位でブラックリスト・ホワイトリストを設定',
        campaignBlackWhiteConfigTitle: 'キャンペーン単位で、ブラックリスト・ホワイトリストを設定',
        sellerIdBlackWhiteConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        sellerIdBlackWhiteConfigTips: '*The SellerID blacklist/whitelist will take effect, only when the Account-level media blacklist/whitelist is removed',
        rulesType: 'サイトフィルター',
        configIndex: '適用単位',
        configObject: '設置対象',
        blackList: 'ブラックリスト',
        whiteList: 'ホワイトリスト',
        mediaSiteGroup: 'Media Siteグループ',
        mediaAccountAndSite: '媒体アカウント/Media Site',
        mediaAccount: '媒体アカウント',
        mediaSite: 'Media Site',
        selectedConfig: '現状の設置',
        rulesTypeTip: '"ブラックリスト、ホワイトリストかのいずれか片方のみ設定可能です。もしブラックリストをホワイトリストへ設置切り替えたいなら、既存すでに設定されたホワイトリスト・ブラックリストを削除してから、再設定をお願いします。"',
        deleteAll: '全部削除',
        sitePlaceholder: '"複数のメディアを同時に登録される場合は、媒体アカウント（もしくはMedia site）名をセミコロン「;」(※小文字)で区切ってください。例）Media_A;Media_B"',
        mediaPlaceholder: 'Supports adding accounts in batches for selected media accounts. Please enter account id, separated by semicolons.',
        sellerPlaceholder: 'Supports adding Seller ID in batches for selected accounts. Please enter Seller ID, separated by semicolons.',
        creativeIdPlaceholder: 'Content, separated by two semicolons.',
        cancel: '取り消し',
        confirm: '確認',
        operationReport: '操作ログ',
        operationTime: '操作時間',
        userName: '操作アカウント',
        actionDetails: '操作内容',
        USA: '米国',
        Canada: 'カナダ',
        UnitedKingdom: 'イギリス',
        Australia: 'オーストラリア',
        Japan: '日本',
        Korea: '韓国',
        Taiwan: '台湾',
        Indonesia: 'インドネシア',
        Singapore: 'シンガポール',
        Malaysia: 'マレーシア',
        Thailand: 'タイ',
        HongKong: '香港',
        from: 'から',
        to: 'へ',
        tips1: 'このOCPCの設定は広告のOCPC入札価格とリンクして、慎重に調整してください。',
        tips2: '1) CampaignがOCPCで入札する場合、広告主はそれを感知し、慎重に変更してくだい。',
        tips3: '2)キャンペーンの入札単価が固定で入札単価の場合、このOCPC設定は広告主には認識されません。広告主が固定入札単価をOCPCに変更した場合、この目標CPAがOCPCのデフォルトの目標CPAとして使用されます。   ',
        // 通知中心
        notificationCenter: 'お知らせ',
        createNotification: '新規お知らせを作成',
        notificationTitle: 'タイトル',
        recipients: '対象',
        noticeDate: '日付',
        notificationContent: '內容',
        confirmNotice: '登録',
        previousNotifications: '登録済みお知らせ一覧',
        sender: '登録者',
        submittedTime: '登録時間',
        dataRange: 'スケジュール',
        notificationStatus: 'ステータス',
        changeStatusTip: '一度ステータスをOFFにするとONに戻すことはできません',
        msgTitleRequired: '*必須：タイトル',
        msgRecipientRequired: '*必須：対象',
        msgLanguageRequired: '言語を選択してください',
        msgContentRequired: '*必須：内容',
        msgTipsTitle: '※Tips',
        msgStatusChangeTips: '一度ステータスをOFFにするとONに戻すことはできません\nこのお知らせをOffにしますか？',
        close: '閉じる',
        delete: '既読',
        noMessage: '新規お知らせはございません',
        moreMessage: '他のお知らせ',
        toNoticeList: 'お知らせリストへ戻る',
        noticeDetail: 'お知らせ内容',
        closeDetail: '戻る',
        noticeListTitle: 'お知らせリスト'
    },
    pixel: {
        companyName: '会社名',
        selectDimensionTip: 'アカウント/会社を選択',
        accountPlaceholder: '該当するアカウントを選択',
        companyPlaceholder: '該当する会社を選択',
        pixelCreateTipHeader: '選択したアカウント/会社のコンバージョンを追跡するために、初期Pixel、MCV Event PixelとCV Event Pixelを設定してください',
        pixelCreateTipContent: '1つのアカウントのみpixelを埋め込む場合は、アカウント名を選択してください；同一会社の複数のアカウントにpixelを埋め込む場合は、会社名を選択してください。コードを複数回埋め込む必要はありません',
        createRead1: '下記タグをコピーして</ body>タグの前に配置してください。',
        createRead2: 'タグが設置後発火確認するため、キャンペーン開始前の2作業日前にpopIn窓口まで連絡をお願いします。',
        createRead3: 'HTMLコードを設置',
        getREADCode: 'READタグを発行',
        codeType: 'コードタイプ',
        urlBased: 'URL',
        eventBased: 'イベント',
        type: 'タイプ',
        accountName: 'アカウント名',
        pixelName: '初期ピクセル名',
        conversionType: 'タイプ',
        status: 'ステータス',
        includeInTotalConversion: '重要コンバージョン',
        lastReceived: '最終受信',
        lastModified: '最終更新日',
        eventName: 'イベント名',
        category: 'カテゴリー',
        optionalVal: 'コンバージョン値',
        optionalValAppend: 'これは、コンバージョン価格などの情報をコンバージョンに追加するために使用されます。',
        conversionWindow: 'コンバージョン ウィンドウ',
        isIncludeTatalConversion: '重要コンバージョン',
        isExcludeCampaigns: '全てのキャンペーンからオーディエンスを除外する',
        urlBasedTip: 'URLベース：URLが特定の条件を満たすWebサイトにユーザーがアクセスするたびに追跡します',
        pixelReadTip: '例：<br>実際広告の成果地点まで、３つのページそれぞれ違うドメインになる場合<br>広告クリック→記事LP→広告主LP→サンクスページ（CV)<br>★ 下記のドメインを記入必要<br>1.記事LPドメイン<br>2.広告主LPドメイン<br>3.サンクスページドメイン<br>',
        eventBasedTip: 'イベントベース：誰かがjsコードを使用して特定のアクションを完了するたびに追跡します。 例：カートに商品を追加する',
        urlCondition: 'URL条件',
        urlConditionAppend: 'URLが次の条件のいずれかを満たすトラフィックを含めます。:',
        conversionProperties: '変換特性',
        conversionName: '変換名',
        conversionNameAppend: '変換の意味を区別しやすいように、任意の名前を使用してください',
        conversionCategory: 'コンバージョン カテゴリー',
        conversionCategoryAppend: 'このコンバージョンが属するカテゴリーを選択してください',
        importantConversion: '重要コンバージョン',
        importantConversionTipTitle: 'Yesを選択した場合',
        importantConversionTip1: 'コンバージョンデータは、ダッシュボードのコンバージョン列に含まれています',
        importantConversionTip2: 'コンバージョンデータは、ダッシュボードのコンバージョン列に含まれています',
        eventCategory: 'イベントカンテゴリーを選択してください',
        eventCategoryAppend: '成果条件を選択します',
        embedHtmlCode: 'HTMLコードを埋め込む',
        embedHtmlCodeAppend: '対象のイベントコードが稼働されたときに、関連するhtmlにイベントコードを追加します。',
        pixelInitialCode: 'ピクセル初期コード',
        pixelInitialInfo: 'Phase 2: 下記のタグを記事LPを含め、成果ページまでの全部ページの</head>と<head>間で設置してください。<br> 最初のピクセルを追加することは、コンバージョントラッキングをインストールするための最初のステップであり、コンバージョントラッキングをインストールするには、[新しいコンバージョン]ボタンをクリックし、指示に従う必要があることに注意してください。',
        copy: 'クリップボードにコピー',
        create: '新しいコンバージョン',
        condition: '条件',
        or: 'OR',
        newCondition: '新規条件',
        newConditionAppend: '訪問がコンバージョンとしてカウントされるリンク先ページのURLまたはURLの一部を入力します。',
        confirmCreate: '変換を作成する',
        initPixel: 'MediaGoの初期ピクセルを表示',
        mcvCvOther: 'MCV・CV・他ピクセル',
        initPixelTab: '初期ピクセル',
        initPixelPopin: 'Discoveryの初期ピクセルを表示',
        viewCode: 'コードを見る',
        conversionEdit: 'コンバージョン編集',
        step1: 'ステップ 1',
        step1Desc: 'ピクセル初期コードを追加します',
        step1Desc1: '（すでに初期コードを追加している場合は、この手順をスキップできます）',
        step2: 'ステップ 2',
        step2Desc: 'タイプを選択',
        step3: 'ステップ 3',
        step3Desc: 'プロパティ',
        step4: 'ステップ 4',
        pixelInitialInfo1: 'Phase 2: 下記のタグを記事LPを含め、成果ページまでの全部ページの</head>と<head>間で設置してください。',
        headerCode: 'Phase 1: 成果地点までの全部のドメインを入力してください。',
        headerCode1: '（※成果地点まで複数のドメインがある場合、全部のドメインを改行で入力必要です。',
        pixelInitialTitle1: 'Phase 1: 成果地点までの全部のドメインを入力してください。<br>（※成果地点まで複数のドメインがある場合、全部のドメインを改行で入力必要です。',
        expandCode: '初期コードを展開して作成し、コピーします',
        // new pixel conversions
        createNewPixel: '新規ピクセル作成',
        howInstallPixel: 'ピクセルの実装方法？',
        downloadDoc: 'ダウンロード',
        pixelCreateSubTitle: '対象アカウントを選択してから、初期ピクセル・MCVピクセル・CVピクセルを作成してくだい。',
        selectAccount: 'アカウントを選択',
        configInitPixel: '初期ピクセルを作成',
        addPixelCode: 'ピクセルを追加',
        mcvEventPixel: 'MCVピクセル',
        hasConfigText: '作成済：',
        pixelNumUnit: '',
        cvEventPixel: 'CVピクセル',
        otherEventPixel: '他のイベントピクセルを作成する',
        addInitialPixel: '初期ピクセルを追加',
        initCodeTips: '*まず下記説明の通りに、ドメインを入力してください。\n・ドメイン入力説明\n成果地点までの全部のドメインを入力してください。\n※成果地点まで複数のドメインがある場合、全部のドメインを改行で入力必要です。\n例）成果時点まで、記事LP(https://a.com/xxx)→クッションページ(https://b.com/xxx)→サンクスページ(https://c.com/xxx)\n-------\n初期ピクセルは広告クリック→遷移LP〜リダイレクトURLなど→成果時点までの全部ページの<head>で設置してください。',
        pixelCodeTips: '初期ピクセルは広告クリック→遷移LP〜リダイレクトURLなど→成果時点までの全部ページの<head>で設置してください。',
        pixelPlaceholder: '下記の通りに入力してください。\na.com\nb.com\nc.com',
        getInitialPixel: '初期ピクセルを作成',
        editUpdateText: 'ピクセルを修正',
        initPixelDone: '初期ピクセル設定完了しました。',
        saveAndCopy: 'コピーして保存',
        setMcvEventPixel: 'MCVピクセルを作成',
        mcvEventTips: '*MCVピクセルを遷移先LPの<head>で設置してください。',
        getMcvEventPixel: 'MCVピクセルを取得',
        copyMcvPixelTips: '*上記ピクセルを遷移先LPの<head>で設置してください。',
        mcvPixelDone: 'MCVピクセルの作成完了しました。',
        setCvEventPixel: 'CVピクセルを作成する',
        cvEventCodeTips: '*CVピクセルをサンクスページに設置してください。（成果地点はCTAボタンでしたら、CTAボタンで設置してください。）',
        getCvEventPixel: 'CVピクセルを取得',
        copyCvPixelTips: '*上記CVピクセルを成果時点で設置してください。（サンクスページ或はCTAボタン）',
        cvPixelDone: 'CVピクセルの作成完了しました。',
        setOtherEventPixel: '他のイベントピクセルを作成する。',
        otherEventTips: '*イベントピクセルは対象成果地点で設置してください。例）カートに追加での成果地点を追跡したいなら、このイベントピクセルをカート追加ページで設置してください。',
        copyOtherPixelTips: '*上記イベントピクセルは対象成果地点で設置してください。',
        getOtherEventPixel: '他のイベントピクセルを取得',
        otherPixelDone: '他イベントピクセルの作成完了しました。',
        trackingCodeTips: '*イベントピクセルは対象成果地点で設置してください。例）カートに追加での成果地点を追跡したいなら、このイベントピクセルをカート追加ページで設置してください。',
        manageExistPixel: '作成済のピクセルを確認',
        initPixelTips: '初期ピクセルを広告クリック→遷移LP〜リダイレクトURLなど→成果時点までの全部ページの<head>で設置してください。',
        mcvPixelTips: 'MCVピクセルを遷移先LPの<head>で設置してください。',
        cvPixelTips: 'CVピクセルをサンクスページに設置してください。（成果地点はCTAボタンでしたら、CTAボタンで設置してください。）',
        hasDeprecated: '（利用不可）'
    },
    // 权限部分
    permissionMgr: {
        accountId: 'アカウントID',
        accountStatus: 'アカウントのステータス',
        accountName: 'アカウント名',
        companyName: '会社名',
        type: '会社タイプ',
        email: 'アカウント管理者のEメールアドレス',
        payMethod: '支払方法',
        adMethod: '入金方法',
        timezone: '時間帯',
        createAccount: '新しいアカウント',
        inviteLink: 'アカウント開設招待リンク',
        copyLink: '招待リンクをコピーする',
        dailyCmpCount: 'API利用アカウントのデイリー',
        dailyCmpCount2: '作成可能キャンペーン数の制限',
        openDailyCmpCount: 'Open Account API Single-Day Creation Campaign Upper Limit',
        dailyLimit: 'デイリー作成可能キャンペーン上限数500個、一キャンペーンで作成できるクリエイティブ上限数10個',
        roleMgr: {
            detail: '詳細',
            edit: '権限管理',
            del: '削除'
        }
    },
    permission: {
        notAuthorizeTip: 'Admin権限以外にこの機能の付与はできません',
        p1: '続行するには、MediaGoのプライバシーポリシーと規約に同意してください。',
        password: 'パスワード',
        newPassword: '新しいパスワード',
        timeZone: 'タイムゾーン',
        inputVCode: '検証コード',
        vCode: '確認コードを送信する',
        forgotPasswordInputVCode: '認証コード',
        forgotPasswordVCode: '認証コードを送信する',
        title: '広告主アカウント開設',
        subtitle: 'あと２つのステップでプラットフォームを利用できるようになります',
        userId: 'ユーザーID',
        accountType: 'アカウントタイプ',
        quizz1: '1. あなたの会社は広告アカウントを持っていますか？',
        yes: 'はい',
        no: 'いな',
        quizzyes: '2.許可を適用するには、[メール送信]ボタンをクリックしてください。',
        quizzno: '2.広告アカウントを開き、権限を適用するには、[メール送信]ボタンをクリックしてください。',
        sendEmail: 'メールを送る',
        email: {
            title: '【ユーザーアプリケーション】',
            divider: '===============================================================',
            title1: 'アカウントアプリケーションとユーザーアプリケーション',
            bodyTitle1: 'MSN用のユーザーMediaGoアプリケーション',
            bodySubtitle1: '(すべての項目に記入してください)',
            bodyTitle2: 'MSN用のMediaGo ADアカウントアプリケーションとユーザーアプリケーション',
            bodySubtitle2: '(すべての項目に記入してください)',
            account: {
                title: '【ADアカウントアプリケーション】',
                company: '* 組織名:',
                registerAddr: '* 組織住所: ',
                operationAddr: '* 請求先住所: ',
                type: '* 組織種別: ',
                officialSite: '* 会社のウェブサイト: ',
                position: '* 役職:',
                owner: '* ADアカウント管理者Eメール:',
                ownerInfo: '（メールアドレスを入力してください）',
                accountNum: '* アカウント数:    1個 ',
                accountNumInfo: '(1つのアドアカウントのみ適用できます)',
                accoutName: '* Ad アカウント名:  ',
                totalCap: '* 総予算:    $ ',
                totalCapInfo: '(通貨: USD)',
                chargeType: '* 支払い方法:  ',
                chargeTypeInfo: '(デポジットもしくは現金支払い)',
                manner: '* アカウントタイプ:   ',
                mannerInfo: '(セルフサービス/ 代理)'
            },
            permission: {
                title: '【ユーザーアプリケーション】',
                name: '* ニックネーム:',
                nameInfo: '(所属する組織でのポジション、氏名を入れてください。Eg: Duan Ying - PM)',
                userId: '* ユーザーID: ',
                accountType: '* ユーザーアカウントの種類: ',
                userType: '* ユーザータイプ:  ',
                userTypeInfo: '（広告主/代理店）',
                email: '* ユーザーのEメールアドレス: ',
                emailInfo: '（メールアドレスを入力してください）',
                company: '* 組織名: ',
                adAccount: '* ADアカウント名: ',
                adAccountInfo: '（あなたの所属する組織のアカウント名を入力してください）'
            },
            result: {
                info: '申し込みいただきありがとうございます.',
                info1: 'できるだけ早く連絡します,',
                footer1: '質問がある場合は、こちらに連絡してください:',
                footer2: 'できるだけ早く返信します'
            }
        },
        emailReport: {
            create: '自動レポーティング',
            status: '状態',
            acountName: 'アカウント名',
            timeZone: 'レポートタイムゾーン',
            recivieName: '受信者',
            frequency: '送信頻度',
            closeTips: '終了後にメールの送信を停止する',
            zoneTips: 'アカウントのタイムゾーンと一致します',
            emailFormat: '複数のメールをコンマで区切って入力できます',
            day: 'デイリー',
            month: 'マンスリー'
        },
        formTitle: 'MediaGoアカウントを適用する',
        formDesc: '次の情報を入力してください。プラットフォームの権限を開くために、できるだけ早くご連絡いたします。',
        formAppend: '「適用」をクリックすると、MediaGoに同意したことになります',
        formAppendLink: 'プライバシー契約',
        // country: '国',
        country: '地域',
        currency: '通貨',
        firstName: '名',
        lastName: '氏',
        formEmail: 'メールアドレス',
        formEmailTip: 'これはあなたのユーザー名として使用されます',
        submitProtocol: 'はい。キャンペーンを改善するためのアドバイスを送ってください。',
        timeZoneTip: 'キャンペーンを管理するタイムゾーンを選択してください。設定は変更できません。',
        currencyTip: '決済通貨を選択してください。一度選択すると、変更できません。',
        organizationName: '企業名',
        companySite: '会社のウェブサイト',
        jobTitle: '役職',
        phone: '電話番号',
        companyNameandtips: '会社登録名（登録時に記入）',
        apply: '即日開始',
        createAccount: 'アカウント作成',
        tologinText: 'アカウントを既にお持ちですか？'
    },
    openAccount: {
        stepOne: 'ADアカウントアプリケーション',
        stepTwo: 'ユーザーアカウントをバインドする',
        stepThree: '申請が完了しました',
        workEmailTip: ' (ユーザー名として使用されます)',
        billingEmail: '請求先メールアドレス',
        billingEmailSymbol: '「;」は半角文字を入力してください',
        billingEmailLength: '1000文字以下',
        billingEmailTip: '請求書送付先として登録するメールアドレスを入力してください。複数のアドレスを登録する場合「;」を使用してください。',
        accountTitle: 'ADアカウントアプリケーション',
        accountTitleDesc: '以下の情報はアカウントアプリケーションにのみ使用され、メッセージが公開されないことを約束します。',
        accountTitleDesc1: '入力された情報はアカウント申請にだけ使用させていただきます。',
        currency: '通貨',
        // 币种提示
        currencyTitle: '決済に利用する通貨を選択してください。選択すると変更できません。',
        // 交易币种提示
        tradingCurrencyTitle: '取引に利用する通貨を選択してください。選択すると変更できません。',
        // 合同提示
        contractualTip: 'アカウントの契約主体の選択',
        accountTitleDesc2: 'これは課金に直接影響を与えるので、公式の登録文書を反映するように組織の詳細を慎重かつ正確に入力してください。',
        accountName: '広告アカウント名',
        accountEmail: 'アカウント管理Eメール',
        totalBudget: '総予算',
        payMethod: '支払方法',
        prepayment: 'デポジット',
        delivery: '請求書払い',
        timezone: 'アカウントのタイムゾーン',
        adMethod: '口座種類',
        selfService: 'セルフサービス',
        managed: '代理',
        companyName: '企業名',
        registerAddr: '登記住所',
        operateAddr: '会社住所',
        website: 'ウェブサイト',
        userTitle: 'ユーザーアカウントのバインド',
        userTitle1: 'ユーザーアカウント申請',
        userTitleDesc: '複数のユーザーアカウントをADアカウントにバインドすることができます。MediaGoユーザーアカウント登録もしくは追加のために必要な情報を入力ください。',
        addExistAccount: '既存のユーザーアカウントを追加する',
        registAccount: '新しいユーザーアカウントを登録する',
        // country: '国',
        country: '地域',
        firstName: '名前',
        lastName: '姓',
        email: '仕事用メールアドレス',
        jobTitle: '役職',
        phone: '電話番号',
        submitAppend: '「適用」をクリックするとMediaGoに同意いただきます。',
        privacyLink: 'ポリシー',
        terms: '条項',
        logout: 'ログアウト',
        del: '削除',
        add: '追加',
        apply: '申し込み',
        formAppendLink: 'プライバシーポリシー',
        info: '申請いただきありがとうございます。最短でご連絡させていただきます。',
        info1: 'できるだけ早く連絡いたします。',
        footer1: 'ご不明点がある場合はこちらにご連絡ください:',
        footer2: 'できるだけ早く回答いたします。',
        invalidToken: '提供されているリンクは無効です。正しいリンクを取得するために、AMと連絡を取ってください。',
        inviteMsg: 'MediaGoのアカウントを開設に招待しております。'
    },
    validations: {
        campaignObjective: 'キャンペーン目的が必要です',
        couponAmount: '请输入需要设置的Coupon金额',
        effectiveTime: '请选择生效时间',
        targetMax: '整数のみがサポートされており、最小入力値は100です。',
        validNumber: 'ここに有効な番号を入力してください',
        validDecimal: '小数点３位まで',
        simpleRequired: '必須',
        jobTitle: '有効な役職を入力してください',
        firstName: '有効な名前を入力してください',
        lastName: '有効な姓を入力してください',
        required: 'この項目は必須です',
        number: 'ここに有効な番号を入力してください',
        money: '有効な金額を入力してください',
        moneyNum: '目標入札金額は数字で入力ください。',
        moneyJpy: 'デイリー総予算は整数で入力ください。',
        moneyNumJpy: '目標金額は整数で入力ください',
        moneyTotalJpy: '総予算は整数で入力してください',
        numberBeyond: '1,000,000,000を超えることはできません',
        numberBeyondJpy: '最低デイリー総予算は￥2,000/日です。',
        dailyCapBeyond: '最低デイリー総予算は{money}/日です',
        cpcBeyond: '広告最適化するため、目標入札は{money}以上設定してください',
        checkCurrencyMaxCPC: '広告最適化するため、目標入札は{money}以下で設定してください',
        // numberBeyondJpy: '最低デイリー総予算は￥2,000/日です。',
        numberBeyond1: '1,000,000を超えることはできません',
        dailyOverTotal: 'デイリーの総予算は全期間の総予算より高い金額は入力できません。',
        dailyOverTotal2: '日予算は総予算より高くすることはできません',
        dailyOverTotal3: '総予算は日予算より低くすることはできません',
        dailyOverTotal4: '最低の日予算は20ドル/日です',
        dailyOver20: '最低日予算は$20/日です',
        lifetiemUnderDaily: '総予算は日予算より低く設定できません。',
        targetBidOverDaily: '目標入札金額はデイリー総予算より高く設定はできません。',
        targetBidOverDailyJpy: '目標入札額は整数でなければなりません。',
        targetBidOverDaily2: 'CPCは日予算より高くすることはできません',
        startAfterEnd: '終了時刻は開始時刻より早くできません',
        start: '開始時刻が必要です',
        end: '終了時間が必要です',
        endBeforeStart: '終了時間は開始時刻、現在時刻より前に設定できません',
        status: 'ステータスが必要です',
        daily: 'デイリー総予算は必須です。',
        lifetime: '総予算は必須です。',
        targetBid: 'ターゲット入札金額は必須です。',
        // create
        image: '適切な画像をアップロードしてください',
        imageRevise: '新しい適切な画像をアップロードしてください',
        imageInfo: 'ここへ{scale}px画像をアップロードしてください。',
        size: 'サイズが必要です',
        http: 'http://またはhttps://から始まる会社のウェブサイトの開始を入力してください。',
        charLenth: '250文字以下',
        charLen: '{num}文字以下',
        revise: '必須フィールドを修正してください',
        name: '名前が必要です',
        platform: 'プラットフォームが必要です',
        os: 'OSが必要です',
        browser: 'ブラウザが必要です',
        adFormat: '広告フォーマットが必要です',
        placement: '配置が必要です',
        audience: 'オーディエンスが必要です',
        industry: 'カテゴリが必要です',
        location: '場所が必要です',
        zipcode: '郵便番号が必要です',
        brandName: 'ブランド名が必要です',
        headline: 'タイトルが必要です',
        adAssetName: 'クリエティブ名は必要',
        imageValidations: 'Image is required',
        adAssetNameNotRepeat: 'クリエティブ名重複されています。',
        landingPage: 'ランディングページが必要です',
        impURL: 'ビュートラッキングピクセルが必要です',
        landingPageError: 'ランディングページにスペースを含めることはできません, \', \' 複数 \'?\'',
        noAds: 'このアカウントの下でレビュ​​ーを待っている広告はありません',
        skipInfo: 'このページをスキップした場合、失われる未保存の変更があります。',
        leaveInfo: 'このまま操作を継続すると入力内容が失われます。',
        reviewNotComplete: '審査未完成',
        advertiser: '広告主アカウントを選択してください',
        utmParams: 'URLの追跡は含めることはできませんか？、@、/、スペースとラインブレーク',
        picDuplicated: '同じ画像をアップロードしないでください',
        // 登录
        email: '有効なメールアドレスを入力してください',
        organizationName: '企業名が必要です',
        companySite: '会社のウェブサイトが必要です',
        phone: '電話番号が必要です',
        switchLang: '言語切り替えると入力している内容は消えている。切り替えますか',
        confirmToSwitch: 'There’re unsaved edits. Do you want to save the edits before switching language？',
        confirmTip: '发布操作不可取消，请点击确认按钮以继续。',
        saveTip: '是否保存当前操作？请点击相应按钮以继续。',
        cacheNewCampaign: '前編集完成してないキャンペーンを編集しますか',
        cacheContinue: '編集続ける',
        cacheAgain: '作り直す',
        cacheSaveNewCampaign: '今のページ離れ、編集された内容は自動的保存されます。次回編集する時再編集可能',
        fileParse: 'ファイル解析中、送信ボタンをクリックしてください。5 ~ 15分後に新しいダッシュボードでクリエイティブが正常に作成されたか確認できます。',
        pwd: 'アルファベット、数字、記号の組み合わせで8文字以上を使用する',
        pwdNotMatch: '入力した2つのパスワードが一致しません',
        relation: 'フィルタ条件を選択してください',
        pwdRequired: '有効なパスワードを入力して下さい',
        // open account
        company: '有効な企業名を入力してください',
        companyName: '有効な企業名を入力してください',
        account: 'ADアカウント名が必要です',
        budget: '予算を入力してください',
        registerAdd: '有効な企業住所を入力してください',
        registerAddress: '有効な企業住所を入力してください',
        businessAddress: '有効な会社用メールアドレスを入力してください',
        timezone: 'アカウントタイムゾーンが必要です',
        // country: '国が必要です',
        country: '地域が必要です',
        payMethod: '支払い方法が必要',
        adMethod: '配信方法が必要です',
        vCodeNeed: '正しい認証コードを入力してください',
        // 权限
        noUser: '権限の当たっているユーザー存在していません。',
        errorCampaign: '申し訳ありませんが、あなたは権限を持っていません。',
        errorUploadImgSize: 'アップロードされた画像サイズは500Kを超えた！',
        blackWhiteType: 'ブラックリストやホワイトリストを選択してください',
        sspRequire: '媒体アカウントを選択してください',
        // pixel
        accountRequired: 'アカウント名が必要です',
        conversionNameRequired: '変換名が必要です',
        categoryRequired: 'カテゴリーが必要です',
        valueRequired: '変換値が必要です',
        eventTypeRequired: '発火イベントカテゴリが必要です',
        repeatEmail: 'このメールアドレスは既に使用されています。別のメールを入力してください。',
        emailNotExist: 'メールアドレスが存在しません',
        selectUser: 'ユーザーアカウントが必要です',
        cpcInfo: 'ターゲットBidは小数点３位まで',
        cpcJpyInfo: 'ターゲットBidは小数点1位まで',
        targetCpaInfo: 'ターゲットCPAは小数点2位まで',
        cpcInfoTarget: 'ターゲット入札は変更されません',
        cpcNumber: 'ターゲット入札は数字でなければなりません',
        invalidFile: '郵便番号追加失敗。テンプレートの通りCSVをアップロードしてください。',
        sensitiveTag: '危ない広告タグは空き設置不可',
        cpcPercentMin: '输入框数值不能低于-99',
        cpcNumberInteger: '请输入整数',
        currencyCheck: '请选择账户结算币种',
        tradingCurrencyCheck: '请选择账户交易币种',
        legalRepresentativeCheck: '次のいずれかのオプションを選択してください',
        contractCheck: '请选择账户合同主体',
        cpcMin: '入力整数のみをサポートし、最小入力値は10.',
        cpcMax: '入力整数のみをサポートし、最小入力値は100である。推奨比率上限は150%以上である。',
        cpcMaxUp: '上限値は下限値より大きくなければならない。',
        cpaType: ' CPAタイプを選択してください'
    },
    currencySpecies: {
        dollar: 'USD（$）',
        bahtDollar: 'THB（฿）',
        japaneseDollar: 'JPY（￥）',
        RMB: 'CNY（CNY ￥）',
        taiwanDollar: 'TWD（NT$）',
        HKDollar: 'HKD（HK$）',
        singaporeDollar: 'SGD（S$）',
        wonDollar: 'KRW（₩）',
        malaysianRupiah: 'MYR（RM）',
        indonesianRupiah: 'IDR（Rp）'
    },
    infos: {
        info: '注意',
        total: '概要',
        emptyText: 'データなし',
        emptyReadChartText: 'READタグが設置されていないか、期間内のデータが存在しないため表示できません',
        allSelect: '全て',
        selected: '選択',
        dragTip: '（ドラッグして順番を変更可能）',
        conversionTip: '変換データ、{}を同期させるにはMediago PostBackリンクを使用してください。またはMediagoピクセル、{}を設定するための \'pixel \'ナビゲーションバーに移動します。変換データは2時間後にプラットフォームで更新されます。',
        DiscoveryConversionTip: '変換データ、{}を同期させるにはDiscovery PostBackリンクを使用してください。またはDiscoveryピクセル、{}を設定するための \'pixel \'ナビゲーションバーに移動します。変換データは2時間後にプラットフォームで更新されます。',
        conversionTipGo: '次のページを確認',
        conversionTipMore: '詳しくはこちら',
        postBack: 'ポストバックコード:',
        sessionInvalid: 'システムが更新されました、ページを更新してもう一度やり直してください',
        fileSizeLimit: '広告効果を最適化するために、画像の解像度を600 * 400ピクセルより大きくすることをお勧めします'
    },
    constants: {
        all: '全て',
        cancel: 'キャンセル',
        selectAll: '全て',
        eq: '同等',
        gt: 'より多い',
        lt: '未満',
        ge: 'より少ない',
        le: 'に満たない',
        platform: 'プラットホーム',
        os: 'OS',
        browser: 'ブラウザ',
        smartphone: 'スマートフォン',
        mobile: 'モバイル',
        desktop: 'デスクトップ',
        tablet: 'タブレット',
        xbox: 'Xbox',
        android: 'Android',
        ios: 'iOS',
        macOs: 'Mac OS',
        windows: 'Windows',
        linux: 'Linux',
        chrome: 'Chrome',
        firefox: 'Firefox',
        safari: 'Safari',
        ie: 'Internet Explorer',
        samsung: 'Samsung',
        opera: 'Opera',
        uc: 'UC Browser',
        inApp: 'InApp',
        edge: 'Edge',
        est: 'EST',
        usdname: 'USD',
        jpyname: 'JPY',
        cnyname: 'CNY',
        sgdname: 'SGD',
        myrname: 'MYR',
        krwname: 'KRW',
        idrname: 'IDR',
        thbname: 'THB',
        twdname: 'TWD',
        hkdname: 'HKD',
        utc0: 'UTC+0',
        utc7: 'UTC+7',
        utc8: 'UTC+8',
        utc9: 'UTC+9',
        standard: '時間内均等配信する',
        accelerate: '時間内均等配信しない',
        other: 'その他',
        daily: '毎日',
        hourly: '毎時',
        earlier: 'より早く',
        later: 'より遅い',
        at: 'On',
        white: 'White',
        black: 'Black',
        msn: 'MSN',
        mgid: 'MGID',
        // 合同主体
        mediago: 'mediago',
        jpSubject: 'jp.popin.cc',
        krSubject: 'kr.popin.cc',
        twSubject: 'tw.popin.cc',
        docomo: 'docomo',
        au: 'au',
        softbank: 'softbank',
        male: '男',
        female: '女',
        // 受众
        car: '車',
        election: '選挙',
        entertainment: 'エンターテイメント',
        money: 'お金',
        finance: '金融',
        foodDrink: '飲食',
        homepage: 'ホームページ',
        health: '健康',
        lifestyle: 'ライフスタイル',
        music: '音楽',
        movies: '映画',
        news: 'ニュース',
        sports: 'スポーツ',
        travel: '旅行',
        TV: 'テレビ',
        video: 'ビデオ',
        weather: '天気',
        education: '教育',
        gadgetsAutos: 'ガジェット・車',
        business: 'ビジネス',
        beautyFashion: '美容・ファッション',
        economyMoney: '経済とお金',
        homeLifestyle: '住まい・ライフスタイル',
        loveMarriage: '恋愛・結婚',
        politicsSocial: '政治・社会',
        travelGourment: '旅行・グルメ',
        autoselectronics: '自動車および電子機器',
        // 广告分类
        auto: '車',
        beautyFitness: '美容・フィットネス',
        beautyHairCare: '美容-ヘアケア',
        businessAndFinance: 'ビジネス・金融',
        careers: '職業の',
        computerElectronics: 'コンピューター・電子機器',
        eCommerce: 'Eコマース',
        eventsAndAttractions: 'イベント・アトラクション',
        familyAndRelationships: '家族と関係',
        financeInsurance: '金融・保険',
        food: '飲食',
        gaming: 'ゲーム',
        Gaming: 'ゲーム',
        healthcare: '健康管理',
        homeGarden: '住まい・庭',
        newsAndPolitics: 'ニュース・政治',
        pets: 'ペット',
        personalCareOralCare: 'パーソナルケア-オーラルケア',
        popCulture: 'ポップカルチャー',
        realEstate: '不動産',
        religionSpiritualty: '宗教・スピリチュアリティ',
        science: '化学',
        travelHospitality: '旅行・おもてなし',
        weightLoss: 'ダイエット',
        wellness: '健康',
        others: 'その他',
        content: 'コンテンツ',
        leadGen: '潜在的な顧客',
        search: '検索',
        insurance: '保険',
        recruitment: '採用',
        marriage: '結婚',
        medicalIndustry: '医療',
        diet: 'ダイエット',
        beautyCosmetic: '化粧品',
        homeAppliances: '家電製品',
        computer: 'コンピューター',
        communication: 'コミュニケーション',
        media: 'メディア',
        game: 'ゲーム',
        physicalEducation: 'スポーツ',
        beautyCosmetics: '美しさ',
        industry: 'カテゴリ'
    },
    requestMsg: {
        requestError: 'ネットワークエラーが発生しました、もう一度やり直してください',
        exportError: 'データのエクスポートに失敗しました、もう一度やり直してください',
        exportSuccess: '正常にエクスポートされました',
        statusUpdated: '正常に更新されました',
        updatedFailed: 'データのアップロードに失敗しました',
        createError: '作成に失敗しました',
        uploadFailed: 'アップロードに失敗しました、もう一度やり直してください!',
        uploadOverSize: '画像サイズは{num} MBを超えることはできません',
        uploadSuccess: 'アップロードに成功しました',
        gifSizeInfo: '適切なサイズで新しいイメージをアップロードしてください',
        logoutSuccess: '正常にログアウトしました',
        logoutFailed: 'ログアウトに失敗しました',
        modified: 'パスワードは正常にリセットされました！',
        loginFailed: 'アカウント名またはパスワードが間違っています',
        copySuccess: 'コピーされました',
        previewFirst: 'プレビュー画面を確認してください。'
    },
    backendMsg: {
        fail: '失敗',
        success: '成功',
        sendCodeSucs: '認証コード発送しました',
        alreadyExist: 'このメールアドレスは既に使用されています。別のものを入力してください',
        verificateFail: '正しい確認コードを入力してください',
        busy: 'サーバー実行中。',
        noPermission: '権限のない操作',
        gifError: 'GIFのサイズはCardと合わない',
        loginError: 'アカウントまたはパスワードが正しくありません',
        registError: 'このメールアドレスは既に登録されています',
        repeatCreate: '同じ広告既に存在しています。',
        operateTooMuch: '操作頻度高すぎる',
        amountError: '金額は0以下入力不可',
        accountExist: 'この広告アカウントはすでに存在します',
        companyExist: '法人名重複されています。',
        campaignNameExist: 'キャンペーン名は重複されています。',
        paramsError: 'パラメータが間違っています',
        addRepeat: 'Cannot Add Repeatedly',
        landingpageOrUtmIsInvalid: 'Landing Page或はutmチェック失敗',
        conversionExist: 'コンバージョン名は既に存在している',
        requestError: 'ネットワークエラーが発生しました、もう一度やり直してください',
        linkInvalid: '無効なトークン',
        roleExist: '役割名はすでに存在します',
        noResult: '認証制限時間超過した、或は認証コードまだ送信してない',
        ecommercePageNameRepeated: '入力されたページ名既に存在している',
        ecommerceSeoUrlRepeated: 'SEO URL既に存在している',
        auditTypeNameExist: '审计类型名称已经存在',
        tagExist: '标签已存在',
        readExist: 'READ タグすでに存在している。',
        completedDataAccumulation: 'このキャンペーンのデータ継承はすでに終了しました',
        sizeTooLarge: '画像の容量は1MB以内に',
        gifSizeTooLarge10: 'GIF画像サイズは10MB以内',
        imgTypeError: 'JPG,JPEG,PNG,GIFのみ対応'
    },
    // paypal充值
    paymentList: {
        time: '時間',
        id: '支払いID',
        status: '支払い状況',
        actualAmount: '実際金額',
        transferFee: '手数料',
        prepaidAmount: '支払金額',
        method: '支払方法',
        payer: 'PayPalアカウント',
        receipt: 'レシート',
        accountName: '広告口座',
        totalCoupon: '総クーポン',
        couponBalance: 'クーポンバランス',
        totalQuotaTip: '総バランス=総チャージ額+クレジットライン+総クーポン - 総費用',
        fold: '收折りたたみアカウントリスト起账户数据',
        expand: 'アカウントリストを展開します',
        recharge: 'チャージ',
        autoBilling: '自動チャージ',
        operate: '詳細',
        balanceLess0: 'このアカウントの残高は0です。'
    },
    // stripe支付
    stripeList: {
        status: 'ステータス',
        account: '広告口座',
        method: '支払方法',
        date: '最終請求日',
        amount: '最終請求量',
        spend: 'アカウント支払',
        card: 'お支払カード',
        ABAmount: '自動チャージ金額',
        edit: '編集',
        paymentDetails: '支払詳細',
        history: '支払履歴'
    },
    // geoEdge
    geoEdge: {
        type: 'アラートタイプ',
        time: '時間',
        detail: '詳細',
        detailLink: '詳細URL'
    },
    // bidAnalysis
    bidAnalysis: {
        adId: '広告ID',
        placementId: '配置ID',
        avgRank: 'ランク',
        bidMode: '入札モード',
        picpm: 'P-ICPM',
        pvcpm: 'P-VCPM',
        pictr: 'P-ICTR',
        pvctr: 'P-VCTR',
        pvwr: 'P-VWR',
        pvctrLevel: 'P-VCTRレベル',
        pvwrLevel: 'P-VWRレベル',
        cpc: 'CPC',
        campaignId: 'キャンペーンID',
        title: '広告タイトル',
        linkTo: '入札分析'
    },
    // 电商
    'nativeE-commerceTool': {
        createFormAccountName: 'アカウント名',
        createFormProductName: '商品名',
        createFormSampleLPUrl: 'サンプルLP URL',
        createFormParseBtn: 'Parse',
        createFormPageName: 'ページ名',
        createFormPageNameLength: '300文字以下入力してください',
        createFormImages: '画像',
        createFormImagesBtn: 'すべての画像を見る',
        createFormreplaceKeyword: 'キーワードを切り替える',
        createFormreplaceKeyword1: '全部を切り替える',
        createFormreplaceKeyword2: 'に',
        createFormAddRules: 'ルールを追加します',
        createFormMatchCase: 'マッチケース',
        createFormCTAURL: 'CTA URL',
        createFormSEOURL: 'SEO URL',
        createFormSeoTagTitle: 'SEO タイトル',
        createFormseoDescription: 'SEO Desription',
        createLPBtn: '新規LPを追加',
        createOPBtn: '新規OPを追加',
        parseSuccess: '解析成功',
        parseFail: '解析失敗',
        searchLPFilter: '検索ページの名前',
        searchButton: '検索',
        productMultipleChoice: '商材項目は１つ以内入力してください。',
        coppyOPHTML: 'HTMLをコピーします',
        productChoice: '商材を選択してください',
        opCreatedPageName: 'PageNameを入力してください',
        coppyLPLinkButton: 'リンクをコピーする',
        selectDomain: 'ドメイン名を選択してください',
        addTemplate: '新しいメールテンプレートを追加する',
        searchtemplate: 'メールテンプレートを検索',
        emailTemplateName: 'メールテンプレートの名前',
        status: 'ステータス',
        domain: 'ドメイン',
        emailAddress: 'メールアドレス',
        emailPassword: 'パスワード',
        lastModifiedDate: '最終更新日',
        creationUser: '作成ユーザー',
        lastModifiedUser: '最後の修正ユーザー',
        operation: '操作',
        domainName: 'ドメイン名',
        templateName: 'テンプレート名',
        conditions: '状態',
        storeLogo: '店のロゴ',
        storeName: '店名',
        emailTitle: 'メールタイトル',
        emailContent: 'メール内容',
        emailImage: 'メールイメージ',
        emailFooter: 'フッター',
        addNewEmailTemplate: '新しいメールテンプレートを追加する',
        editEmailTemplate: 'メールテンプレートを編集する',
        copyEmailTemplate: 'メールテンプレートをコピーする',
        deleteEmailTemplate: 'メールテンプレートを削除する',
        recommendedSize: 'おすすめサイズ'
    },
    supplement: {
        bulkUpload: '一括入稿',
        regionEurope: '地区：GE、FR、IT、ES、AT',
        editCompany: '会社情報の編集',
        agentAccount: '代理店口座',
        selectAgentAccount: '代理店口座を選択してください',
        externalWebsite: '会社情報を記入するための外部ウェブサイトリンク',
        change: '改訂',
        editAccount: 'アカウントの編集',
        addAccount1: 'アカウントを追加する',
        pleaseEnterContent: 'コンテンツを入力してください',
        openingInformation: 'アカウント開始情報',
        agencyPublisher: '選択したアカウントがAgency Publisherの場合は、選択したら変更することはできません。',
        specifiedSSP: '指定されたSSP',
        operationType: '操作タイプ',
        accountManager1: 'アカウント/マネージャー',
        progressInDescription: '進捗説明',
        descriptionOfCurrentProgress: '現在の進捗の説明',
        currentProgress: '現在の進捗状況の説明を入力してください',
        pleaseSelect: '選択してください',
        quantityAllotted: '割り当て額',
        discoveryOnline: 'Discoveryオンライン契約検索',
        seleced: 'Selected',
        editRemark: '備考欄の編集',
        bid: '入札',
        noBid: '入札なし',
        fixedBid: '固定入札',
        smartBid: 'Smart Bid',
        noAdBid: '審査待ちの広告入札設定はありません',
        EntNumber: '-1から1の間の数字を入力してください',
        trainPrice: 'トレーニング入札',
        floorPrice: '最低価格',
        maxPrice: '最大価格',
        limitCPC: '上限CPC',
        atLeast: '最低1時間または1日が必要です',
        noCreateAPI: 'アカウントはAPIを作成するための条件を満たしていません',
        eventArea: 'イベント領域を選択してください',
        pleaseenterrecipientemail: '受信者のEメールを入力してください',
        billingInformation: '課金情報',
        cooperationMode: '協力モード',
        viewRS: 'RSの変更履歴を表示します',
        setRS: '新しいRS設定',
        onceEnter: '入力後、変更不可となります',
        billingCycle: '支払いサイト',
        financialEmail: '経理メールアドレス',
        sendInvoice: '請求書情報を送信するために使用され、複数のEメールを区切って追加することができます。',
        mediaInformation: 'メディア情報',
        trafficType: 'トラフィックタイプ',
        adTool: '広告ツール',
        addUser1: 'ユーザーを追加する',
        userBackground: 'ユーザーの背景',
        authorizationConfig: '承認設定',
        roleConfig: '役割設定',
        remenberUser: 'ユーザーが正常に追加された場合は、次のユーザーアカウントのパスワードを確認してください。',
        userName: 'ユーザー名',
        userPassword: 'ユーザーのパスワード',
        advertiserLogin: 'ユーザーが正常に追加された場合は、広告主のプラットフォームパスワードでログインしてください。',
        done: '終了',
        accountORcompany: 'アカウント/会社名を入力してください',
        selectRole: '役割を選択してください',
        selectBMaccount: 'BMアカウントを選択してください',
        selectWritable: '書き込み可能なアカウントを選択してください',
        selectReadable: '読み取り可能なアカウントを選択してください',
        accAuthorization: 'アカウントの承認',
        enterAmountCoupon: 'クーポン金額を入力してください',
        enterBM: 'ビジネスマネージャーを入力してください',
        enterIndustryClassification: '業界の分類を入力してください',
        enterIndustry: '業界を入力してください',
        selectAM: 'アカウントマネージャを選択してください',
        selectContractType: '契約タイプを選択してください',
        selectAccountLanguage: 'アカウント言語を選択してください',
        selectCompanyType: '会社タイプを選択してください',
        addressCompany: '会社の事業住所を入力してください',
        enterAddress: '会社登録住所を入力してください',
        enterValid: '有効なリベートレートを入力してください',
        selectAppropriate: '適切な代理店口座を選択してください',
        onHold: '保留',
        cVROPC1: 'このCVR最適化と広告入札の作成',
        cVROPC2: 'OCPCリンケージ、慎重にお願いします。',
        cVROPC3: '1）キャンペーン入札が固定入札の場合、このCVR最適化構成は広告主には認識できません。広告主が固定BIDをOCPCに変更した場合、このCVR最適化構成はOCPCのデフォルト設定として使用されます。',
        cVROPC4: '2）キャンペーン入札がOCPCの場合、このCVR最適化構成はOCPCとリンクされているため、広告主はそれを認識して注意して使用することができます。',
        algorithmic: 'アルゴリズム最適化オブジェクトは、',
        changedAfterSetting: '設定後に変更できません',
        cpa: '0より大きい数値でなければなりません',
        completed: '完了',
        pleaseEnter1: '入力してください',
        profitRatio: '利益率は数字で入力してください',
        yueBao1: '（内部資料。社内秘）',
        yueBao3: '運用アラート、クリエティブ画像、詳細データインジケータは社内閲覧のみ。広告主へ共有禁止',
        dailySubsidy: 'Dailyボリュウム補填は０以上記入してください。',
        totalSubsidy: 'トータルボリュウム補填は０以上記入してください。',
        accelerate: '配信加速係数は0-20で記入必要',
        impBound: '配信加速係数は0-10000で記入必要',
        contains: 'を含みます',
        percentage: '割合',
        creation: '新着',
        processing: '処理',
        accepting: '受け入れる',
        clear: 'クリア',
        mon: '月曜日',
        tue: '火曜日',
        wed: '水曜日',
        thu: '木曜日',
        fri: '金曜日',
        sat: '土曜日',
        sun: '日曜日',
        selected: '選択',
        unselected: '未選択',
        less1: 'Stripe手数料必要、MediaGoでチャージされる金額が',
        less2: 'より少なくなります。',
        whenRecharge: '設定された制限がなくなると、Stripeは自動的に保存されたクレジットカードから定額チャージされます。自動チャージ金額は自動チャージで設定できます。',
        whenAmount: 'アカウント消耗金額に設定した金額だけアカウントが使い果たされると、Stripeは保存されたクレジットカードで設定した金額を差し引きます。設定された自動チャージ金額が設定されたアカウント消耗金額を下回ることはできません。',
        autoBillingAmount: '自動請求金額',
        paymentDetails: '支払詳細',
        dueAmount: '送金手数料のため、実際の受取金額はお支払い金額より少ないと予想されます。',
        selectAdAccount: '広告アカウントの選択',
        minimum: '最小額は50.00ドルです。',
        transferAmount: '「送金手数料全額支払い（PayPal手数料を含む）」',
        paypal1: 'MediaGoで',
        paypal2: 'の全額を受け取るには、Paypalが請求する手数料を含めて',
        paypal3: 'を支払う必要があります。',
        amountPayable: '未払額',
        transferFee: '送金手数料',
        rechargeAmount: 'チャージ金額の2.9% +取引あたり$0.30',
        actualAmount: '実際の金額',
        cancel: 'キャンセル',
        payNow: '即時支払',
        paymentHistoryDetails: '支払履歴詳細',
        paymentAccount: '支払口座',
        rechargeAmount1: 'リチャージ金額',
        amountSpend: 'Amount Spend',
        account: 'アカウント',
        PayPalmore1: 'Paypalによる基本取引手数料は、取引金額の2.9％に1件あたり0.30ドルが加算されます。',
        PayPalmore2: '詳しくはこちらをご覧ください。',
        automaticBilling: '自動請求',
        automaticBilling1: 'この機能を設定すると、Stripeは次の自動支払いのためにクレジットカード情報を自動的に保存します。',
        monthlyOperationalReport: '月間レポート',
        currentIncome: '現在の収益',
        percentageOfConsumption: '消費量の割合',
        lastPeriodIncome: '前期の収益',
        yearOnYearLastWeek: 'WoW',
        yearOnYear: 'YoY',
        comparisonFunction1: '比較機能の使用のヒント：',
        comparisonFunction2: 'ステップ1：左側の現在の期間の時間間隔を選択します',
        comparisonFunction3: 'ステップ2：右側の比較の開始時刻を選択してください',
        display: '円グラフのデータを表示します',
        comparison: '比較',
        categoryOfEvent: 'イベントカテゴリー',
        adAccount: 'アカウント',
        category: 'カテゴリ',
        campaignName: 'キャンペーン名',
        priority: '優先度',
        status: 'ステータス',
        accountManager: 'AM担当',
        currentPersonInCharge: '現担当',
        addTracker: '新築の件',
        uploadImage: '画像アップロード',
        blackFormat: 'ブラックフォーマット',
        blackPageType: 'ブラックページタイプ',
        forstyle: 'スタイルフィルタリングの場合は、英語の「;」と複数の値を区切ります。例えば：Infopane;river',
        forpage: 'PageTypeフィルタリングの場合、複数の値は英語で区切られています。例：edgechrmtp; 25; 36.',
        startDate: '開始日',
        accountSource: 'アカウントソース',
        accountNature: 'アカウントの性質',
        contractTitle: '契約タイトル',
        aPIToken: 'APIトークン',
        serviceType: 'サービスタイプ',
        businessManagement: 'ビジネスマネージメント',
        advertiser: '広告主',
        aPIhasinstalled: 'APIがインストールされました',
        withoutSettingAPI: 'APIを設定なし',
        openAccountIndependently: '独立アカウントを開設',
        inviteToOpenAccount: 'オープンアカウントに招待します',
        searchName: 'アカウント/会社名の検索',
        previewImgTitle: 'クリア',
        accountName: 'アカウント名',
        accountID: 'アカウントID',
        companyName: '会社名',
        // country: '国',
        country: '地域',
        billingEmail: '請求Eメール',
        organizationName: '組織名',
        businessRebate: 'ビジネスリベート',
        email: 'アカウント保有者のEメール',
        paymentMethod: '支払方法',
        deliverymethod: '入金方法',
        accountTimeZone: 'アカウントタイムゾーン',
        businessManager: '営業担当',
        industryCategory: '産業カテゴリー',
        creationTime: '作成時間（EST）',
        operate: 'オペレート',
        accountDetails: 'アカウント詳細',
        accountInformation: '口座情報',
        organizationWebsite: '組織のウェブサイト',
        accountHolderInformation: '担当者メールアドレス',
        totalAccountBudget: '総口座収支',
        timeZone: '時間帯',
        company: '会社',
        mediaGoNon: 'MediaGo Non-Docking 会社',
        registeredAddressOfCompany: '会社の登録アドレス',
        companyAddress: '会社の住所',
        emailaddress: '有効なメールアドレスを入力ください',
        prepay: '前払い',
        postpay: '後払い',
        mark1: 'アカウント作成APIの条件：1.アカウントの性質は広告主（2番目の確認）です2.アカウントのステータスが開いています3.アカウントの消費量は5000ドルに達しています',
        generateToken: 'トークンを生成します',
        mark2: '注：Taboolaに配置されている広告は、データを牽引するためにAPIを使用できません。',
        addUserRW: 'ユーザーを追加すると、アカウントに対するユーザーの読み取り可能な権限が自動的に有効になります。',
        otherUsers: '他のユーザー',
        operator: 'オペレーター',
        operatingTime: '稼働時間',
        specificIndustry: '具体的な産業',
        add: '追加',
        automaticReporting: '自動レポーティング',
        reportTimeZone: 'レポートタイムゾーン',
        recipient: '受信者',
        sendingFrequency: '送信頻度',
        stopsending: '終了後にメールの送信を停止する',
        consistent: 'アカウントのタイムゾーンと一致します',
        mark4: '複数のメールをコンマで区切って入力できます',
        daily: 'デイリー',
        monthly: 'マンスリー',
        advertiserName: '広告主名',
        advertiserEmail: '広告主のEメール',
        advertiserApplication: '広告主のアプリ',
        loginInfo: 'ログイン情報',
        applicationCompleted: 'アプリケーションが完了しました',
        postpay1: '後払い',
        accountType: '口座の種類',
        selfService: 'セルフサービス',
        managed: '管理された',
        reportingTimezone: 'タイムゾーンの報告',
        selectthetimezone: 'キャンペーンを管理する時間帯を選択してください。設定後は変更できません。',
        organizationName1: '機構名称',
        asthiswil: '請求書に直接影響を与えるため、公式登録書類を反映するために、組織内訳を慎重かつ正確に入力してください。',
        organizationAddress: '機構住所',
        asthiswilldirectly: '請求書に直接影響を与えるため、公式登録書類を反映するために、組織内訳を慎重かつ正確に入力してください。',
        organizationWebsite1: 'ウェブサイト管理',
        initialCreditLine: '初期クレジットライン',
        ifyou: '初期クレジットラインは０以上設定すると、AMチームがメールで確認する予定',
        advertisernameisrequired: '広告主の名前が必要です',
        registeraddressisrequired: '登録アドレスが必要です',
        pleaseEnter: 'http:またはhttps:から始まる有効な会社のウェブサイトを入力してください',
        workEmail: 'メールアドレス',
        workEmail1: '（ユーザー名として使用されます）',
        firstName: '名前',
        lastName: '姓',
        password: 'パスワード',
        pleaseenteravalidemailaddress: '有効なメールアドレスを入力してください',
        pleaseenteravalidfirstname: '有効な名前を入力してください',
        pleaseenteravalidlastname: '有効な姓を入力してください',
        specialcharacterandnumber: 'アルファベット、記号、数字を組み合わせて少なくとも8文字を入力してください',
        next: '次へ',
        previous: '戻る',
        submit: '送信',
        officialWebsite: '公式ウェブサイト',
        pleaseenteryouremail: 'あなたのメールアドレスを入力してください',
        pleaseentercompanyURL: '会社のURLを入力してください',
        pleaseentertheaccountname: 'アカウント名を入力してください',
        pleaseenterbudget: '予算を入力してください',
        deliveryMethod: '配達方法',
        pleaseselectacompany: '会社を選択してください',
        addCompany: '会社を追加する',
        ifthere: 'オプションに会社がない場合は、追加して選択してください。',
        customerSource: '顧客の情報源',
        terminalclient: 'ターミナルクライアント',
        agent: 'エージェント',
        contractType: '契約タイプ',
        contractType2: '契約タイプ',
        adding: 'ユーザーを追加すると、アカウントに対するユーザーの読み取り可能な権限が自動的に有効になります。',
        pleasechooseanotherrole: '別の役割を選択してください。',
        registeredaddressofthecompany: '会社の登録住所',
        companyBAddress: 'B会社住所',
        bysubmitt: 'MediaGoのプライバシーポリシーに同意して送信をする',
        authorizedUser: '権限与えたユーザー',
        unauthorizedUser: '権限なしユーザー',
        name: '名前',
        usersource: 'ユーザーソース',
        loginMethod: 'ログイン方法',
        workEmail2: 'ワークEメール',
        companyName1: '会社名（プラットフォーム入力入力した名前）',
        position: 'ポジション',
        numberOfWritableAccounts: '書き込み可能なアカウント数',
        numberOfReadableAccounts: '読み取り可能なアカウント数',
        role: '役割',
        searchCompanyName: '会社名+ Name + Eメールを検索',
        userInvitationLink: 'ユーザー招待状リンク',
        details: '詳細',
        userRegistrationInformation: 'ユーザー登録情報',
        userSource: 'ユーザー情報源',
        lastName1: '苗字',
        name1: '名前',
        companyWebsite: '会社のウェブサイト',
        telephone: '電話',
        userRole: 'ユーザーロール',
        accountAllocation: 'アカウント割り当て',
        bMAccount: 'BMアカウント',
        writableAccount: '書き込み可能な口座',
        readableAccount: '読みやすい口座',
        addingordeletinga: 'BMアカウントを削除すると紐づいている子アカウントが全て削除されるためご注意ください',
        edit: '編集',
        ordinal: '順序数',
        roleName: '役割名',
        assignedNumber: '割り当てられた番号',
        changeTime: '時間の変更',
        createRole: '役割を作成',
        pleaseentertherolename: '役割を入力してください',
        mediaGoAdvertiser: 'MediaGo広告主',
        baiduReviewer: 'Baidu Reviewer',
        microsoftReviewer: 'Microsoft Reviewer',
        baiduRD: 'Baidu RD',
        baiduBD: 'Baidu BD',
        baiduAM: 'Baidu AM',
        financialStaff: '財務スタッフ',
        admin: 'admin',
        dataAnalyst: 'Data Analyst',
        mGIDReviewer: 'MGID Reviewer',
        parentologyReviewer: 'Parentology Reviewer',
        helpCenter编辑: 'ヘルプセンター',
        financialReceivable: '財務債権',
        roleManager: 'ロールマネージャー',
        prebidTestReviewer: 'Prebid Test Reviewer',
        rTBDemandReviewer: 'RTBDemand Reviewer',
        zipporReviewer: 'Zippor Reviewer',
        adPushupReviewer: 'AdPushup Reviewer',
        advertiser1: '代理店-advertiser',
        pubGeniusReviewer: 'PubGenius Reviewer',
        insticatorReviewer: 'Insticator Reviewer',
        streamAmpReviewer: 'StreamAmp Reviewer',
        financialStaffPaypal: '財務スタッフ-PayPal',
        eCommerceAM: 'E-commerce AM',
        eCommerceROIReport: 'E-commerce ROI Report',
        adminR: 'Admin-R',
        internalTestAdvertiser2: '社内用テスト広告主2',
        offlineProject: 'Finance-Offline Project',
        bulkReview: '一括審査',
        domainWhitelist: 'ドメインホワイトリスト',
        financialReport: '財務報告',
        stripeAutoBilling: '社内用テスト広告主-Stripe Auto Billing',
        discoveryAdvertiser: '広告主',
        discoveryFinancalTest: '財務テスト',
        discoveryOldAdvertisers: '旧広告主',
        discoveryOldAgency: '旧代理店',
        oldDiscoveryAgency: '旧代理店',
        discoveryNewadvertisers: '新しい広告主',
        platformExperienceRead: '',
        bidbydomain: 'ドメイン別入札',
        newDiscoveryAdvertiser: '新しい広告主',
        dashboard: 'ダッシュボード',
        reviewDetails: '審査詳細',
        campaignDetails: 'キャンペーンの詳細',
        campaignCopy: 'キャンペーンのコピー',
        campaignEdit: 'キャンペーンの編集',
        adStatus: '広告ステータス',
        domainBlock: 'ドメインブロック',
        domainTabsspColumn: 'SSPのドメインタブ',
        reviewDetails1: 'メディアグループの詳細を確認',
        adColumnSize: '広告列とサイズ',
        dimensionFilter: '寸法フィルター',
        conversiontype: 'コンバージョンタイプ',
        oCPC: 'OCPC',
        domainSetCampaignCpc: 'ドメイン別CPC',
        eCPC: 'ECPC',
        adOperation: '広告稼働',
        analysis: '分析',
        blackwhiteconfig: 'ブラックホワイトリスト設定',
        campaignOperationConfig: 'キャンペーン計画オペレーション',
        taboolaAdPull: 'Taboola広告取得',
        blacklistconfig: 'キャンペーン計画PageIDブロックリスト設置',
        reminder: 'キャンペーン計画異常リマインド',
        log: 'キャンペーン計画操作ログ',
        advertiserOperationRecord: '広告主操作記録',
        multiDimensionalreport: 'マルチレポート',
        tracker: 'トラッカー',
        bidAnalysis: '入札分析',
        diagnosis: '広告診断',
        innerFAQ: '内部FAQ',
        sample: 'サンプル',
        newCampaign: '新しいキャンペーン',
        win10adTargeting: 'Win10 AD-Targeting',
        userPackage: 'userpackage.',
        more: 'もっと',
        // domainwhitelist: 'DomainWhiteList',
        impURL: '3rd party',
        // locationJapan: 'ロケーションジャパン',
        // monthlyCapStatus: '月予算',
        // objective: '目的',
        // audience: '観客',
        pixel: 'ピクセル',
        conversionlist: 'CVリスト',
        readlist: 'Readリスト',
        newConversion: '新しいCV',
        dataReport: 'レポートデータ',
        lPManagement: 'LP管理',
        oPManagement: 'op Management',
        productManagement: '製品管理',
        domainManagement: 'ドメイン管理',
        customerManagement: '顧客管理',
        rOIAnalysis: 'ROI分析',
        paymentManagement: '支払い管理',
        reviewAds: '広告を確認してください',
        startReviewing: 'レビューを始める',
        detail: '詳細',
        category1: 'Category',
        geoEdge: 'Geo Edge',
        sensitiveCategory: '敏感なカテゴリ',
        auditLabel: '審査ラベル',
        displayallauditedimg: '監査されたすべてのIMGを表示します',
        authorization: '承認',
        accountList: 'アカウントリスト',
        addAccount: 'アカウントを追加する',
        link: 'アカウント開設招待状リンク',
        authorize: '承認',
        ignore: '無視',
        copyInvitationLink: 'コピー招待状リンク',
        bDAccountOpening: 'BDアカウント開口部',
        userList: 'ユーザー権限',
        roleList: '役割リスト',
        delete: '削除',
        create: '作成',
        billing: '請求する',
        recharge: 'チャージ',
        stripe: '縞',
        autoBilling: '自動請求',
        multiCurrencySwitcher: 'マルチ通貨スイッチャー',
        finance: 'ファイナンス',
        creditLine: 'クレジットライン',
        creditLine1: 'クレジットライン',
        coupon: 'クーポン',
        rOIReport: 'ROIレポート',
        receivable: '売掛金',
        payable: 'お支払いください',
        offline: 'オフライン',
        bannerViewableTest: 'バナーが表示されます',
        viewableTest: '閲覧可能なテスト',
        hibernation: '冬眠',
        aDCallback: '広告コールバック',
        adBidConfig: 'AD BID設定',
        review: '審査',
        history: '履歴',
        generateLPWrapper: 'ランニングページ作成',
        getEmail: '電子メール検証コードを入手してください',
        write: '書く',
        read: '読む',
        currencyType: '通貨タイプ',
        open: '独立してアカウントを開く',
        inviteTo: '既存アカウントに招待します',
        uSD: 'USD',
        cNY: 'CNY',
        jPY: 'JPY',
        sGD: 'SGD',
        hKD: 'HKD',
        tWD: 'TWD',
        tHB: 'THB',
        iDR: 'IDR',
        kRW: 'KRW',
        mYR: 'MYR',
        totalBalance: '総合収支',
        totalRecharge: 'チャージ',
        availablequota: '利用可能なクォータ',
        creditLineSetting: 'クレジットラインの設定',
        creditLineRecord: 'クレジットラインの記録',
        rechargeRecord: 'チャージ履歴',
        pleaseentertheamounttorecharge: 'チャージ金額を入力してください。',
        asof: 'そのまま',
        validAmount: '数値で入力してください',
        remark: '備考',
        pleaseenteraremark: '備考を入力してください',
        uTSDataUpdateTime: 'UTSデータ更新時刻',
        totalCoupon: '総クーポン',
        couponConsumption: 'クーポン消費量',
        effectiveCouponBalance: '効果的なクーポンバランス',
        effectiveTime: '効果的な時間',
        expireDate: '有効期限',
        offlineTime: 'オフラインタイム',
        profitMarginSetting: '利益率の設定',
        pleaseenterprofitmargin: '利益率を入力してください',
        profitMarginRecord: '利益率記録',
        currentIncome1: '現在の収入',
        currentCost: '現在のコスト',
        currentProfit: '現在の純利益',
        profitMargin: '利益率',
        impression: '表示',
        clicks: 'クリック数',
        totalHistory: '全体',
        totalHistoricalIncome: '全履歴収入',
        totalHistoricalCost: '全履歴コスト',
        historicalProfitMargin: '利益率',
        lastUpdateTime: '最後の更新時刻',
        enterAccountCompanyName: 'アカウント会社名を入力してください',
        media: 'メディア',
        currentIncome2: '現在の収入=口座の消費電流（クーポン含まない）',
        pullAds: '広告を引く',
        interfaceSetting: 'インターフェース設定',
        adInterface: '広告インターフェース',
        testField: 'テストフィールド',
        campaignId: 'キャンペーンID',
        aDId: '広告ID',
        onlineEnvironment: 'オンライン環境',
        testEnvironment: 'テスト環境',
        developmentEnvironment: '開発環境',
        testNoBilling: '1-課金なし(test)',
        realBilling: '0-課金',
        multipleids: 'コンマで区切られた複数のID。例：111,222',
        multipleids1: 'コンマで区切られた複数のID。例：111,222',
        pageParameters: 'ページパラメータ',
        placement: 'プレースメント',
        pageURL: 'ページのURL',
        category2: 'カテゴリー',
        adTypeSetting: '広告タイプ設定',
        thereserveprice: '準備価格、デフォルトは0です。例えば：0.43',
        fixedIndex: '固定インデックス：英語のコンマ区切り、デフォルトインデックス',
        geographicalConfig: '地理的な設定',
        zipCode: '郵便番号',
        iP: 'IP',
        language: '言語',
        unitedStatesState: 'アメリカ合衆国',
        noneMSNMayNotTransmit: 'NONE-MSNは送信しない場合があります',
        stateData: '州データ',
        deviceInformation: 'デバイス情報',
        userAgent: 'ユーザーエージェント',
        personalInformation: '個人情報',
        microsoftUID: 'Microsoft UID',
        copymuid: 'Microsoft UIDをコピーします',
        refreshmuid: 'Microsoft UIDをリフレッシュします',
        fuzzyMatch: 'あいまい一致',
        sspId: 'SSP ID',
        selectRecallStatus: 'Recall Statusを選択してください',
        notrecallable: 'リコールできない',
        recallable: 'リコール可能です',
        inquire: 'お問い合わせ',
        column: '桁',
        campaign: 'キャンペーン',
        ad: '広告',
        generate: '生成',
        getVerificationCode: '検証コードを入手してください',
        verificationCode: '検証コード',
        none: 'なし',
        obtain: '入手',
        pid: 'Pid',
        priceStatus: '価格のステータス',
        trainPriceStatus: 'テスト価格のステータス',
        sSPId: 'SSP ID',
        imageSize: '画像サイズ',
        format: 'フォーマット',
        pidProfit: 'Pid利益',
        cTRSwitch: 'CTRスイッチ',
        priceMode: '価格モード',
        toBeReviewed: 'レビューするために',
        belowReservePrice: '下記の予約価格',
        reset: 'リセット',
        search: '検索',
        addPid: 'PIDを追加してください',
        startReview: 'レビューを始める',
        adId: '広告ID',
        mediaAccount: 'メディアアカウント',
        mediaAccountName: 'メディアアカウント名',
        site: 'サイト',
        creativeID: 'クリエイティブID',
        browser: 'ブラウザ',
        platform: 'プラットホーム',
        oS: 'OS',
        stateRegion: '州地域',
        cost: '費用',
        revenue: '収益',
        click: 'Click',
        vCPM: 'VCPM',
        cTR: 'CTR',
        avgCPC: '平均CPC',
        reqAdNum: '広告リクエスト数',
        reqNum: 'リクエスト数',
        resNum: '回答数',
        conversions: 'CV',
        conversionRate: 'CV率',
        conversionPrice: 'CV単価',
        viewContent: '商品LPに遷移',
        appInstall: 'インストール',
        completeRegistration: '登録完了',
        addToCar: 'カートに追加',
        addPaymentInfo: '支払い情報を登録',
        startCheckout: 'チェックアウトを開始します',
        purchase: '購入完了',
        addToWishlist: 'お気に入りに追加',
        lead: 'リード',
        other: 'その他',
        addTitle: '表題を加える',
        benchmark1: 'ベンチマークルール：',
        benchmark2: '各カテゴリのすべてのキャンペーンデータをカテゴリ別の生データとしてエクスポートします',
        benchmark3: '生のキャンペーンデータをフィルタリングする',
        benchmark4: '承認されたキャンペーンを保持します（拒否とアーカイブを除く）',
        benchmark5: '今月のキャンペーンをVimpが1Wを超え、クリック数が100を超え、支出が$ 100を超える状態に保つ',
        benchmark6: '残りのキャンペーンをCPCとCTRでカテゴリに並べ替えて、CPCとCTRの範囲を取得します',
        benchmark7: '優れた材料選択規則：',
        benchmark8: '各カテゴリのすべてのキャンペーンデータをカテゴリ別の生データとしてエクスポートします',
        benchmark9: '生のキャンペーンデータをフィルタリングする',
        benchmark10: '承認されたキャンペーンを保持します（拒否とアーカイブを除く）',
        benchmark11: '各カテゴリで今月消費する上位10のキャンペーンを除外します',
        benchmark12: 'トップキャンペーンで広告をフィルタリングし、vimpが2Wを超える広告を除外します',
        benchmark13: 'カテゴリ別にトップ広告を選択：クリエイティブが重複していないことを確認します。クリエイティブが重複している場合、ランキングは延期されます',
        benchmark14: 'コンテンツ：フィルタリングされた広告をvctrに従って均一に並べ替え、上位10個の広告をvctrで選択します。',
        benchmark15: 'Eコマース：フィルタリングされた広告をそれぞれcvrとvctrに従って並べ替えます',
        benchmark16: 'cvrでトップ5の広告を選ぶ',
        benchmark17: 'vctrによる上位5つの広告を選択します',
        benchmark18: '財務：選択した広告をvctrに従って均一に並べ替え、上位10個の広告をvctrで選択します。',
        locationJapan: 'ロケーションジャパン',
        monthlyCapStatus: '月予算',
        domainwhitelist: 'モバイルドメイン',
        objective: '目的',
        audience: '観客',
        remarkisrequired: '備考は空にすることはできません',
        amountRequired: '調整が必要な金額を完全に入力してください',
        addUser: 'ユーザーを追加する',
        privacyPolicy1: 'MediaGoの',
        privacyPolicy2: 'プライバシーポリシー',
        privacyPolicy3: 'と',
        privacyPolicy4: '規約',
        privacyPolicy5: 'に同意します',
        previous1: '申請いただきありがとうございます。最短でご連絡させていただきます。',
        previous2: 'できるだけ早く連絡いたします。',
        previous3: 'ご不明点がある場合はこちらにご連絡ください:',
        previous4: 'できるだけ早く回答いたします。',
        startAuthorizing: '承認を開始する',
        searchbyAccountType: 'アカウントタイプの検索',
        youImageHere: 'ここにあなたの画像',
        targetMCPA: '目標MCPA',
        targetCPA: '目標CPA',
        confirmSubmission: '提出後、アルゴリズム最適化対象は変更できない。提出の確認?',
        accountName1: 'アカウント名',
        campaginID: 'キャンペーンID',
        currentManager: '現在のマネージャー',
        addRole: '役割追加',
        loginAddress: 'ログインアドレス',
        permanentlyDelete: 'この操作で役割を削除します。確定しましたら、「続き」をクリックしてください。',
        goon: '続き',
        verifyThat: 'API Tokenを作成',
        applicationTitle: '申込完成。下記ログイン情報を大事に保管してください。',
        noApecificAccount: '該当特定アカウント存在しません。',
        noMoreThan: '（数値）まで超過している',
        pleaseSelectItemCategory: 'カテゴリーを選択してください。',
        pleaseSelectStatus: 'ステータスを選択してください。',
        pleaseSelectPriority: '優先度を選択してください。',
        pleaseSelectResponsiblePeople: '担当を選択してください',
        theAbnormalExposure: 'Imp異常',
        blackFormat1: 'Black Formatを記入してください',
        blackPlacement: 'Black Placementを選択してください。',
        keyGoal: 'コアターゲット目標は100文字以内記入してください。',
        advertisingCategory: '商材名は100文字以内記入してください。',
        communicationChannel: 'コミュニケーション手段は100文字以内',
        otherInfo: 'その他の情報は200文字以内',
        expireTime: '終了期間を選択してください。',
        profitRate: '修正希望の利益率を記入してください',
        amount: 'チャージ金額を記入してください。',
        editCreditline: 'クレジットラインを記入してください。',
        recordValue: '現状クレジットライン',
        totalRecharge1: 'チャージ済み金額',
        cannotBeModified: '確認後修正できません。確認しますか',
        checkNumber: '有効な数値を記入してください。',
        password1: '大文字と小文字を各々少なくとも一文字以上入力してください',
        password2: '一文字以上の記号(#@!~%^&*)を入力してください',
        password3: '一文字以上の数字を入力してください',
        password4: '8桁以上の文字列を入力してください',
        yueBao2: 'オススメのクリエティブ',
        undef: '未定義',
        affiliate: 'アフィリエイト',
        affiliateName: 'アフィリエイト名',
        billingPeriod: 'サイト周期',
        conversionDeduction: 'コンバージョン抜き',
        income: '売上',
        offerId: 'Offer ID',
        offerName: 'Offer 名',
        benchmark: 'ベンチマーク',
        content: 'コンテンツ',
        ecommerceCtr: 'EC CTR',
        ecommerceCvr: 'EC CVR',
        'lead gen': 'リード獲得',
        noMore: 'PDCA記録は800文字以内',
        noUndef: 'PDCA記録は必要項目。',
        comparison1h: '1時間比較',
        comparison24h: '24時間比較',
        comparison1w: '1週間比較',
        now: '今は',
        optimizationType: 'アリゴリズム最適化対象を選択してください。設定後変更できません。',
        generateAnalysisReport: 'インサイトレポート作成',
        reportEmail: '（レポートは指定するメールアドレスまで送付します）',
        confirmSendingReport: 'レポート内容確認する。',
        lineChart1: '表示可能なデータ存在しません。',
        lineChart2: '広告IDとPlacement IDを選択して、データ確認。',
        searchPlacementID: 'Placement IDを検索',
        sampleId: 'サンプルID',
        sampleName: 'サンプル名',
        bucketId: 'Bucket ID',
        description: '説明',
        conf: '設置',
        type: 'タイプ',
        flow: 'flow',
        correctFormat: '正しいフォーマットで入力してください。例：12-20',
        noNumberLR: '左文字或いは右文字は数字以外の文字となっています。',
        defaultHomePage: 'デフォルトホームページ',
        homePage: 'ホームページ',
        articlePage: '記事ページ',
        galleryPage: '画像ページ',
        videoPlayerPage: '動画ページ',
        kids: '子供カテゴリ',
        forGood: 'For Good',
        basePrice: '最低限金額。記入しない場合、デフォルトは０と足ます。例：0.43',
        fixedIndex1: '固定index。英語半角記号「,」 で分けてください。記入してない場合、デフォルトindexは1から。ex:2,3,',
        'river-half': 'river-half',
        river: 'river',
        rivertall: 'rivertall',
        'river-dbl': 'river-dbl',
        infopane: 'infopane',
        flexaside: 'flexaside',
        copyDone: 'コピー完了',
        checkStatus1: 'CPC Cap内容',
        checkStatus2: '日消化',
        checkStatus3: '日キャップ',
        checkStatus4: '総消化',
        checkStatus5: '総キャップ',
        checkStatus6: '特定のキャンペーンは休眠モード設定できません。',
        adStatus1: '広告Onにしました。',
        dormancy: '休眠モード　ON｜OFF',
        recallStatus: 'リコール可能ステータス',
        clickCapStatus: 'CPCキャップ状態',
        impCapStatus: 'CPMキャップ状態',
        under: '予算当たってない',
        full: '予算切れ',
        timeStatus: 'スケジュールステータス',
        platoonPeriod: 'スケジュール内',
        outside: 'スケジュール外',
        uniformlyStatus: '均等配信',
        uniformlyStatus1: '均等配信された。コントロールされています。',
        campaignStatus: 'キャンペーンステータス',
        allRejected: '一括NG',
        allThrough: '一括OK',
        'Approved+pendingPeview': '通過＋審査待ち',
        'Pass+Reject': '通過＋NG',
        currentMediaPass: '媒体審査通過',
        currentMediaPendingReview: '媒体審査待ち',
        currentMediaRejection: '媒体審査NG',
        totalAccountBalanceStatus: 'アカウント残額状態',
        accountStatus: 'アカウント状態',
        auditPassSsps: '審査通過した媒体',
        index: 'Index',
        newAdvertisingSpace: '広告枠追加',
        json: 'jsonフォーマットで入力可能',
        json1: '（複数入力可能）',
        json2: '正しいJSON数値で入力してください。',
        pidProfit1: '第${i + 1}行、Pid Profit 値は-1~1以内で必要。',
        beforeTheChange: '変更前',
        afterTheChange: '変更後',
        modifier: '編集者',
        modifiedTime: '編集時間',
        newURL: '新リンク',
        taboolaLP: 'Taboola 広告遷移先LP',
        pageId: 'Page Id',
        creationDate: '生成時間',
        product: '商材',
        onLp: 'LPページでのポップアップ設定しますか',
        onBanner: '右側のバナーを固定しますか',
        addNewProduct: '新規商材追加',
        editProuduct: '商材を編集',
        deleteProduct: '商材を削除',
        addNewDomain: 'ドメインを追加',
        editDomain: 'ドメインを編集',
        domainId: 'ドメインID',
        favicon: 'favicon画像',
        operations: '操作',
        tips: '横縦90*90推奨',
        tips1: '画像フォーマット：JPG / JPEG / PNG',
        tips2: '画像サイズは2MB以内',
        enterDomainName: 'ドメインを入力してください',
        enterValidDomainName: '有効なドメインを入力してください',
        pleaseUploadPictures: '画像をアップロードしてください',
        pleaseEnterFooter: 'footerをアップロードしてください',
        emailTemplate1: '注文で',
        emailTemplate2: 'と',
        emailTemplate3: '注文',
        domain: 'ドメイン名を選択してください',
        emailAddress: 'メールアドレスを入力してください',
        emailAddress1: '正しいメールアドレスを入力してください',
        enterPassword: 'メールアドのPWを入力してください',
        templateName: 'メールテンプレート名を設定してください',
        time: '時間帯を選択してください',
        storeLogo: '店ロゴをアップロードしてください',
        storeName: '店名を入力してください',
        emailTitle: 'メール件名を入力してください',
        emailContent: 'メール本文を入力してください',
        emailImage: 'メールポスターを入力してください',
        footer: 'メールFooterを入力してください',
        time1: '時間を選択してください',
        lastUpdateDate: '最後更新時間',
        selectAccountName: 'アカウント名を選択',
        selectProductName: '商材名を選択',
        selectCampaignName: 'キャンペーン名を選択',
        onlineDate: '反映日',
        order: '注文',
        adSpendBudget: '広告予算消化',
        adSpend: '広告消化',
        atv: 'ATV',
        enterLandingPage: 'LPページ',
        enterOfferPage: 'オファーページ',
        impCost: 'Imp 消化',
        merchandise: '商品',
        numberOfProduct: '商材数',
        numberOfOrders: '注文数',
        logisticFee: '物流費用',
        productPrice: '商材売価',
        startPurchase: '購入開始',
        avgCTR: '平均CTR',
        vimpCost: 'vimpCost',
        prodcutName: '商材名',
        totalAmount: '総金額',
        disputeAmount: '紛争金額',
        disputeRate: '紛争率',
        disputeRate1: '紛争率（紛争申込日基く）',
        disputeRate2: '紛争率（課金日基く）',
        paymentCount: '支払い回数',
        disputeCount: '紛争回数',
        disputeCount1: '重複紛争回数',
        disputeCount2: '詐欺紛争回数',
        disputeCount3: '一般紛争',
        disputeCount4: 'クレカ未処理紛争数',
        notRecCount: '商品未配達紛争回数',
        productUnacceptableCount: '商品返品回数',
        subscriptionCanceledDisputeCount: '登録解除数',
        unrecognizedDisputeCount: '識別不可回数',
        notReceivedDisputeAmount: '未収紛争金額',
        notAsDescribedDisputeAmount: '説明ずれ紛争金額',
        unauthorizedDisputeAmount: '無許可・権利侵害紛争金額',
        creditNotProcessedDisputeAmount: 'クレカ未処理紛争金額',
        duplicateTransactionDisputeAmount: '重複注文紛争金額',
        incorrectAmountDisputeAmount: '金額間違い紛争金額',
        paymentByOtherMeansDisputeAmount: 'その他支払い方法紛争金額',
        canceledRecurringBillingDisputeAmount: '定期注文解約紛争金額',
        problemWithRemittanceAmount: '送金金額問題',
        otherDisputeAmount: 'その他紛争金額',
        fraudAmount: '詐欺紛争金額',
        fraudRate: '詐欺紛争率',
        // 需要翻译
        comparison1d: '1d对比',
        comparison7d: '7d对比',
        comparisons: '周期对比'
    },
    serviceRecord: {
        tracker: 'Tracker',
        backgroundInformation: '背景情報',
        edit: '編集',
        accountName: 'アカウント名',
        accountHoldersEmail: '担当メールアドレス',
        accountTimeZone: 'タイムゾーン',
        // country: '国',
        country: '地域',
        industryCategory: '産業カテゴリー',
        accountManager: 'AM担当',
        businessManager: 'AE担当',
        coreTarget: 'コアターゲット',
        launchProduct: '製品を起動します',
        communicationChannel: '通信チャネル',
        otherInformation: 'その他の情報',
        serviceRecord: 'サービスレコード',
        serviceType: 'サービスの種類',
        deliveryStatus: '配信状況',
        meetingCommunication: '会議／コミュニケーション',
        changeOfManager: '管理者の変更',
        other: 'その他',
        timeline: 'タイムライン',
        operationRecordDescription: '操作記録の説明',
        add: '追加',
        config: 'アカウントディメンションの設定は、新しいキャンペーンにのみ有効になります。古いキャンペーンのホワイトリストは変わりません。',
        whitelist: 'ホワイトリスト',
        individualChoice: '個人の選択',
        supportbatch: 'サポートバッチ書き込み、分離するために使ってください;',
        percentageOfMedia: '媒体の割合',
        pleaseenter: '1-99を入力してください、その数はですメディア内のキャンペーンクリックの割合（単位：％）',
        clientid: 'クライアントID:',
        clientSecret: 'クライアントシークリー:',
        accountid: 'アカウントID:',
        added1: '追加:',
        profitMarginOperation: '利益マージンオペレーション',
        profitMargin: '利益率',
        replenishmentday: '補充日レベルの損失上限',
        topUptotallosscap: 'トップアップトータルロスキャップ',
        trainingOperations: 'トレーニングオペレーションズ',
        speedUpTrainingMultiple: 'トレーニングを倍速させる',
        retrainingthreshold: '再トレーニングのしきい値',
        cVROptimization: 'CVR最適化',
        switch: 'スイッチ'
    },
    tracker: {
        trackerCategory: 'トラッカーカテゴリー',
        priority: '優先度',
        status: 'スタータス',
        industryCategory: '産業カテゴリー',
        onlyShow: 'あなたが責任を負うトラッカーだけを表示します',
        include: '歴史的責任を含みます',
        afterchecking: '確認後、マネージャーを検索できない場合は、アカウントを検索できません。',
        newItem: '新商品',
        ordinal: '序数',
        adAccount: '広告アカウント',
        updateTime: '更新時刻',
        accountManager: 'アカウントマネージャー',
        currentManager: '現在のマネージャー',
        creationTime: '作成時刻',
        operate: '操作',
        update: 'アップデート',
        consumptionRiseAnalysis: '消費上昇分析',
        consumptionFallAnalysis: '消費下降分析',
        lowBudget: '低予算消費',
        bugFeedback: 'バグ報告',
        functionOptimization: '機能最適化提案',
        material: '材料設計要件',
        strategicSupport: '戦略的サポート',
        technicalSupport: '技術サポート',
        conversionRelated: '変換関連',
        other: 'その他',
        request: 'リクエスト',
        callback: 'コールバック数',
        imp: 'Imp',
        vimp: 'Vimp',
        click: 'Click',
        revenue: '収益',
        selectPublisher: '出版社を選択してください',
        selectPage: 'ページを選ぶ',
        selectFormat: 'フォーマットを選択してください',
        selectAccount: 'アカウントを選択してください',
        selectCampaign: 'キャンペーンを選択してください',
        search: '検索',
        reset: 'リセット',
        byPublisher: '出版社ごとに',
        byPage: 'ページごとに',
        byFormat: 'フォーマットごとに',
        byAccount: 'アカウントごとに',
        byCampaign: 'キャンペーンごとに',
        more: 'もっと',
        columns: '列',
        publisherName: '出版社の名前',
        page: 'ページ',
        format: 'フォーマット',
        accountName: 'アカウント名',
        campaignName: 'キャンペーン名',
        callbackRate: 'コールバック率',
        winRate: '勝率',
        viewableRate: 'Viewable Rate',
        iCTR: 'iCTR',
        vCTR: 'vCTR',
        callbackRateROC: 'Callback Rate ROC',
        winRateROC: 'Win Rate ROC',
        viewableRateROC: 'Viewable Rate ROC',
        vCTRROC: 'vCTR ROC',
        iCTRROC: 'iCTR ROC',
        cost: '料金',
        rOI: 'ROI',
        icpm: 'iCPM',
        cRpm: 'cRPM',
        iRpm: 'iRPM',
        revenueROC: 'Revenue ROC',
        revenueDiff: '収益の差異',
        requestDiff: 'リクエストの差異',
        callbackDiff: 'コールバックの差異',
        impDiff: 'impの差異',
        vimpDiff: 'vimpの差異',
        clickDiff: 'Clickの差異',
        profit: '利益',
        profitDiff: '利益の差異',
        accountID: 'アカウントID',
        campaignID: 'キャンペーンID',
        accountNameData: 'accountNameData',
        conversion: 'CV',
        conversionDiff: 'CVの差異',
        conversionRate: 'CV率',
        conversionRateROC: 'CV ROC',
        costPerConversion: 'CVあたりのコスト',
        costPerConversionDiff: 'CVあたりのコスト差異',
        viewContent: 'コンテンツを表示します',
        appInstall: 'インストール',
        completeRegistration: '登録完了',
        addToCar: 'カートpopIn追加',
        addPaymentInfo: '支払い情報を登録',
        startCheckout: 'チェックアウトを開始します',
        purchase: '購入完了',
        addToWishlist: '欲しい物リストに追加する',
        lead: 'リード',
        submissionDate: 'クリエティブ提出日',
        reviewDate: 'レビュー日',
        startDate: '開始日',
        endDate: '終了日',
        geoEdgeAlert: 'GeoEdge Alert'
    },
    monthlyBill: {
        downloadTemplate: 'テンプレートダンロード',
        initData: '初期データ',
        currentData: '現在のデータ',
        operationLog: '操作記録',
        placeholderTip: 'Company Name/user名検索',
        uploadErrorTip: 'アップロード失敗しました。修正して再度アップロードしてください。',
        uploadTip: '編集可能部分のみアップロード対応可能',
        uploadTypeTip: 'csvファイルのみ対応可能',
        selectFlie: 'ファイルを選択',
        uploadResult: 'アップロード結果',
        editTip: ' は ',
        editFormTip: ' を ',
        editAfterTip: ' に変更しました ',
        editToTip: ' から ',
        remark: '備考',
        upload: '導入',
        line: '第'
    },
    // AI-CreativeGo
    aiCreativeGo: {
        Japan: '日本',
        twd: '台湾',
        hk: '香港',
        my: 'マレーシア',
        Japanese: '日本語',
        chinese: '中国語（簡体）',
        zhHant: '中国語（繁体）',
        // title
        hearderTitle: 'クリエティブタイトル自動生成',
        contentTitle: 'AI生成タイトル',
        // form-labels
        landingPage: '記事LP',
        location: '地域',
        language: '言語',
        sellingPoint: 'セールスポイント',
        titleSample: 'CRタイトル例',
        audience: 'オーディエンス',
        gender: '性別',
        age: '年齢',
        productName: '商材名',
        campaignCategory: '商材カテゴリ',
        // btns
        createBtn: 'CRタイトルを作成',
        copyAllBtn: '全てのタイトルをコピー',
        // tips
        landingPageNoneTip: '記事LP URLを登録してください。',
        landingPageRuleTip: '記事LP URLには記号の「<」と「> 」は登録いただけません。',
        languageNoneTip: '言語を選択してください。',
        sellingPointTip: '複数のセールスポイントを同時に登録される場合は、セールスポイントをコンマ「,」（※小文字）で区切ってください。',
        titleSampleTip: 'popIn Creative Makerの学習効率を向上させるため、三つ以上のCRタイトルを記入してください。',
        copied: 'コピー完了',
        requestErrorTip: 'アクセスが混み合っている可能性があります。大変申し訳ございませんが、しばらく時間をおいてから再度お試しください。',
        all: 'ALL',
        // 目标受众-option:
        officeWorkers: '職員',
        Homemakers: '主婦',
        elderlyPeople: 'お年寄り',
        sportsEnthusiasts: 'スポーツ愛好者',
        investors: '投資家',
        entrepreneurs: '起業家',
        artistsAndDesigners: '芸術家・デザイナー',
        technologyEnthusiasts: 'テクノロジー愛好者',
        travelEnthusiasts: '旅行愛好者',
        // gender-option:
        male: '男性',
        female: '女性',
        // age-option:
        ageRange1: '18歳以下',
        ageRange2: '18~24歳',
        ageRange3: '25~44歳',
        ageRange4: '45~64歳',
        ageRange5: '65~85歳',
        ageRange6: '85歳以上',
        // campaignCategory-option
        // aiCreativeGo下所对应的日文较constants的更为准确
        autoselectronics: '自動車・ガジェット',
        business: 'ビジネス',
        beautyFashion: '美容・ファッション',
        entertainment: '芸能・エンターテイメント',
        economyMoney: 'ビジネス(BtoBサービス)',
        election: '選挙',
        education: '教育・育児',
        finance: 'パーソナルファイナンス',
        foodDrink: '飲食料品・グルメ',
        homepage: 'ホームページ',
        health: '健康食品',
        homeLifestyle: '住まい・ライフスタイル',
        loveMarriage: '恋愛・結婚',
        music: '音楽',
        movies: '映画',
        news: 'ニュース',
        politicsSocial: '政治・社会',
        sports: 'スポーツ',
        travel: '旅行',
        TV: 'TV・ドラマ・アニメ',
        video: '動画',
        weather: '天気'
    },
    ...state,
    ...country
};