/**
 * @file
 * @author  fangsimin
 * @date    2020-02-09 21:29:35
 * @last Modified by    fangsimin@baidu.com
 * @last Modified time  2021-06-25 15:32:10
 */
import state from './state-ko';
import country from './country-ko';
export default {
    permissions: {
        legalReTip: ' 회사 등록된 국가/지역区',
        legalPerson: '법인이 위치한 곳',
        companyLegalPerson: '회사 법인 소재지',
        roleList: '역할 리스트',
        roleName: '역할 이름',
        rolePrivileges: '역할 권한',
        announcementEmail: '공지 이메일'
    },
    newDashboradBreadCrumbs: {
        account: '집',
        accounts: '계정',
        companys: '회사',
        campaign: '캠페인',
        campaigns: '캠페인',
        ad: '광고',
        billCampaign: '活动'
    },
    menuData: {
        oppoSDK: 'oem 报表',
        oppoSDKTitle: 'oem 报表',
        popInCreativeMakerTitle: 'popIn Creative Maker',
        receivablesTitle: 'Discovery 미수금',
        receivables: 'Discovery 미수금',
        monthlyBill: '외부용 계산서',
        monthlyBillTitle: '외부용 계산서',
        msgCenter: '알림을 받다',
        notification: '알림 센터',
        notificationTitle: '알림 센터',
        monthBillTitle: '청구서',
        amConfigTitle: '설정',
        amConfig: '설정',
        readCreateTitle: 'READ 코드 가져오기',
        discoveryReadCreateTitle: 'READ 코드 가져오기',
        minBid: '광고효과의 최적화를 위해 목표 입찰은 {minBid} 이상 설정해주세요.',
        brandReason: '브랜드명이 프리미엄 매체에서 송출하기에 적절하지 않습니다. 자세한 내용은 계정 관리자에게 문의하세요.',
        imgReason: '부적절한 이미지 또는 타이틀으로 인해 이 캠페인이 NG처리 되었습니다.',
        campaignReason: '부적절한 랜딩 페이지로 인해 니 캠페인이 NG처리 되었습니다.',
        textOnImageReason: '광고 소재가 미디어 정책을 위반했습니다. 자세한 정보를 얻으려면 담당 계정 관리자에게 연락하십시오.',
        msnRejectionReason: '해당 광고의 크리에이티브는 일부 매체의 심사 규칙에 부합하지 않아 심사 거부되었습니다',
        sexualRelatedReason: '해당 광고의 크리에이티브는 성적 관련 위반 문제로 인해 심사 거부되었습니다',
        tobaccoProductsReason: '해당 광고의 크리에이티브는 미성년자 관련 위반 문제로 인해 심사 거부되었습니다',
        textOnImageMediaType: 'MSN 심사 태그 필터링',
        tagReason: '광고 카테고리에서 제한되어 해당 제품은 프리미엄 매체에세 NG처리 되었습니다. (카테고리 - {tagName})',
        uploadWaring: 'The platform service will go under regular maintenance for 6 hours in the near future, ',
        uploadWaringMore: 'more.',
        uploadText: 'The platform service will go under regular maintenance for 6 hours, starting between 10:00 pm on January 15, 2022 to 4:00 am on January 16, 2022 in the EST time zone. We recommend planning your campaign management accordingly.<br><br>Converted Time Zones:<br><br>UTC+8: 11:00 am on January 16, 2022  to 5:00 pm on January 16, 2022<br>UTC+0:  3:00 am on January 16, 2022 to 9:00 am on January 16, 2022',
        multiDimension: '멀티 리포트',
        multiDimensionTitle: '멀티 리포트',
        wrongList: '일괄 추가한 {wrongList} 도메인은 올바르지 않아 사용할 수 없습니다.',
        downList: '문서를 다운로드하기위해서는 여기를 클릭해주세요.',
        domainSuccess: '업로드된 도메인 {correctLenght}',
        searchSimilarBtnTitle: '일괄심사',
        searchSimilar: '일괄심사',
        stripeActionTitle: 'Stripe',
        PaymentManagement: '결제관리',
        PaymentManagementTitle: '결제관리',
        ROIAnalysisTitle: 'ROI분석',
        campaignTitle: '신규캠페인',
        dashboardTitle: '대쉬보드',
        new_dashboardTitle: '새대시보드',
        auditTitle: '광고리뷰',
        ecommerceToolTitle: '전자상거래 툴',
        lPManagementTitle: 'LP관리',
        oPManagementTitle: 'OP관리',
        productManagementTitle: '상품관리',
        domainManagementTitle: '도메인관리',
        testTitle: '테스트',
        visualTestTitle: '가시화 테스트',
        visualTestBannerTitle: '배너 가시화 테스트',
        generateLocalPageWrapper: '랜딩 페이지 생성',
        generateLocalPageWrapperTitle: '랜딩 페이지 생성',
        checkStatusTitle: '광고 콜백',
        administrator: '관리자',
        financialTitle: '재무관리',
        monthlyBillRelease: 'Monthly Bill Release',
        monthlyBillSave: 'Monthly Bill Save',
        creditLineTitle: '신용한도',
        roiMgrTitle: 'ROI리포트',
        incomeTitle: '미수금',
        payTitle: '미지급금',
        offlineTitle: '오프라인',
        applyTitle: '타이틀을 적용',
        agencyManagementTitle: '대리관리',
        permissionTitle: '권한관리',
        accountTitle: '계정관리',
        userTitle: '유저권한',
        bdOpenAccountTitle: 'BD계정추가',
        roleTitle: '역할 관리',
        balance: '총계',
        adListTitle: '광고',
        dataReportTitle: '데이터리포트',
        amTitle: '운용대쉬보드',
        amDashboardTitle: '분석',
        icafeTitle: '요구사항 추적',
        sampleTitle: '샘플',
        adTooltipText: '최소 1개이상의 캠페인을 선택해주세요',
        pixel: '픽셀',
        pixelTitle: '픽셀',
        pixelConversion: 'CV',
        conversionListTitle: 'Conversion & Read',
        readListTitle: 'Read',
        conversionCreateTitle: '전환 만들기',
        pixelCreate: '전환 만들기',
        account: '계정',
        accounts: '계정',
        rechargeTitle: '계정 충전',
        payActionTitle: '충전',
        stripeAction: 'stripe',
        showbilling: '청구를표시',
        helpCenterTitle: '도움말 센터',
        gettingStartTitle: '시작하기',
        createAndManageCampaignTitle: '캠페인관리',
        priceAndBillTitle: '가격 & 청구',
        qaCenterTitle: 'FAQ',
        faqTitle: 'FAQ',
        adPolicyTitle: '광고 정책',
        reportAndOptimizingTitle: '리포트&최적화',
        diagnosisTitle: '광고진단',
        bidAnalysisTitle: '입찰분석',
        pageBidTitle: 'AD BID구성',
        monthlyReportTitle: '월간 운영 리포트',
        customerManagementTitle: '광고주관리',
        wraningWrod: '주의: 입찰 가격이 정상 범위를 초과했습니다.',
        cpcWraningWrod: 'CPC를{cpc}에서{cpcData}으로 조정했으나 조정폭이 정상 수준보다 높습니다. 계속하시겠습니까?',
        dayWraningWrod: '주의: 일예산을{day}에서{dayData}으로 조정했습니다. 조정폭이 정상 수준을 초과했습니다. 계속하시겠습니까?',
        conversions: 'Conversions',
        conversionsTitle: 'Discovery Conversion',
        siteTip: '캠페인의 일부 도메인 CPC가 최저가보다 낮아 자동으로 {siteCpc}로 조정됩니다',
        cpcTips: '주의하여 주십시오. 이부분은 계좌 거래 화폐종류',
        cpcTips2: '입니다.'
    },
    login: {
        emailLogin: '메일주소로 로그인',
        thirdPartyLogin: '소셜 로그인',
        forgetPwd: '패스워드가 기억나지않음',
        haveNoAccount: '계정이 있습니까?',
        register: '가입',
        email: '이메일',
        pwd: '패스워드',
        loginText: '로그인',
        pwdBefore: '이전패스워드',
        pwdAfter: '새패스워드',
        confirmPwd: '패스워드확인',
        account: '계정명',
        loginEmail: '로그인 이메일'
    },
    button: {
        subimtAudit: '심사 제출',
        subimtAuditTitle: '(심사시간 1~3 영업일 소요)',
        backCheck: 'Check',
        confrimReject: 'Confrim',
        search: 'Search',
        addCreative: '새소재 추가',
        trash: '삭제',
        reachOfLandingPage: '유입률',
        checkoutConversion: '결제CVR',
        budgetSpend: '예산초과율',
        offerConversion: 'Offer CVR',
        purchaseConversion: '구매CVR',
        apiToken: 'Api Token을 복사하다',
        filterCols: '설정',
        export: '내보내기',
        reset: '재설정',
        apply: '적용',
        confirm: '확인',
        cancel: '취소',
        previous: '돌아가기',
        next: '다음',
        submit: '제출',
        audit: '신청',
        revise: '캠페인수정',
        total: '합계',
        edit: '편집',
        operate: '조작',
        detail: '상세',
        authorize: '승인',
        adminAuthorize: '관리자 역할 권한 부여',
        advertiseAuthorize: '광고주 역할 권한만',
        ignore: '무시',
        add: '신규',
        query: '검색',
        recreate: '캠페인재작성',
        createConversion: 'CV목표작성',
        createCampaign: '새 캠페인',
        updateConversion: 'CV목표갱신',
        archived: '삭제',
        editMore: '상세편집하기',
        delete: '삭제',
        pass: 'OK',
        copy: '복사',
        import: '가져오기',
        create: '작성',
        history: '이전기록',
        pageAudit: '심사',
        preview: '미리보기',
        rename: '이름수정',
        sendReport: '리포트송신',
        save: '저장',
        notSave: '저장 안 함',
        openEditor: '편집툴열기',
        deleteTitle: '삭제할지 여부',
        deletePage: '제시',
        innerStatus: '휴면 상태'
    },
    dashboard: {
        totalBalance: 'Current Balance',
        balanceTip1: '일부 계정의 총 사용 가능 잔액이 거의 소진되었습니다. 광고가 정상적으로 배치되는 데 영향을 줄 수 있으므로 고객 담당자에게 연락하여 입금을 하시기 바랍니다.',
        balanceTip2: '일부 계정의 총 사용 가능 잔액이 이미 소진 완료 되었습니다. 광고가 정상적으로 배치되는 데 영향을 줄 수 있으므로 고객 담당자에게 연락하여 입금을 하시기 바랍니다.',
        balanceTip3: '일부 계정의 총 사용 가능 잔액이 거의 소진되었거나 이미 소진되었습니다. 광고가 정상적으로 배치되는 데 영향을 줄 수 있으므로 고객 담당자에게 연락하여 입금을 하시기 바랍니다.',
        totalBalanceTip1: '계정의 총 사용 가능 잔액이 곧 소진될 예정이며, 약 <span style="color: #e92754;">{num}</span>일 동안 사용 가능합니다. 광고가 정상적으로 배치되는 데 영향을 줄 수 있으므로 고객 담당자에게 연락하여 입금을 하시기 바랍니다.',
        totalBalanceTip2: '계정의 총 사용 가능 잔액이 이미 소진되었습니다. 광고가 정상적으로 배치되는 데 영향을 줄 수 있으므로 고객 담당자에게 연락하여 입금을 하시기 바랍니다.',
        balanceStatus: '잔액 상태',
        sufficientBalance: '잔액이 충분합니다.',
        lowBalance: '잔액이 낮습니다.',
        tightBalance: '잔액이 긴박합니다.',
        insufficientBalance: '잔액이 부족합니다.',
        totalBalanceTip: '총 사용 가능 한도 = 누적 충전 + 신용 한도 + coupon - 누적 비용입니다. 총 사용 가능 한도는 계정의 실제 자산이 아닙니다. 실제 자산은 청구서를 기준으로합니다.',
        searchDetail: '자세히 보기',
        collapseDetail: '상세 정보 숨기기',
        smbLearningCompletedTip: '스마트형 입찰 학습에 성공되었습니다. cpc 수정은 지원되지 않습니다',
        maxcvCpcTip: 'MaxCV 모드, cpc 수정은 지원되지 않습니다.',
        siteBlockAccountContentTip: '일단 특정 계정 레벨에서 도메인 차단 조치를 취하게 되면, 해당 계정 내의 광고 레벨에서 domain 차단을 해제할 수 없게 됨을 유의하십시오.',
        siteBlockCampaignContentTip: '특정 광고 캠페인 레벨에서 도메인 차단을 하게 되면, 계정 레벨에서 domain차단을 해제할 수 없게 됩니다. domain 차단을 해제하려면 해당 광고 캠페인 레벨에서만 가능합니다.',
        viewSelected: '선택 사항 확인',
        adReview: '{num}개 광고를 선택했습니다',
        assetCampaign: '{num}개 광고 캠페인을 선택했습니다',
        assetReview: '{num}개 광고 계획을 선택했습니다',
        accountReview: '{num}개 계정을 선택했습니다',
        campaignReview: '{num}개 광고 계획을 선택했습니다',
        specifyCampaignTip1: 'Campaign id가 올바르지 않습니다',
        specifyCampaignTip2: '캠페인이 새 캠페인로 활성화되었습니다. 이벤트를 다시 선택하십시오',
        specifyCampaignTip3: '해당 캠페인은  지난 6일간 CV<3이 원클릭 상속 기준에 충족하지 안아 이벤트를 다시 선택해 주십시오',
        specifyCampaignTip4: '최근 14일 동안 cv≥3의 아이디어가 이 캠페인에 존재하지 않습니다. 이벤트를 다시 선택하십시오',
        specifyCampaignTip5: 'Country, platform, OS, Browser 설정이 일치하지 않아 학습효과가 좋지 않을 수 있습니다. 계속 제출하시겠습니까?',
        assignMatch: '수동으로 지정',
        inheritCampaignId: 'キャンペーンID',
        inherit: '원클릭 상속자료',
        matchType: '매칭 방법',
        autoMatch: '자동 매칭',
        exceedLimitTip: '계정위도를 생성가는할 원클릭 상속 캠페인 한도가 초과됨',
        notInheritTip: '는 원클릭 상속의 전제 조건을 충족하지 못합니다. 삭제 후 남은 캠페인을 계속 제출해야 합니까?',
        notInheritTip2: '광고가 원클릭 상속의  조건을 충족하지 않음 ',
        matchDegree: '매칭',
        excellent: '수',
        fine: '우',
        noMatch: '원클릭 상속자료가 일치하지 않음',
        matching: '매칭중',
        inheritEnd: '상속종료',
        dateHourTip: '1회 최대 14일 범위의 시간 단위 데이터입니다.',
        ocpcphase: '해당 캠페인은 스마트입찰 2단계로 넘어갔습니다',
        ocpcNewPhase: '해당 캠페인에 Smart Bid기능 적용되었습니다',
        dateRemind: 'CV 데이터 반영은 최대 40분 소요됩니다. 최근 업데이트:{month}/{data}/{year} {hour}:{minute} {meridian} <span class="date-remind-color">({timezone})</span>',
        downloadTypeDay: '일자별 리포트',
        downloadTypeSummary: '기간 지정 리포트',
        accountFilter: '계정 필터',
        customerSource: '고객 소스',
        vcpm: 'vCPM',
        country: '나라 필터',
        readChart: 'READ 分析',
        readChartTitle: 'READ 报表',
        total: '총',
        account: '계정',
        allAccounts: '모든 계정',
        colManagement: '관리',
        colCampaigns: '캠페인',
        colAds: '광고',
        colCost: '비용',
        colTrafficAcquisition: '트래픽 획득',
        colAudienceMetrics: '잠재고객 측정항목',
        mcv: 'MCV',
        cv: 'CV',
        cvr: 'CVR',
        mcvr: 'MCVR',
        mcpa: 'MCPA',
        adSize: '사이즈',
        colTargeting: '타겟팅',
        dimensionFilter: '차원 필터',
        conversiontype: '전환 유형',
        newConversiontype: 'Discovery Conversion (MCV)',
        mediagoCv: 'MediaGo Conversion (Lead&purchase)',
        currencyType: '멀티 통화 전환',
        colPerformance: '퍼포먼스',
        payPal: 'PayPal',
        StripeVisa: 'Stripe-Visa',
        StripeMastercard: 'Stripe-Mastercard',
        productName: '상품명',
        otherPage: '도메인의 해석을 기다리고 있습니다.',
        blocked: '계정단위로 블락',
        adListColSize: '광고사이즈 체크박스',
        auditPublisher: '상세 매체 분류 검토',
        domainSspColumn: '도메인 탭 SSP 선택',
        dashboardDomainBlock: '도메인블락',
        uniformlyFlag: '송출 속도',
        campaign: '캠페인',
        campaignName: '캠페인명',
        status: '상태',
        adStatus: '광고 상태',
        campaignStatus: '캠페인 상태',
        spend: '소진',
        impression: 'View IMP',
        click: '클릭',
        clickRate: 'CTR',
        startTime: '시작시간',
        endTime: '종료시간',
        searchPlaceholder: '캠페인/계정을 검색',
        filterBtn: '필터',
        refreshBtn: '갱신',
        viewSelectedBtn: '선택한 항목 보기',
        editSelectedBtn: '선택한 항목 편집',
        review: '심사 내역',
        discarded: '이 캠페인은 저장되었습니다.',
        reviewResult: '심사 결과',
        sppNotWork: '캠페인 심사 중 오류가 발생하여 송출불가 상태로 되었습니다. 담당자한테 연락해주시기 바랍니다.',
        reviewAssetWaiting: '캠페인 소재는 검수 대기중이며 3영업일 이내 검수 완료될 예정입니다.<br/> 조금만 더 기다려주십시오.',
        reviewAssetNotPass: '해당 소재는 정책위반으로 송출불가 상태로 되었습니다, <br/>우측버튼을 클릭하여 자세한 내용을 확인하시기 바랍니다&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;',
        reviewAssetReason: 'The creative isn\'t able to run due to policy restrictions',
        reviewAssetReason1: 'The creative can be run, but can not be shown in all situations due to policy restrictions',
        reviewAssetNotPass1: '해당 소재는 송출 가능하지만 정책위반으로 특정 매체에서 제한되었습니다. 우측버튼을 클릭하여 자세한 내용을 확인하시기 바랍니다.',
        discardedAsset: 'This asset has been archived',
        newReviewNotPass1: '캠페인의 일부 소재는 송출 가능하지만 정책위반으로 특정 매체에서 제한되었습니다. <br/>우측버튼을 클릭하여 자세한 내용을 확인하시기 바랍니다&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;',
        newReviewAssetWaiting: '해당 소재는 송출 가능하지만 정책위반으로 특정 매체에서 제한되었습니다. <br/>우측버튼을 클릭하여 자세한 내용을 확인하시기 바랍니다.',
        newReviewNotPass: '캠페인의 일부 소재는 정책위반으로 송출불가 상태로 되었습니다. <br/>우측버튼을 클릭하여 자세한 내용을 확인하시기 바랍니다&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;',
        reviewHasRemain: '심사중상태의 소재수：{wait_audit_asset_num}/{asset_num} <br/>',
        reviewWaiting1: '캠페인의 일부 소재는 검수 대기중이며 3영업일 이내 검수 완료될 예정입니다.<br/> 이미 승인 완료된 소재는 정상적으로 송출됩니다.<br/> 조금만 더 기다려주십시오.',
        reviewWaiting: '이 광고는 심사중입니다. 영업일 3일 이내로 심사될 예정입니다.',
        reviewNotPass: '이 광고는 NG처리되었습니다. 담당자에게 연락주세요',
        reviewNotPass1: '이 캠페인에는 승인되지 않은 광고 소재가 있습니다.자세한 내용은 오른쪽의 XX 버튼을 클릭하세요.',
        // reviewNotPass: '이 캠페인은 일부 광고소재가 NG처리되었습니다. 오른쪽 버튼을 눌러 상세를 확인해 주세요',
        auditDetails: '상세',
        campaignFilter: '캠페인 필터',
        adSetFilter: '광고셋팅 필터',
        adFilter: '광고 필터',
        datetimeRange: '시작&종료',
        and: 'and',
        selectDate: '날짜를 선택',
        on: 'ON',
        off: 'OFF',
        CPC: 'CPC',
        totalBudget: '총예산',
        roas: 'ROAS',
        dailyBudget: '일예산',
        campaignDetails: '캠페인 상세',
        campaignCopy: '캠페인 복사',
        adSetsDetails: '광고 세트 상세',
        adsDetails: '광고 상세',
        campaignEdit: '캠페인 수정',
        adCopy: '광고를 복사합니다',
        adEdit: '광고 편집',
        creative: '소재',
        utcStandard: 'UTC 표준시',
        objective: '목적',
        accountsName: '계정명',
        adCreator: 'AD작성자',
        // audit
        assetTitle: '타이틀',
        asset: '이미지',
        assetName: '소재명',
        adInformation: 'AD Information',
        accountName: '계정명',
        adName: 'AD Name',
        submitTime: '송출시간',
        start: '시작시간',
        userId: 'User ID',
        reviewItems: '심사',
        pass: '경과',
        fail: '일부의 광고가 NG 입니다.',
        invalidReason: '이유',
        comments: '심사원 코멘트',
        reviewer: '심사 담당',
        reviewTime: '심사 시간',
        colCfgTitle: '설정 열',
        colDimension: '차원',
        colAdPerformance: '광고 퍼포먼스',
        colContentPerformance: '컨텐츠 퍼포먼스',
        campaignId: '캠페인 ID',
        accountId: '계정 ID',
        colStatus: '상태',
        colStart: '시작',
        colEnd: '종료',
        colSubmissionTime: '송출시간',
        colSpend: '소진',
        // colBalance: '캠페인밸런스',
        colBalance: '나머지 총예산',
        colTotalBudget: '총예산',
        colDailyBudget: '일예산',
        colDetail: '상세',
        colDelivery: 'Delivery',
        colOther: '기타',
        colCpc: 'CPC',
        colAvgCpc: '평균CPC',
        colVCpm: 'vCPM',
        colImp: 'View IMP',
        colClick: '클릭',
        colCtr: 'CTR',
        colConversion: 'CV',
        newColConversion: 'CV',
        cvViewContent: '컨텐츠 확인',
        cvAppInstall: '설치',
        cvCompleteRegistration: '등록 완료',
        cvAddToCar: '카트에 추가',
        cvAddPaymentInfo: '결제정보를 추가해 주세요',
        cvSearch: '검색',
        cvStartCheckout: '체크아웃을 시작합니다',
        cvPurchase: '구입',
        cvAddToWishlist: '위시리스트에 추가',
        cvLead: '잠재적 사용자',
        cvOther: '기타',
        colAccount: '계정정보',
        conversions: 'CV',
        conversion: 'CV',
        conversionRate: 'CVR',
        colConversionRate: 'CVR',
        costPerConversion: 'CPA',
        colConversionPrice: 'CPA',

        newConversion: 'CV',
        newConversions: 'CV',
        newConversionRate: 'CVR',
        newColConversionRate: 'CVR',
        newColAgency: '대행사',
        newColAgencyInput: '대행사명으로 검색',
        newColAgencyName: '대행사명',
        newColAgencyId: '대행사ID',
        newCostPerConversion: 'CPA',
        newColConversionPrice: 'CPA',
        campaignMultiEdits: {
            selectIdsNum: '편집하고있는캠체인의 수 1 | 편집하고있는캠체인의 수 {num}',
            selectAssetIdsNum: '현재 1 소재 편집중입니다 | 현재 {num}소재 편집중입니다',
            dialogTitle: '대량편집',
            status: '상태',
            bid: '입찰',
            perCampaign: '캠페인별',
            dailySpendCap: '일예산',
            lifetimeSpendCap: '총예산',
            bulkEditSuccess: '정상을 갱신되었습니다.',
            assetBulkEditSuccess: '정상을 갱신되었습니다.',
            bulkArchivedSuccess: '정상을 갱신되었습니다.',
            assetBulkArchivedSuccess: '정상을 업데이트 되었습니다'
        },
        accountList: {
            searchPlaceholder: '타이틀/광고ID입력'
        },
        adList: {
            head: '광고리스트',
            adNum: '추가완료',
            id: '광고ID',
            status: '개제상태',
            fileName: '파일명',
            name: '소재명',
            image: '소재',
            size: '사이즈',
            title: '타이틀',
            brandName: '브랜드명',
            spend: '소진',
            impression: 'View IMP',
            ctr: 'CTR',
            url: 'URL',
            searchPlaceholder: '타이틀/광고ID입력/URL',
            click: '클릭',
            webUrl: 'URL',
            cpc: 'CPC',
            campaignId: '캠페인ID',
            campaignName: '캠페인명',
            assetId: '소재 ID',
            tableFooterMsg: '현재 {n} 개의 광고가 만들어지고 있다',
            searchAssetId: '타이틀/소재 ID/URL',
            failedMsg: '이미지 전환실패'
        },
        domain: {
            domainName: 'Site',
            spend: '소진',
            impression: 'View IMP',
            click: '클릭',
            ctr: 'CTR',
            vcpm: 'vCPM',
            acpc: '평균 CPC',
            suggestedCpc: '권장 CPC',
            cpc: 'CPC',
            searchPlaceholder: '도메인 검색',
            blockBtn: '블락',
            blockDisableBtn: '블락 금지',
            resumeBtn: '재개',
            resumeDisableBtn: '재개 금지',
            ssp: 'SSP',
            domainNameRemark: '도메인 수치갱신은 2시간 소요됩니다.'
        },
        domainTitle: 'Sites',
        // data report column setting
        colBreakDownBy: '내역',
        colCampaignAds: '캠페인/광고',
        suffixBreakDownBy: '최대 2개 선택가능합니다.',
        colData: '데이터',
        colEarning: '매출',
        yesterday: '어제',
        today: '오늘',
        last7days: '일주일간',
        last30days: '한달간',
        archivedMsg: '이 캠페인을 저장하시겠습니까? (저장된 캠페인은 편집할 수 없지만 데이터는 저장됩니다.)',
        archivedMsg2: '이 캠페인을 저장하시겠습니까? (저장된 캠페인은 편집할 수 없지만 데이터는 저장됩니다.)',
        archivedAssetMsg: '이 캠페인을 저장하시겠습니까? (저장된 캠페인은 편집할 수 없지만 데이터는 저장됩니다.)',
        filterList: '캠페인리스트설정',
        adFilterList: '广告列表设置',
        hideArchivedCmps: '저장된 캠페인 숨기기',
        hideArchivedAds: '저장된 캠페인 숨기기',
        onOffBoth: '모든캠페인을 표시하기',
        adOnOffBoth: '모든캠페인을 표시하기',
        showAllCampaigns: '모든 캠페인 표시(저장된 캠페인 포함)',
        onlyArchivedCmps: '저장된 캠페인만 표시하기',
        showAllAds: '모든 캠페인 표시(저장된 캠페인 포함)',
        onlyArchivedAds: '저장된 캠페인만 표시하기',
        selectedCampaigns: '{num}가선택되었습니다.',
        adInfo: '캠페인수{num}',
        domainCampaignTip: '캠페인수{num}',
        domainAccountTipAll: '전 계정',
        domainAccountTip: '계정수{num}',
        mediaBlackWhiteListTitle: '매체 계정/Site화이트리스트',
        mediaType: '광고주',
        selectedZipCode: '우편번호 : {num}',
        campaignSetOcpc: 'OCPC',
        campaignSetEcpc: 'ECPC',
        bidByDomain: 'bid by domainCPC',
        suggested: 'Site 권장 CPC',
        readChartObj: {
            avgReadTime: '평균 정독시간',
            avgReadRate: '평균 정독률',
            totalReadTime: '총 정독시간',
            readPart: 'READ분포',
            readAnalysis: 'READ분석',
            pv: 'PV수',
            readRate: '정독률',
            readUserPart: 'READ 유저층',
            tiaoReadPart: '훑어보는 층',
            shuReadPart: '열독층',
            yueReadPart: '열람층',
            popInAvgReadRate: 'popIn 유입시 평균 정독률',
            inAvgReadRate: '보통 유입시 평균 정독률',
            contentAnalysis: '컨텍스트 분석',
            clickNumber: '클릭수',
            imp: '노출수',
            ctr: 'CTR',
            outMediaAnalysis: '외부 매체에서의 콘텐츠 소비 스타일을 분석',
            normalReadContent: '보통 보고있는 기사 콘텐츠',
            normalReadTimePeriod: '보통 기사를 보는 시간대',
            timePeriod: '시간대',
            category: '카테고리'
        }
    },
    create: {
        newCountryLang: {
            Japanese: '일본어',
            English: '영어',
            Chinese: '중국어',
            Thai: '태국어',
            Malay: '말레이어',
            Indonesian: '인도네시아어',
            Korean: '한국어',
            allLanguageSplit: '모든 언어를 구체적인 언어로 분류',
            LanguageErrTip: '언어 부분 비워둘 수 없습니다'
        },
        maxCvTipFirst: '1.초기 테스트 시 권장 활동 일 예산은 목표 CPA의 5~10배입니다.',
        maxCvTipLast: '2.MaxCV 모드에서 CPA가 너무 높아지는 것을 방지하기 위해, 매일 최대 1회만 일일 예산을 조정하는 것이 좋으며, 조정 폭은 30% 이내로 설정 하는 것을 권장합니다.',
        dataInheritance: 'Data Inheritance',
        dataInheritanceInternal: 'Data Inheritance（internal）',
        imgUploadSizeUpperLimit: '소재 추가 한도',
        smartBidmaxcv: 'SmartBid Max CV',
        smartBidmaxcvTip: '예산 내에서 최대한 많은 전환을 받고싶다면 해당 비딩 기능을 선택해주세요. ',
        submitTitle: '이미지, 타이틀 등 소재에서 타인의 이름, 사진, 대화 및 초상, 상표, 저작물 등을 무단으로 사용하지 않았습니다.',
        requiredTitle: '(필수)',
        picSize: '이미지 사이즈',
        displaySize: 'Banner Ads Upload',
        anySize: '임의 사이즈',
        recommendedSize: '권장 디스플레이 광고 사이즈',
        plsSelectOne: '이미지 사이즈 선택해주세요',
        plsSize: '[{size}]사이즈의 이미지를 업로드해주세요',
        campaignCategoryInfo: 'Define a category for your campaign content.The effectiveness of campaign optimization is highly related to the category, please select carefully.',
        campaignCategory: 'Campaign Category',
        createCampaignSetps: 'Create A Campaign',
        addAds: 'Add Creatives',
        conversionGoal: 'Conversion Goal',
        basicSettings: 'General Settings',
        campaignTargeting: 'Campaign Targeting',
        marketingObjective: 'Marketing Objective',
        scheduleAndBudget: 'Schedule and Budget',
        smartBid: 'Smart Bid',
        smartBidLearning: 'Smart Bid (Learning)',
        smartBidLearningTip: 'MediaGo is learning how to best deliver your campaign. Performance may improve further. Please avoid editing your ad set during the learning phase. This phase uses Fixed Bid.',
        smartBidLearningCompleted: 'Smart Bid (Learning Completed)',
        smartBidLearningCompletedTip: 'The learning phase has complete for this campaign. Smart Bid is already in use.',
        uploadStatus: 'ステータス',
        audienceTip: 'Choose target audiences for the campaign. Selecting more audience types will increase your audience reach.',
        singleUpload: '手動入稿',
        bulkUpload: '一括入稿',
        uploadFile: 'インポート',
        uploadCsvFile: 'CSVファイルをインポート',
        downloadTemplateCsv: 'テンプレートをダウンロード',
        csvUploadTip: '下記リンクよりバルクシートのテンプレートをダウンロードしてください。<br>バルクシートをインポートする際に、テンプレート内の３行目の記入例を削除してください。<br>《入稿項目》<br>入稿URL、UTMトラッキングコード（必須ではない）、カテゴリー、タイトル、広告主名、画像。<br>バルクシートのフォーマットは以下です。<br>・ファイル形式：.csv<br>・⽂字コード︓UTF-8<br>・コンマ区切り',
        dragUpload: 'ドラッグ＆ドロップ',
        plsUploadCsv: 'CSVファイルをアップロードしてください',
        uploadRecord: '	インポートログ',
        csvRecordTip: 'キャンペーンあたり登録可能なクリエティブ(asset)数：最大100',
        uploadTime: 'インポート時間',
        uploadRemark: '備考',
        file: 'ファイル名',
        magnitudeEvaluation: '규모 평가',
        thirdPartyTracking: '3rd Party Pixel',
        tagType: '라벨 유형 ',
        eventType: '이벤트 유형',
        url_code: '링크/코드 추가',
        click: '딸깍 하는 소리',
        viewImperssion: '시각적 디스플레이',
        trackingTips: '여기에서 제3자 추적을 추가하여 광고 프로그램 데이터를 캡처하고 이를 외부 플랫폼으로 전송하여 마케팅 목표를 달성할 수 있습니다. 타사 추적에서 지원하는 매크로는 UTM에서 지원하는 것과 동일합니다. 더 이해 참고: JavaScript 태그를 사용할 때 일부 트래픽은 이러한 유형의 추적을 지원하지 않기 때문에 추적 데이터는 실제 데이터와 다를 수 있습니다.',
        jp: '일본',
        au: '호주',
        gb: '영국',
        sg: '싱가포르',
        my: '말레이시아',
        th: '태국',
        hk: '홍콩',
        tw: '대만',
        kr: '대한민국',
        id: '인도네시아',
        preview: '미리보기',
        collapse: '접',
        expand: '벌',
        // campaign
        createCampaign: '스케줄과 예산',
        de: '독일',
        fr: '프랑스',
        it: '이탈리아',
        es: '스페인',
        at: '오스트리아',
        br: 'Brazil',
        mx: 'Mexico',
        nl: 'Netherlands',
        pl: 'Poland',
        se: 'Sweden',
        be: 'Belgium',
        pt: 'Portugal',
        ch: 'Switzerland',
        cz: 'Czechia',
        no: 'Norway',
        dk: 'Denmark',
        hu: 'Hungary',
        deLang: '독일어',
        itLang: '이탈리아어',
        frLang: '프랑스어',
        accountName: '계정명',
        campaign: '캠페인',
        objective: '목적',
        promotionObjective: '광고 목표',
        completeConversion: '전환',
        purchaseOrder: '구매',
        willingnessToInteract: '참여',
        fillForm: '폼 작성',
        signUp: '회원가입',
        downLoadData: '자료 다운로드',
        sendMessage: '메시지 발송',
        browseWebpage: '웹페이지 열람',
        reputation: '브랜드 인지도',
        enhanceBrand: '브랜드 인지도 높이기',
        objectiveTraffic: '트레픽',
        objectiveAwareness: '브랜드 인지',
        objectiveConversions: 'CV',
        objectiveLead: '잠재적 사용자',
        campaignName: '캠페인명',
        campaignStatus: '캠페인상태',
        startAndEnd: '시작&종료',
        startTime: '시작',
        endTime: '종료',
        setTime: '시간설정',
        immediately: '바로라이브',
        indefinitely: '종료일 미설정',
        dayparting: '시간대별',
        selectDay: '캠페인을 게재할 시간과 날짜를 선택하세요.',
        dailySpendCap: '일예산',
        dailyCapTip: '실제 이용금액은 일예산보다 낮거나 (최대 2배) 많을 수 있습니다.',
        lifetimeSpendCap: '총예산',
        lifetimeSpendCapTip: '매월 또는 합계 캠페인의 예산을 선택할 수 있습니다.',
        lifetimeBudget: '총예산',
        monthlyBudget: '월예산',
        pacing: '송출속도',
        // ad set
        search: '검색...',
        English: '영어',
        Spanish: '스페인어',
        Japanese: '일본어',
        All: '모든',
        lang: '언어',
        countryUsa: '미국',
        countryCanada: '캐나다',
        adSetTitle: '목표 설정',
        adSets: '광고리셋',
        adSetsName: '광고리셋명',
        adSetsStatus: '상태',
        adFormat: '광고포멧',
        placement: '위치',
        size: '사이즈',
        homepage: '홈페이지',
        audience: '광고 타깃',
        audienceAsiaTip: '주의 : 선택한 광고 타깃은 아시아에서의 볼륨이 제한되어있습니다',
        audienceAmericaTip: '주의 : 선택한 광고 타깃은 미국에서의 볼륨이 제한되어있습니다',
        industry: '카테고리',
        industryPh: '카테고리를 선택해주세요.',
        industryInfo: `상기의 랜딩페이지의 상품 혹은 서비스의 카테고리를 설명하기위해 최대 2셋트의 단어를 선택해주세요. 정확한 설명은 알고리즘이 자동적으로 적절한 트래픽을 캠페인에 적용하도록 합니다.
        *카테고리를 임의로 변경하지 말아주세요.`,
        location: '지역',
        state: '시',
        us_state: '시',
        ca_state: '지역',
        au_state: '지역',
        gb_state: '시',
        jp_state: '지역',
        tw_state: '지역',
        id_state: '지역',
        kr_state: '지역',
        hk_state: '지역',
        sg_state: '지역',
        my_state: '지역',
        th_state: '지역',
        // 必须按照缩写_placeholder的格式填写 有多个地方用到
        us_placeholder: '시/우편번호',
        ca_placeholder: '지역',
        au_placeholder: '시',
        gb_placeholder: '지역',
        jp_placeholder: '지역',
        tw_placeholder: '지역',
        id_placeholder: '지역',
        kr_placeholder: '지역',
        hk_placeholder: '지역',
        sg_placeholder: '지역',
        my_placeholder: '지역',
        th_placeholder: '지역',
        targetBid: '타겟입찰',
        contentCategory: '컨텐츠카테고리',
        device: '디바이스',
        os: 'OS',
        osv: 'Win10 AD-Targeting',
        userPackage: '사용자 패키지.',
        domainwhitelist: '모바일 도메인',
        moreBtn: '더보기',
        impURL: '서드파티트레킹',
        osvTip: '이것을 선택하면, Windows 10을 타겟팅합니다.',
        amountSmall: '주의 : 타겟팅을 좁게 설정하면 플랫폼의 시스템 브라우저가 매칭하는 트래픽이 적어집니다. 광고송출의 유효성의 영항을 끼칠수 있으니 다른 플랫폼의 시스템브라우저도 함께 적용하여 테스트하길 추천합니다.',
        noAmount: '주의 : 선택한 타겟팅 시스템과 브라우저가 매칭되지않아 트래픽이 없습니다. 재선택해주세요.',
        platform: '플렛폼',
        browser: '브라우저',
        gender: '성별',
        carrier: '운영자',
        include: '포함',
        exclude: '제외',
        only: '한정 분야',
        zipcode: '우편번호',
        // 需要有一些失败提醒
        locationTip: '정보를 전부 입력해주세요.',
        locationTip1: '필수',
        locationCheck: '해당 지역의 IP에 한함',
        locationCheckTip: '선택 후 해당 지역의 IP만 타겟합니다',
        allSelect: '전부',
        allSelect1: '모두선택',
        createAds: '소재명',
        adName: '브랜드명',
        adAssetName: '소재명',
        adStatus: '상태',
        brandName: '브랜드명',
        headline: '타이틀',
        asset: '이미지 및 이름',
        webUrl: '랜딩페이지',
        upload: '업로드',
        reUpload: '재 업로드',
        utmSuffix: '트레킹',
        urlPreview: '그룹 미리보기',
        // imageDescription: 'CTR을 높이기 위해서는 16:9 비율의 이미지를 업로드하여 중앙에 맞춰주시는걸 추천드립니다. (이미지의 환경으로부터 적절한 거리를 유지하게됩니다). 최대 이미지사이즈는 2MB입니다. 이미지의 사이즈는 600x400픽셀보다 높게 할 필요가 있습니다.',// imageDescription: '为提升点击率，推荐上传比例为16:9、且图片素材居中（与边框保持适当间距）的图片。图片最大为2MB，分辨率需大于600x400像素。',
        imageDescription: '이미지의 최대사이즈는2MB、최소해상도는600x400픽셀입니다.',
        // CAMPAIGN_ID: '캠페인ID',                              emmmmm注释都被翻译了，那么辛苦,就留着吧。。。
        // AD_ID: '광고 ID',
        // AD_NAME: '광고 이름',
        // PAGE_TYPE: 'Page type, DHP, HP, etc.',
        // AD_STYLE: '광고형식, infopane, river 등',
        // REQ_ID: '요청 ID , 제거',
        // BID_TIME_STAMP: '광고 충전 타임스탬프(정확도 , MideaGo에서 지원되는 기준)',
        // TRACKING_ID: '추적ID',
        // CPC: '이광고의 CPC',
        more: '상세를 확인해주세요.',
        utmInfos: '캠페인의 추적용 트레킹코드를 입력해주세요.',
        titleInfo: '타이틀에 동작메크로를 설정할수있습니다.',
        titleTemplate1: '이 수치는 유저가 보고있는 지역 ( 상태를 포함 ) 으로 바꿀수있습니다.',
        titleTemplate2: '이 수치는 월단위로 바꿀수있습니다. 예 : 1월',
        utmInfoHeader: '추천된 트랙킹코드 :',
        titleInfoHeader: '',
        assetPreview: '소재 미리보기',
        adList: 'AD리스트',
        adHead: {
            id: '광고ID',
            name: '소재명',
            image: '이미지',
            title: '타이틀',
            brandName: '광고주명',
            url: '랜딩페이지',
            searchPlaceholder: '타이틀/소재명'
        },
        assetAditionInfo: '더많은 트래픽이 올 예정입니다.',
        closedText: '곧 개시예정...',
        delAdMsg: '이 정보를 삭제하시겠습니까? (관련 광고는 남으며, 데이터는 저장됩니다.)',
        mdyAdMsg: '모든 사이즈를 유지하는것을 추천합니다. 알고리즘은 최적화를 위해 제일 퍼포먼스가 높은 광고를 선택합니다.',
        disableSizeMsg: '모든 사이즈를 유지하는것을 추천합니다. 알고리즘은 최적화를 위해 제일 퍼포먼스가 높은 광고를 선택합니다.',
        importZipCode: '우편번호 가져오기',
        importDomainList: '화이트리스트 도메인을 가져오기',
        selectCsv: 'CVS파일을 선택해주세요',
        downloadTemplate: '탬플릿 다운로드',
        previewTip: '의 컨텐츠레코멘드는 다양한 형식, 사이즈로 적용가능합니다. 설치영역, 광고 ( 이미지, 타이틀 , 광고주명)은 사이트 형식에 따라 바뀝니다.',
        domainTip: 'CSV파일을 통해 필요한 화이트도메인리스트를 업로드할 수있지만, 이러한 화이트리스트는 플랫폼의 기존도메인과 일치할 경우에만 적용되며 성공적으로 일치한 화이트리스트는 모바일 대상 트래픽에만 유효합니다.',
        fixedCpc: '고정 CPC',
        notCut: '이미지 자르지 않음',
        notCutTip: '선택하지 않을 경우, 시스템이 필요한 비율에 따라 이미지를 자동으로 잘라냅니다. 제출 후 수정 불가하오니 신중하게 선택하세요.',
        OCPC: 'OCPC',
        ECPC: 'ECPC',
        ocpcTip: '입찰가격은 전환 목표에 따라 조정될 것이며 이 기능을 선택하면, 도메인별 비딩 기능이 비활성화됩니다.[지원되는 지역: 일본]',
        cpaMaxChangeTip: '조절가능한 최대 CPA를 입력하세요. CPA 효과를 최적화하기 위해 시스템이 학습 과정 중 최대 수치를 초과할 수도 있습니다.',
        ecpcTip: '더 높은 전환률을 얻을 수 있게 시스템이 CPC*입찰가격 범위 내에서 동적으로 입찰 가격을 조정합니다. 이 기능을 선택하면 도메인별 입찰 CPC도 이 CPC의 변동에 따라 바뀝니다.[지원되는 지역: 미국, 캐나다, 영국, 호주]',
        cpaTypeTip: '시스템이 더 발전하고 빠른 학습을 할 수 있도록 CPA 유형을 선택한 후에는 수정하지 않는 것을 권장합니다.',
        fisrtStepCpc: '1단계 CPC',
        secondStepTargetCpa: '2단계 대상 CPA',
        secondStepTargetMcpa: '2단계 목표 MCPA',
        cpaMaxChange: '조절가능한 최대 CPA',
        cpcChange: '입찰 가격 범위',
        targetCpa: '대상 CPA',
        cpaType: 'CPA 유형',
        targetCpaRequired: '목표 CPA 입력해주세요',
        targetCpanumbered: '수자만 입력해주세요',
        bidType: '입찰 방식',
        fixedBid: '고정 CPC',
        smartBidText: 'OCPC',
        smartBidTargetCPA: 'SmartBid Target CPA',
        smartBidTargetCPATip: 'Target CPA를 너무 낮게 설정하면, 스마트 입찰에 참여할 수 없습니다.',
        smartBidNext: 'Smart Bid',
        smartBid: 'OCPC(현재 일본에서만 이용가능)',
        phaseCpc: '1단계 CPC',
        smartBidTips: '*1단계 - 모델학습: 고정 CPC 입찰, 변환 데이터 축적\n*2단계 - 스마트 입찰: 캠페인 목표, 목표 CPA 및 전환 데이터를 기반으로 한 스마트 입찰',
        smartBidNextTips: '캠페인 효율 자동 최적화를 적용하려면 해당 비딩기능을 사용해 보세요.',
        smartTargetCpaTips: '목표 CPA:\n입력한 목표 CPA를 기준으로 송출 효과를 계속 최적화할 것이며, 시스템 최적화 효과에 영향을 미치지 않도록 예상 목표 CPA에 따라 입력해 주세요.',
        upToAdsLimitMsg: 'The maximum number of ads for this campaign is {limit}.',
        previewImgTip: '이미지 비율 ',
        previewImgTitle: '제목',
        previewImgName: '브랜드명',
        theThirdPartyPixelPrompt: '여기에 타사 추적을 추가하면 캠페인 데이터를 캡처하고 외부 플랫폼에 전달하여 마케팅 목표를 촉진할 수 있습니다. ',
        PixelPromptMore: '타사 추적은 UTM이 지원하는 동일한 매크로를 지원합니다.',
        postback: 'Postback',
        postbackNull: 'None',
        mPostbackNull: 'None',
        discovery: 'Discovery',
        postback91App: '91App',
        mediago: 'MediaGo',
        postbackTip: '이 Postback 설정은 해당 캠페인의 모든 소재에 적용됩니다. 「None」을 선택하면 CV의 정확도가 떨어질 수 있습니다',
        assetInfo: '여러 이미지 업로드 지원</br>권장하는 종횡비 : 16:9</br>이미지 유형: JPG, JPEG, PNG, GIF</br>최대 이미지 크기: 1MB</br>제품/로고 등 중요 정보를 20% 정도 내축시키고, 이미지 가장자리에 너무 가깝게 하지 말 것을 권장합니다.',
        assetSingleInfo: '권장하는 종횡비 : 16:9</br>이미지 유형: JPG, JPEG, PNG, GIF</br>최대 이미지 크기: 1MB</br>제품/로고 등 중요 정보를 20% 정도 내축시키고, 이미지 가장자리에 너무 가깝게 하지 말 것을 권장합니다.',
        assetInfoTip: '이미지 크기: 1MB 까지 제한',
        gifSizeTip: '; GIF 크기 제안: 10MB 까지 제한',
        createCampaignBtnText: '캠페인 제출',
        campaignNoAssetsTips: '해당 캠페인이 아직 소재를 업로드하지 않았습니다',
        addAssetsBtnText: '소재 추가'
    },
    audit: {
        review: '심사',
        auditStatus: '심사 상태',
        detail: '상세',
        reAudit: '재심사',
        reviewAds: '재심사',
        status: '상태',
        inReview: '심사중',
        limited: '매체제한',
        preliminaryReview: '예비심사중',
        secondReview: '제2심사중',
        rejected: 'NG처리되었습니다',
        rejectedSelect: '일부의 캠페인이 NG처리되었습니다.',
        manuallyApproved: '수동으로 승인',
        bdApproved: 'Baidu승인완료',
        msApproved: 'Publisher승인완료',
        bdRejected: 'Baidu 심사 거절',
        msRejected: 'MSN 심사 거절',
        adId: '광고ID',
        campaignName: '캠페인명',
        accountName: '계정명',
        submittedTime: '제출시간',
        startTime: '개시시간',
        adName: '광고명',
        operate: '조작',
        startReviewing: '리뷰개시',
        searchPlaceholder: '캠페인명 또는 계정명섬색',
        adInfo: '광고정보',
        userId: '유저ID',
        reviewItems: '심사내용',
        pass: '패스',
        fail: '실패',
        reviewResult: '결과확인',
        conclusion: '결론',
        approved: '승인완료',
        abnormal: '경고',
        description: '설명',
        comments: '코맨트',
        skip: '스킵',
        submitAndNext: '송출하고 다음으로',
        reviewer: '심사담당',
        reviewTime: '시간확인',
        reviewed: '심사',
        reviewedLines: '심사:{num}건',
        team: '심사팀',
        result: '심사결과',
        reason: 'NG이유'
    },
    auditEn: {
        confirmation: 'confirmation',
        searchSimilar: '일괄심사',
        assetType: '소재유형',
        nativeAsset: 'Native',
        displayAsset: 'Display',
        mediaType: '매체',
        location: '지역',
        language: '언어',
        languageTip: '영어의 소재는 스페인어 트래픽에서는 실행되지 않습니다.',
        audience: '광고 타깃',
        industry: '카테고리',
        startTime: '시작시간',
        brandName: '브랜드명',
        headline: '타이틀',
        asset: '이미지',
        landingPage: 'LP',
        webUrl: 'LP',
        mApproved: 'In Machine Review',
        preliminaryReview: '심사중',
        secondReview: '2차심사',
        rejected: 'NG',
        manuallyApproved: '수동으로 승인',
        bdApproved: 'Baidu승인완료',
        msApproved: 'Publisher승인완료',
        bdRejected: 'Baidu NG',
        msRejected: 'Publisher NG',
        operate: '조작',
        startReviewing: '심사 시작',
        searchPlaceholder: '캠페인/계정 심사담당',
        adId: '광고 ID',
        assetId: '소재 ID',
        assetName: '소재명',
        campaignName: '캠페인명',
        accountName: '계정명',
        submittedTime: '송출시간',
        assetSubmissionTime: '소재 제출 시간',
        campaignSubmissionTime: '캠페인 제출 시간',
        reviewItems: '심사대상',
        pass: '통과',
        fail: 'NG',
        reviewResult: '결과확인',
        conclusion: '결과',
        approved: '승인완료',
        description: '설명',
        comments: '코멘트',
        skip: '스킵',
        submitAndNext: '송출하고 다음으로',
        reviewer: '심사당담',
        reviewTime: '심사시간',
        reviewed: '심사완료',
        reviewedLines: '심사완료 : {num}건',
        team: '심사팀',
        result: '심사결과',
        reason: 'NG이유',
        status: '상태',
        detail: '상세',
        review: '심사',
        reAudit: '재심사',
        adInfo: '광고정보',
        adName: '광고명',
        operateRecord: '코드를 확인합니다.',
        baiduBrainScanRecords: 'Baidu Brain Scan Records',
        refused: 'Refused',
        brainApproved: 'Approved',
        suspected: 'Suspected',
        others: 'Others',
        modify: 'Modify',
        baiduBrainScan: 'Baidu Brain Scan',
        baiduBrainScanResult: 'Baidu Brain Scan Result',
        baiduBrainRejectReason: 'Baidu Brain Reject Reason',
        baiduBrainReviewTag: 'Baidu Brain Review Tag',
        noData: 'No Data',
        lowLevelPornographic: 'Low Level Pornographic;',
        highLevelPornographic: 'High Level Pornographic;',
        lowLevelPolitics: 'Low Level Politics;',
        highLevelPolitics: 'High Level Politics;',
        lowLevelViolenceAndTerrorism: 'Low Level Violence And Terrorism;',
        highLevelViolenceAndTerrorism: 'High Level Violence And Terrorism;',
        lowLevelProhibited: 'Low Level Prohibited;',
        highLevelProhibited: 'High Level Prohibited;',
        lowLevelDisgustingPicture: 'Low Level Disgusting Picture;',
        highLevelDisgustingPicture: 'High Level Disgusting Picture;',
        submit: '송출',
        cancel: '캔슬',
        confirm: '확인',
        skipInfo: '컨텐츠를 잃을 수도있습니다. 문제없습니까?',
        leaveInfo: '컨텐츠를 잃을 수도있습니다. 문제없습니까?',
        reviewNotComplete: '심사가 미완료 상태입니다.',
        requestError: '네트워크 에러, 재실행 해주세요.',
        discarded: '보관',
        pass1next: '통과하고 다음으로',
        passAction: '통과',
        geoEdge: 'Geo Edge',
        displayAllImg: '심사된 모든 이미지를 표시하기',
        setSensitive: '부적절한 광고 카테고리',
        baiduBrain: 'Baidu Brain'
    },
    monthBill: {
        agencyAccountName: '대행사 계정',
        monthBillTitle: '청구서',
        settlementCurrency: '청구금액',
        settlementCurrencyRemark: '세금포함청구금액(결제통화)',
        montlyExchangeRate: '환율',
        settlementSpendWithTax: '세금 포함',
        spendPlusTax: '비용',
        consumptionTax: '소비세',
        consumptionTaxRemark: '소비세',
        spendPlusTaxRemark: '비용(부가세 별도)',
        settlementSpendWithTaxRemark: '청구금액(세금포함)，청구금액(부가세 포함) = 비용(부가세 별도) + 부가세',
        monthlyExchangeRate: '환율',
        agentName: '대행사 계정',
        updataTitile: '전월 청구서는 다음 달 3일에 업데이트됩니다',
        monthlyExchangeRateRemark: '환율 환산 규칙: 지난달 일평균 환율을 기준으로 환산합니다. 환율은 The Federal Reserve System을 참조합니다.<a href="http://www.federalreserve.gov/releases/h10/hist/default.htm">http://www.federalreserve.gov/releases/h10/hist/default.htm</a>'

    },
    creditLine: {
        currencyConverterTip: '환율 환산 규칙: 지난달 일평균 환율을 기준으로 환산합니다. 환율은 The Federal Reserve System을 참조합니다.<a>http://www.federalreserve.gov/releases/h10/hist/default.htm</a>',
        tradingCurrencies: '거래화폐종류',
        accountName: '계정명',
        accountId: '계정ID',
        status: '계정의 상태',
        companyName: '회사명',
        availableBalance: '이용가능한 잔액',
        availableBalanceRemark: '합계 잔고 = 계정잔고+신용한도+합계쿠폰소비량+합계유효쿠폰잔고（거래화폐종류）',
        balance: '계정의잔고',
        balanceRemark: '계정잔고= 누적충전-누적소진(CPC)（거래화폐종류）',
        totalRecharge: '누적충전',
        totalRechargeRemark: '누적충전량',
        creditLinetotalRechargeRemark: '누적충전량（거래화폐종류）',
        totalSpend: '총지출',
        untaxedCopy: '누적 소진금액은 세금 미포함 금액이며, 실제 청구서의 세금포함 금액과 차이가 있을 수 있습니다',
        totalSpendRemark: '과거의 누적총지출</br>（거래화폐종류）',
        creditBalance: '카드잔고',
        creditBalanceRemark: '계정잔액+쿠폰 총소비량>=0인경우 카드잔액=카드한도.계정잔고+쿠폰 총소비량<0인경우, 카드잔액=신용한도+계정잔고+쿠폰합계소비량（거래화폐종류）',
        creditLine: '신용한도',
        creditLineRemark: '계정에 설정한 추가한 총 신용한도（거래화폐종류）',
        balanceNote: '현재 계정의 사용 가능한 총 한도가 $200 미만입니다. 참고해주세요',
        balanceNote1: '광고송출에 영향이없도록 되도록 빠르게 재충전 해주세요.',
        filter: '필터',
        charge: '충전',
        edit: '편집',
        editTitle: '신용카드의설정',
        chargeTitle: '충전',
        currencyTitle: '통화',
        tradingCurrencyLable: '거래화폐종류',
        contractualTitle: '合同主体',
        agent: '대행사',
        userName: '운영담당',
        note: '메모',
        operationTime: '조작시간',
        amount: '충전금액',
        chargeTime: '충전시간',
        editRecordTitle: '신용카드 편집이력',
        chargeRecordTitle: '충전기록',
        opt: '조작',
        couponBalance: '유효한쿠폰잔고',
        couponBalanceRemark: '유요한 전 쿠폰잔고</br>（거래화폐종류）',
        couponSpend: '소진완료 쿠폰요금',
        couponSpendRemark: '소진완료 쿠폰요금</br>（거래화폐종류）',
        couponTitle: '쿠폰',
        couponAmount: '쿠폰 잔고',
        effectiveTime: '유효기간 시작',
        expireTime: '유효기간 끝',
        offline: '오프라인',
        offlineTip: '실행하면 이 쿠폰을 이용할 수 없게됩니다. 실행하시겠습니까?',
        profitRate: '이익률(예상)',
        profitRateRemark: '본계정의 예상이익률 설정하기. 이익률의 수치는 실제입찰에 영향을 미칩니다.',
        profitRateTitle: '이익률의 설정',
        profitRateRecordTitle: '이익률수정기록',
        curProfitRate: '지금의 이익률',
        transactionCurrency: '거래화폐종류',
        settletmentCurrency: '결제화폐종류',
        couponCost: '쿠폰 이용금액',
        adjustmentAmount: '변경금액',
        receivablesWithTax: '미수금(부가세 포함)',
        adjustmentAmountRecord: '금액변경 내역',
        receivablesWithoutTax: '미수금(부가세 미포함)',
        currentCoupons: '쿠폰 이용금액',
        selectMonth: '시간 선택',
        amountSetting: '변경금액 설정',
        setAmount: '변경금액 편집',
        searchText: '계정/회사/대행사 검색',
        searchMonthlyText: 'Search Account/Agency Name',
        amountRecords: '변경금액 편집내역',
        spendPlusTaxRemark: '선택한 기간 내 해당 계정의 세전소진금액',
        couponCostRemark: '선택한 기간 내 해당 계정의 쿠폰적용금액',
        adjustmentAmountRemark: '선택한 기간 내 조정된 총금액(세전)',
        consumptionTaxRemark: '(조정후)당기세전소진금액에서 발생한 부가세',
        monthlyExchangeRateRemark: '당월 평균 환율',
        receivablesWithTaxRemark: '선택한 기간 내 계정의 누적 세후금액',
        receivablesWithoutTaxRemark: '선택한 기간 내 해당 계정의 미수금(거래통화)',
        currentUntaxedSpend: '청구금액(부가세 미포함)',
        settlementCurrency: '결제화폐종류',
        rebate: '리베이트 금액',
        monthText: '매월 3일 이후에는 이전의 금액을 수정할 수 없다',
        adjustedText: '전체 조정 금액을 입력하십시오',
        changeRecords: '편집내역',
        remarkNote: '설명',
        changeDate: '변경 시간',
        amountEdit: '변경금액 설정',
        release: '게시'
    },
    roiMgr: {
        serviceType: '서비스 종류',
        category: '업종',
        companyName: '회사명',
        accountName: '계정명',
        accountId: '계정ID',
        income: 'Baidu현재매출',
        incomeRemark: 'Baidu현재매출=계정현재소진-현재소진완료된쿠폰금액',
        spend: '계정의 현재소진',
        spendRemark: '선택된 시간대의 소진',
        cost: '현재의 트래픽코스트',
        costRemark: '선택기간에서의 MSN지불금액(CPM)',
        profit: '현재의 이익',
        profitRemark: '현재의 이익=Baidu현재의 이익-현재의트래픽코스트',
        profitRate: '이익률(실제)',
        profitRateRemark: '이익률=지금의 이익/Baidu현재매출',
        impression: '표시',
        click: '클릭수',
        filter: '필터',
        coupon: '현재의 쿠폰코스트',
        couponRemark: '선택된 기간중의 총 쿠폰소진량',
        rebateRatio: '환불률',
        rebateRatioRemark: 'ROI리포트페이지에서는, 대행사와 광고주 양쪽의 환불률을 표시합니다.',
        source: '고객 소스',
        sourceCompany: '고객 소스 회사',
        accountType: '계정 특성',
        rebate: '현재 환불 비용',
        rebateRemark: '현재 환불 비용 = Baidu 현재 매출 x 환불 비율',
        launchDays: '송출 일수',
        averageDailySpend: '일일 소진량',
        maximumDailySpend: '최대 일소진'
    },
    // 可视化测试
    visualTest: {
        adminEmailCode: '관리자가 이메일 인증번호를 가져옵니다.'
    },
    financiaIncome: {
        accountId: '계정 ID',
        companyName: '회사명',
        accountName: '계정명',
        timezone: '계정 시간대',
        email: '계정 소유자의 이메일',
        submitTime: '생성 시간',
        spend: '계정 당기 소진',
        spendRemark: '선택한 기간 내 계정의 누적 지출',
        coupon: '현재의 쿠폰',
        couponRemark: '선택한 기간 동안의 총 쿠폰 소진량',
        receivablesRemark: '재무채권=계정 당기 소진-현재 쿠폰 비용',
        recharge: '현재 충전량',
        receivables: '재무채권',
        businessManager: '비즈니스 매니저'
    },
    financiaPay: {
        accountType: '계정 특성',
        accountId: '계정ID',
        source: '고객 소스',
        rebateRatio: '환불률',
        rebateRatioRemark: '지불 가능한 페이지에서 대행사 광고주의 환불률은 0입니다(여기에는 표시되어 있지 않습니다).',
        companyName: '회사명',
        accountName: '계정명',
        timezone: '계정 시간대',
        email: '계정 소유자의 이메일',
        cost: '현재 트래픽 비용',
        businessManager: '비즈니스 매니저',
        costRemark: '선택한 기간의 MSN 지불 금액(CPM)',
        rebate: '현재 환불 비용',
        sspName: '매체'
    },
    dataReport: {
        account: '계정',
        device: '디바이스',
        os: 'OS',
        platform: '플렛폼',
        browser: '브라우저',
        audience: '광고 타깃',
        industry: '카테고리',
        location: '위치',
        // country: '나라',
        country: '지역',
        stateregion: '시/지역',
        language: '언어',
        publisher: '광고주',
        campaignName: '캠페인',
        assetName: '소재명',
        adsize: '이미지사이즈',
        spend: '결제',
        viewedImp: 'View IMP',
        click: '클릭수',
        ctr: 'CTR',
        colAvgCpc: '평균 CPC',
        colVcpm: 'vCPM',
        colDate: '날짜',
        conversions: 'CV',
        conversionRate: 'CVR',
        conversionPrice: 'CPA',
        colConversionPrice: 'CPA',
        colConversionRate: 'CVR',
        colCtr: 'CTR',
        needAccount: '계정을 선택해주세요',
        needCampaign: '캠페인을 선택해주세요.',
        timePre: '최종갱신일',
        conversionTimePre: '최신 컴버전데이터',
        timeAfter: '(리포트 시간대는 광고 계정과 동일합니다)',
        targetBid: '타겟입찰',
        fixedBid: '고정CPC',
        OCPC: 'OCPC',
        ECPC: 'ECPC'
    },
    amDashboard: {
        // 运营配置
        currentSsp: '현재 송출중인 SSP',
        // black&white
        kwTitle: '계정별 매체 블랙리스트/화이트리스트 및 캠페인별 키워드 설정',
        kwSetting: '블랙리스트/화이트리스트 설정',
        kwTag: '키워드 설정',
        filterType: '필터 종류',
        // blackList: 'Black List',
        // whiteList: 'White List',
        insertList: '키워드 목록 가져오기',
        selectCsv: 'CSV 파일 선택',
        editDate: '수정 시간',
        operationId: '수정인',
        objectName: '수정 내용',
        insert: '上传',
        edit: '修改',
        importWhite: '키워드 화이트리스트 가져오기',
        importBlack: '키워드 블랙리스트 가져오기',
        kwWarning: '잘못된 포맷, 아래 키워드 수정 후 다시 업로드해주세요',
        dialogTitle: '업로드 결과',
        dialogSubTitle: '키워드 {num}개 업로드됨',
        downloadCurrentCSV: '해당 CSV파일 다운로드',
        companyName: '회사명',
        // country: '나라',
        country: '지역',
        language: '언어',
        date: '날짜',
        roas: 'ROAS',
        // remainingBudget: '캠페인 잔고',
        remainingBudget: '나머지 총예산',
        taboolaBind: 'Taboola Ad Pull',
        configableCpc: '설정가능 CPC의 하한',
        accountListSetting: '계정리스트 설정',
        hideClosedAccount: 'OFF한 계정을 비표시',
        hideBalanceAccount: '총 잔액이 0이하인 계정 숨기기',
        CPC: 'CPC',
        totalBudget: '총예산',
        dailyBudget: '일예산',
        campaignName: '캠페인명',
        accountName: '계정명',
        status: '상태',
        campaignStatus: '캠페인의 상태',
        spend: '당기 매출',
        impression: 'IMP',
        click: '클릭',
        clickRate: 'CTR',
        startTime: '시작시간',
        endTime: '종료시간',
        iImpression: 'IMP',
        cost: '비용',
        iCtr: 'iCTR',
        iCpm: 'ICPM',
        viewableRate: 'Viewable Rate',
        vCtr: 'VCTR',
        colCfgTitle: '예',
        campaignId: '캠페인ID',
        accountId: '계정ID',
        colStatus: '상태',
        colStart: '시작',
        colEnd: '종료',
        colSpend: '현재의 수익',
        colTotalBudget: '총예산',
        colDailyBudget: '일예산',
        budget: '예산',
        colDetail: '상세',
        colDelivery: 'Delivery',
        colCpc: 'CPC',
        colAvgCpc: '평균CPC',
        colVCpm: 'vCPM',
        colImp: 'View IMP',
        colClick: '클릭',
        colCtr: 'vCTR',
        colConversion: 'CV',
        colConversionRate: 'CVR',
        colConversionPrice: 'CPA',
        colIimpression: 'IMP',
        colCost: '비용',
        colIctr: 'CTR',
        colIcpm: 'CPM',
        colViewableRate: 'Viewable Rate',
        blackWhiteConfig: '블랙리스트 화이트리스트',
        colProfitRate: '이익률',
        colSpendRate: '예산소진율',
        colLastSpend: '상기 매출',
        colCompareRate: '매출 대비',
        colAmResponsible: 'AM 담당자',
        spendRemark: '매출대비=(당기매출-상기매출)/상기매출',
        spendRateRemark: '일예산소진율: 선택한 기간 동안 해당 광고의 소진/선택한 기간 동안 해당 광고의 일예산의 총합 (최신 일예산을 사용합니다)',
        profitRateRemark: '이익률',
        totalCampaign: '모든캠페인',
        abnormalCampaign: '이상 캠페인',
        campaignOptLog: '캠페인 조작 이력',
        campaignOptCfg: '캠페인 운영 구성',
        pageIdBlockCfg: '캠페인 PageId 블랙리스트 구성',
        campaignAlertCfg: '캠페인 이상 알림',
        colIndustry: '카테고리',
        tradeCategory: '업종별',
        icafe: {
            newItem: 'New Tracker'
        },
        serviceRecord: '광고주 운영 기록',
        minCpcRecordText: '최소 CPC는 {old}에서{new}로 수정되었습니다',
        accountBlackWhiteConfigTitle: '계정 레벨 매체 블랙리스트 및 화이트리스트 설정',
        campaignBlackWhiteConfigTitle: '캠페인 레벨 매체 블랙리스트/화이트리스트 설정',
        sellerIdBlackWhiteConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        sellerIdBlackWhiteConfigTips: '*The SellerID blacklist/whitelist will take effect, only when the Account-level media blacklist/whitelist is removed',
        rulesType: '규칙 유형',
        configIndex: '레벨 설정',
        configObject: '설정 대상',
        blackList: '블랙리스트',
        whiteList: '화이트리스트',
        mediaSiteGroup: '매체 사이트 그룹',
        mediaAccountAndSite: '매체 계정/매체 사이트',
        mediaAccount: '매체 계정',
        mediaSite: '매체 사이트',
        selectedConfig: '설정 기록에 현재 유효한 구성 + 유효하지 않은 구성 추가',
        rulesTypeTip: '블랙리스트/화이트리스트 동시에 추가할 수 없습니다. 해당 유형 설정완료 후 다른 유형 다시 추가해 주세요.',
        deleteAll: '모두 삭제',
        sitePlaceholder: '여러 매체를 동시에 등록하는 경우, 매체 계정명(혹은 매체 사이트)을 「;」로 구분해 주세요.예) Media _ A;Media _ B',
        mediaPlaceholder: 'Supports adding accounts in batches for selected media accounts. Please enter account id, separated by semicolons.',
        sellerPlaceholder: 'Supports adding Seller ID in batches for selected accounts. Please enter Seller ID, separated by semicolons.',
        creativeIdPlaceholder: 'Content, separated by two semicolons.',
        cancel: '취소',
        confirm: '확인',
        operationReport: '조작 로그',
        operationTime: '조작 시간',
        userName: '조작계정',
        actionDetails: '조작 내용',
        USA: '미국',
        Canada: '캐나다',
        UnitedKingdom: '영국',
        Australia: '호주',
        Japan: '일본',
        Korea: '대한민국',
        Taiwan: '대만',
        Indonesia: '인도네시아',
        Singapore: '싱가포르',
        Malaysia: '말레이시아',
        Thailand: '태국',
        HongKong: '홍콩',
        from: '에서',
        to: '로',
        tips1: '본 OCPC 설정은 캠페인 만들때의 입찰 OCPC랑 연동되어 주의해 주세요',
        tips2: '1) 캠페인 입찰이 OCPC라면 광고주측에서 인식할 수 있음으로 수정 시 주의해 주세요',
        tips3: '2) 캠페인 입찰이 고정 입찰인 경우, 이 OCPC 설정은 광고주측에서 인식할 수 없습니다. 광고주측에서 고정입찰을 OCPC로 수정하면 이 목표 CPA가 OCPC의 목표 CPA로 자동으로 적용됩니다',
        // 通知中心
        notificationCenter: '알림 센터',
        createNotification: '알림 발송',
        notificationTitle: '제목',
        recipients: '받는사람',
        noticeDate: '날짜',
        notificationContent: '내용',
        confirmNotice: '확인',
        previousNotifications: '발송된 알림',
        sender: '보낸 사람',
        submittedTime: '제출시간',
        dataRange: '기간',
        notificationStatus: '상태',
        changeStatusTip: '상태를 「OFF」로 설정하면 다시 「ON」로 변경할 수 없습니다',
        msgTitleRequired: '타이틀을 입력해 주세요',
        msgRecipientRequired: '받는 사람 선택해 주세요',
        msgLanguageRequired: '언어 선택해 주세요',
        msgContentRequired: '내용을 입력해 주세요',
        msgTipsTitle: '[주의]',
        msgStatusChangeTips: '상태를 「OFF」로 설정하면 다시 「ON」로 변경할 수 없습니다.\n이 알림을 「OFF」로 설정하시겠습니까?',
        close: '닫다',
        delete: '읽음',
        noMessage: '새로운 알림 없음',
        moreMessage: '모든 알림',
        toNoticeList: '알림 목록으로 돌아가기',
        noticeDetail: '알림 내용',
        closeDetail: '돌아가기',
        noticeListTitle: '알림 목록'
    },
    pixel: {
        companyName: '컴퍼니 네임',
        selectDimensionTip: '계정/회사를 선택하십시오.',
        accountPlaceholder: '해당되는 계정을 선택하십시오.',
        companyPlaceholder: '해당되는 회사를 선택하십시오.',
        pixelCreateTipHeader: '선택한 계정/회사에 초기 Pixel, MCV Event Pixel 및 CV Event Pixel을 구성하여 전환 추적하기',
        pixelCreateTipContent: '하나의 계정에 픽셀을 설치하려면 계정 이름을 선택하십시오. 한 회사 주체 아래 여러 계정에 픽셀을 설치해야하는 경우 회사 이름을 선택하십시오. 코드를 여러 번 설치할 필요가 없습니다.',
        createRead1: '다음 내용을 복사하여 </body> 탭 앞에 놓으세요.',
        createRead2: '태그를 설정한 후 작업을 검사할 것이며 캠페인 시작 전 최소 영업일 2일 전에 계정 관리자에게 문의하시기 바랍니다.',
        createRead3: 'HTML 코드 삽입',
        getREADCode: 'READ 코드 가져오기',
        codeType: '코드 유형',
        urlBased: 'URL',
        eventBased: '이밴트',
        type: '타입',
        accountName: '계정명',
        pixelName: '초기 픽셀 코드 이름',
        conversionType: '타입',
        status: '상태',
        includeInTotalConversion: '중요 CV',
        lastReceived: '마지막 수신',
        lastModified: '최종갱신일',
        eventName: '이밴트명',
        category: '카테고리',
        optionalVal: 'CV수치',
        optionalValAppend: '전환가격과 같은 정보를 전환에 추가하는 데 사용됩니다.',
        conversionWindow: 'CV Window',
        isIncludeTatalConversion: '중요CV',
        isExcludeCampaigns: '모든 캠페인에서 광고 타깃 제외',
        urlBasedTip: 'URL 기준: 사용자가 URL의 특정 조건에 부합하는 사이트에 방문할 때마다 추적합니다.',
        pixelReadTip: '만약 전환 경로가 다음과 같은 경우<br>광고 클릭 --> 콘텐츠 페이지 --> 제품 페이지(광고주 웹사이트) --> 감사 페이지(전환)<br> ★다음 도메인을 입력하고 모든 페이지를 초기화 코드로 심어야 합니다<br>1. 콘텐츠 페이지 도메인<br>2. 제품 페이지 도메인',
        eventBasedTip: '이벤트 기반: js 코드를 사용하여 특정 행위를 완료할 때마다 추적합니다. 예: 장바구니에 상품 추가',
        urlCondition: 'URL 조건',
        urlConditionAppend: 'URL이 다음 조건 중 하나 이상을 충족할 때 송출됩니다.',
        conversionProperties: '전환 속성',
        conversionName: '전환명',
        conversionNameAppend: '전환데이터를 쉽게 구별할 수 있도록 고유의 이름을 사용하세요.',
        conversionCategory: '전환 카테고리',
        conversionCategoryAppend: '이 전환이 속한 카테고리를 선택해 주세요',
        importantConversion: '중요 전환',
        importantConversionTipTitle: 'YES를 선택한 경우',
        importantConversionTip1: '대시보드등 전환데이터에 포함됩니다.',
        importantConversionTip2: '이 부분의 전환데이터에 따라 로직이 최적화됩니다.',
        eventCategory: '이벤트 카테고리 선택',
        eventCategoryAppend: '전환을 체크할 이벤트 카테고리를 선택해주세요.',
        embedHtmlCode: '삽입된 HTML 코드 추적',
        embedHtmlCodeAppend: '대상 이벤트 코드가 가동되었을 때 관련 html에 이벤트 코드를 추가합니다.',
        pixelInitialCode: '픽셀 초기 코드',
        pixelInitialInfo: 'Phase 2: 이 코드를 랜딩 페이지 및 리디렉트 페이지의 html 코드의 head 태그에 심어 주세요.<br> 초기 픽셀을 추가하는 것이 전환 추적을 설치하기 위한 첫 번째 단계이며, 전환 추적을 설치하려면 새 전환 버튼을 클릭하시고 안내를 따라주세요',
        copy: '클립보드로 복사',
        create: '새로운 CV',
        condition: '조건',
        or: 'OR',
        newCondition: '신규조건',
        newConditionAppend: '방문이 변환으로 계산될 대상 페이지의 URL 또는 URL 일부를 입력합니다.',
        confirmCreate: '전환 만들기',
        initPixel: 'MediaGo 초기 픽셀 보기',
        mcvCvOther: 'MCV/CV/기타',
        initPixelTab: '초기 픽셀',
        initPixelPopin: '查看Discovery Initial Pixel',
        viewCode: '코드 보기',
        conversionEdit: '전환 편집',
        step1: '스탭1',
        step1Desc: '픽셀 초기 코드 추가',
        step1Desc1: '(초기 코드를 이미 추가한 경우 이 단계를 건너뛰세요)',
        step2: '스탭2',
        step2Desc: '타입을 선택',
        step3: '스탭3',
        step3Desc: '전환 속성',
        step4: '스탭4',
        pixelInitialInfo1: 'Phase 2: 이 코드를 랜딩 페이지 및 리디렉트 페이지의 html 코드의 head 태그에 심어 주세요',
        headerCode: 'Phase 1: 랜딩 페이지 및 리디렉트 페이지의 도메인을 입력하세요',
        headerCode1: '(※광고 경로가 서로 다른 도메인이 있는 경우 "줄 바꿈"으로 해 주세요. 모든 경로의 도메인명을 입력하세요)',
        pixelInitialTitle1: 'Phase 1: 랜딩 페이지 및 리디렉트 페이지의 도메인을 입력하세요<br>(※광고 경로가 서로 다른 도메인이 있는 경우 "줄 바꿈"으로 해 주세요. 모든 경로의 도메인명을 입력하세요)',
        expandCode: '확장 및 생성 및 초기 코드 복사',
        // new pixel conversions
        createNewPixel: '새 픽셀 추가',
        howInstallPixel: '픽셀 설치 방법？',
        downloadDoc: '파일 다운로드',
        pixelCreateSubTitle: '선택한 계정에서 전환 추적을 위해 초기 픽셀, MCV 이벤트 픽셀 및 CV 이벤트 픽셀을 추가합니다',
        selectAccount: '계정 선택',
        configInitPixel: '초기 픽셀 설정',
        addPixelCode: '코드 추가',
        mcvEventPixel: 'MCV 이벤트 픽셀',
        hasConfigText: '추가됨: ',
        pixelNumUnit: '',
        cvEventPixel: 'CV 이벤트 픽셀',
        otherEventPixel: '기타 이벤트 픽셀',
        addInitialPixel: '초기 픽셀 추가',
        initCodeTips: '*초기 픽셀이 전체 광고 홈페이지 맨 위 <head>에 정확하게 설치해 주세요.주 도메인을 입력해 주세요. 도메인명 여러개 있는 경우, 줄 바꿈으로 모든 주 도메인을 입력해 주세요',
        pixelCodeTips: '*초기 픽셀이 전체 광고 홈페이지 맨 위 <head>에 정확하게 설치해 주세요.주 도메인을 입력해 주세요.',
        pixelPlaceholder: '광고 링크가 LP1, LP2 및 OP의 도메인명은 각각 a.com, b.com, c.com 인 경우, 다음과 같이 입력해 주세요\na.com\nb.com\nc.com',
        getInitialPixel: '초기 픽셀 생성',
        editUpdateText: '편집',
        initPixelDone: '초기 픽셀 코드 추가를 완료했습니다',
        saveAndCopy: '복사 및 저장',
        setMcvEventPixel: 'MCV 이벤트 픽셀 설정',
        mcvEventTips: '*MCV 이벤트 픽셀이 첫 번째 랜딩 페이지의 다음 페이지 html 코드의 헤드 태그에 정확하게 설치해 주세요',
        getMcvEventPixel: 'MCV 이벤트 픽셀 생성',
        copyMcvPixelTips: '*이 코드는 첫 번째 랜딩 페이지의 다음 페이지 html 코드의 머리 태그에 설치해 주세요',
        mcvPixelDone: 'MCV 이벤트 픽셀 코드 추가를 완료했습니다',
        setCvEventPixel: 'CV 이벤트 픽셀 설정',
        cvEventCodeTips: '*프로모션 목표가 달성된 페이지나 버튼에 CV 이벤트 픽셀이 정확하게 설치해 주세요',
        getCvEventPixel: 'CV 이벤트 픽셀 생성',
        copyCvPixelTips: '*이 코드를 프로모션 목표가 달성된 페이지나 버튼에 설치해 주세요',
        cvPixelDone: 'CV 이벤트 픽셀 코드 추가를 완료했습니다',
        setOtherEventPixel: '다른 이벤트 픽셀 설정',
        otherEventTips: '*이벤트 픽셀은 해당 이벤트가 트리거되는 html에 설치해 주세요',
        copyOtherPixelTips: '*이 코드를 해당 이벤트가 트리거되는 html에 설치해 주세요',
        getOtherEventPixel: '다른 이벤트 픽셀 생성',
        otherPixelDone: '다른 이벤트 픽셀 추가를 완료했습니다',
        trackingCodeTips: '*추적 코드 추적 코드를 해당 이벤트가 트리거되는 html에 추가해 주세요',
        manageExistPixel: '기존 픽셀 관리',
        initPixelTips: '광고의 모든 페이지에 있는 html 코드의 </head> 태그에 초기 픽셀을 설치해 주세요',
        mcvPixelTips: '두 번째 랜딩 페이지 html 코드의 </head> 태그에 MCV 이벤트 픽셀을 설치해 주세요',
        cvPixelTips: '프로모션 목표가 달성된 페이지나 버튼에 CV 이벤트 픽셀을 설치해 주세요',
        hasDeprecated: '（유효하지 않음）'
    },
    // 权限部分
    permissionMgr: {
        accountId: '계정ID',
        accountStatus: '계정상태',
        accountName: '계정명',
        companyName: '회사명',
        type: '회사타입',
        email: '계정 소유자의 이메일',
        payMethod: '결제방법',
        adMethod: '입금방법',
        timezone: '시간대',
        createAccount: '새로운계정',
        inviteLink: '계정개설초대링크',
        copyLink: '초대 링크 복사',
        dailyCmpCount: '계정 API 단일 일 캠페인 생성 상한',
        dailyCmpCount2: '',
        openDailyCmpCount: 'Open Account API Single-Day Creation Campaign Upper Limit',
        dailyLimit: '활성화될 때, 계정은 API를 통해 하루에 500개의 캠페인을 생성할 수 있으며, 단일 CPN당 최대 10개의 asset를 생성할 수 있습니다.',
        roleMgr: {
            detail: '상세',
            edit: '권한관리',
            del: '삭제'
        }
    },
    permission: {
        notAuthorizeTip: '어드민이 아닌 역할은 이 기능을 부여받을 수 없습니다.',
        p1: '계속하려면 MediaGo의 개인 정보 보호 정책 및 약관에 동의하세요.',
        password: '패스워드',
        newPassword: '새로운패스워드',
        timeZone: '시간대',
        inputVCode: '인증코드',
        vCode: '인증코드를 보내기',
        forgotPasswordInputVCode: '인증코드',
        forgotPasswordVCode: '인증코드를 보내기',
        title: '광고주계정 생성',
        subtitle: '스텝 2개 완료하면 플랫폼을 이용할 수 있습니다.',
        userId: '유저 ID',
        accountType: '계정타입',
        quizz1: '당신의 회사는 광고계정을 가지고 있습니까?',
        yes: '네',
        no: '아니요',
        quizzyes: '2. "이메일 보내기" 버튼을 클릭하여 권한을 신청하세요.',
        quizzno: '2. "이메일 보내기" 버튼을 클릭하여 계정생성 및 권한을 신청하세요.',
        sendEmail: '메일 보내기',
        email: {
            title: '【사용자 어플리케이션】',
            divider: '===============================================================',
            title1: '광고 계정 응용프로그램 및 사용자 응용 프로그램',
            bodyTitle1: 'MSN 이용자의 응용프로그램용 MediaGo',
            bodySubtitle1: '모든 항목을 기입해주세요.',
            bodyTitle2: 'MSN용의 MediaGo AD계정 응용 프로그램및 사용자 응용 프로그램',
            bodySubtitle2: '모든 항목을 기입해주세요.',
            account: {
                title: '[광고 계정 응용 프로그램]',
                company: '*회사명',
                registerAddr: '*회사주소',
                operationAddr: '*청구지주소',
                type: '*회사종류',
                officialSite: '*회사의 웹사이트',
                position: '직책',
                owner: '*AD계정 관리자 E메일주소 :',
                ownerInfo: '(메일주소를 입력해주세요)',
                accountNum: '계정 수 : 1 개',
                accountNumInfo: '( 1개의 광고계정만 적용됩니다.)',
                accoutName: '*AD 계정명 :',
                totalCap: '*총예산 : $',
                totalCapInfo: '(통화 : USD)',
                chargeType: '*지불 방법 :',
                chargeTypeInfo: '( 선불 또는 송금)',
                manner: '계좌종류',
                mannerInfo: '(셀프서비스/관리)'
            },
            permission: {
                title: '【사용자 어플리케이션】',
                name: '*닉네임',
                nameInfo: '(소속한 회사에서의 포지션, 성함을 입력해주세요. 예 : Dun Ying- pm)',
                userId: '* 유저ID',
                accountType: '*유저 계정의 종류',
                userType: '*유저타입',
                userTypeInfo: '(광고주/대행사)',
                email: '*유저의 E메일주소 :',
                emailInfo: '(메일주소를 입력해주세요)',
                company: '*회사명 :',
                adAccount: '*광고 계정명 :',
                adAccountInfo: '(당신이 소속되어있는 회사의 계정명을 적어주세요)'
            },
            result: {
                info: '신청해주셔서 감사드립니다.',
                info1: '빠르게 회신하도록 하겠습니다.',
                footer1: '질문이 있으시면 이쪽으로 연락주시길 바랍니다.',
                footer2: '되도록 빠르게 회신하도록 하겠습니다.'
            }
        },
        emailReport: {
            create: '자동보고',
            status: '상태',
            acountName: '계정명',
            timeZone: '리포트 보고 시간대',
            recivieName: '수신자',
            frequency: '송신빈도',
            closeTips: '종료후에는 메일로 송신을 정지하기.',
            zoneTips: '계정의 보고 시간대',
            emailFormat: '복수의 메일을 , 로 나눠서 입력할 수 있습니다.',
            day: '매일',
            month: '매월'
        },
        formTitle: 'MediaGo계정을 적용하기',
        formDesc: '다음 정보를 입력해주세요, 플렛폼의 권한을 받을수 있게 되도록 빠르게 연락드리겠습니다.',
        formAppend: '[적용]을 클릭하면 MediaGo에 동의하게 됩니다.',
        formAppendLink: '프라이버시 계약',
        // country: '나라',
        country: '지역',
        currency: '통화',
        firstName: '이름',
        lastName: '성',
        formEmail: '메일주소',
        formEmailTip: '이것은 당신의 유저명으로 사용됩니다.',
        submitProtocol: '네. 캠페인을 개선하기 위한 조언을 보내주세요.',
        timeZoneTip: '캠페인을 관리하는 보고시간대를 선택해주세요. 설정하면 변경하실수 없습니다.',
        currencyTip: '결제통화를 선택해주세요. 한번설정하면 변경하실 수 없습니다.',
        organizationName: '기업명',
        companySite: '회사의웹사이트',
        jobTitle: '직책',
        phone: '전화번호',
        companyNameandtips: '회사 등록 이름(등록 시 기입)',
        apply: '당일시작',
        createAccount: '계정생성',
        tologinText: '계정이 이미 있으신가요?'
    },
    openAccount: {
        stepOne: '광고 계정 응용 프로그램',
        stepTwo: '사용자 계정 그룹화',
        stepThree: '신청이 완료되었습니다.',
        workEmailTip: '유저명으로 사용됩니다.',
        billingEmail: '청구 메일',
        billingEmailSymbol: '필요시 /;/ 이것을 사용해주세요',
        billingEmailLength: '1000자 이하',
        billingEmailTip: '청구서를 받을 계정으로 등록할 메일주소를 입력해주세요. 복수 선택하는 경우에는 ; 로 사용해주세요',
        accountTitle: '광고 계정 응용 프로그램',
        accountTitleDesc: '이하의 정보는 계정 응용 프로그램에서만 쓰이며, 메세지는 공개되지않을것을 약속드립니다.',
        accountTitleDesc1: '입력된 정보는 광고계정어플리케이션으로만 사용됩니다.',
        currency: '통화',
        // 币种提示
        currencyTitle: '결제할 통화를 선택해주세요. 선택후에는 변경 불가능 합니다.',
        // 交易币种提示
        tradingCurrencyTitle: '거래화 폐종류 선택해주세요. 선택후에는 변경 불가능 합니다.',
        // 合同提示
        contractualTip: '계약 대상을 입력하세요.',
        accountTitleDesc2: '이것은 과금의 직적접인 영향을 주게되며, 공식의 등록문서가 반영도도록 회사의 상세정보를 신중/정확 하게 입력해주세요.',
        accountName: '광고계정명',
        accountEmail: '계정관리 이메일주소',
        totalBudget: '총예산',
        payMethod: '결제방법',
        prepayment: '선결제',
        delivery: '현금송금',
        timezone: '보고 시간대',
        adMethod: '계좌종류',
        selfService: '셀프코너',
        managed: '대리',
        companyName: '기업명',
        registerAddr: '등기주소',
        operateAddr: '회사주소',
        website: '웹사이트',
        userTitle: '사용자 계정 그룹화',
        userTitle1: '유저 계정신청',
        userTitleDesc: '복수의 유저계정을 AD계정으로 그룹화 할수 있습니다. MediaGo사용자 계정을 추가하거나 등록 하려면 다음 정보를 입력해주세요.',
        addExistAccount: '기존유저의 계정을 추가 합니다.',
        registAccount: '새로운 유저의 계정을 등록해주세요',
        // country: '나라',
        country: '지역',
        firstName: '이름',
        lastName: '성',
        email: '회사용 메일주소',
        jobTitle: '직책',
        phone: '전화번호',
        submitAppend: '[적용]을 누르면 MediaGo에 동의하게 됩니다.',
        privacyLink: '개인정보 보호 정책',
        terms: '자귀',
        logout: '로그 아웃',
        del: '삭제',
        add: '추가',
        apply: '요청',
        formAppendLink: '개인정보 보호 정책',
        info: '신청해주셔서 감사드립니다. 빠르게 회신드리도록 하겠습니다.',
        info1: '되도록 빠르게 연락드리겠습니다.',
        footer1: '궁금하신점이 있으시면 이쪽으로 연락주세요.',
        footer2: '되로록 빠르게 회신드리겠습니다.',
        invalidToken: '제공된 링크는 무효합니다. 정확한 링크를 취득하기위해 AM과 여락을 취해주세요.',
        inviteMsg: 'MediaGo의 계정을 개설하는것을 추천합니다.'
    },
    validations: {
        campaignObjective: '광고 목표 입력해주세요',
        couponAmount: '请输入需要设置的Coupon金额',
        effectiveTime: '请选择生效时间',
        validNumber: '이곳에 유효한 번호를 입력해주세요.',
        validDecimal: '소수점은 세자릿수까지만 표시됩니다',
        simpleRequired: '필수',
        jobTitle: '유효한 직책을 기입해주세요',
        firstName: '유효한 이름을 입력해주세요',
        lastName: '유효한 성을 입력해주세요',
        required: '이항목은 필수 입니다.',
        number: '이곳에 유효한 번호를 입력해주세요.',
        money: '유효한 금액을 입력해주세요',
        moneyNum: '목표입찰금액을 수치로 입력해주세요.',
        moneyJpy: '데일리 총예산을 수치로 입력해주세요.',
        moneyNumJpy: '목표금액은 수치로 입력해주세요.',
        moneyTotalJpy: '총예산은 수치로 입력해주세요.',
        numberBeyond: '1,000,000,000을 넘을 수 없습니다.',
        numberBeyondJpy: '최저 일총예산은 ￥2,000/일 입니다.',
        dailyCapBeyond: '최저 일총예산은 {money}/일 입니다.',
        cpcBeyond: '광고효과의 최적화를 위해 목표 입찰은 {money} 이상 설정해주세요.',
        checkCurrencyMaxCPC: '캠페인 활성을 극대화하기 위해 목표 가격은 {money} 이상이어야 합니다',
        // numberBeyondJpy: '최저 일총예산은 ￥2,000/일 입니다.',
        numberBeyond1: '1,000,000을 넘을 수 없습니다.',
        dailyOverTotal: '일의 총예산은 전체기간의 총예산보다 높은 금액으로는 설정할 수 없습니다.',
        dailyOverTotal2: '일예산은 총예산보다 높게 설정할 수 없습니다.',
        dailyOverTotal3: '총예산은 일예산보다 낮게 설정할 수 없습니다.',
        dailyOverTotal4: '최저 일예산은 $20/일 입니다.',
        dailyOver20: '최저 일예산은 $20/일 입니다.',
        lifetiemUnderDaily: '총예산은 일예산보다 낮게 설정할 수 없습니다.',
        targetBidOverDaily: '목표입찰금액은 총일예산보다 높게 설정할 수 없습니다.',
        targetBidOverDailyJpy: '목표입찰금액은 수치로 입력해 주세요.',
        targetBidOverDaily2: 'CPC는 일예산보다 높게 설정할 수 없습니다.',
        startAfterEnd: '종료시각은 시작시간보다 빠르게 설정 할 수없습니다.',
        start: '시작시간을 설정해 주세요.',
        end: '종료시간을 설정해 주세요.',
        endBeforeStart: '종료시간은 시작시간 또는 현재시간보다 빠르게 설정할 수 없습니다.',
        status: '상태를 입력해주세요.',
        daily: '일 총예산은 필수 입니다.',
        minLimit: '单日预算不能少于￥2,000/天',
        lifetime: '총예산은 필수입니다.',
        targetBid: '타겟입찰금은 필수 입니다.',
        image: '적절한 이미지를 업로드 해주세요.',
        imageRevise: '새로운 적절한 이미지를 업로드 해주세요.',
        imageInfo: '여기에 {scale}px 이미지를 업로드 해주세요.',
        size: '사이즈가 필요합니다.',
        http: 'http:// 또는 https://로 시작되는 유효한 사이트를 입력해 주세요.',
        charLenth: '250자 이하',
        charLen: '{unm}자 이하',
        revise: '필수 필터를 수정해주세요.',
        name: '이름을 입력해주세요',
        platform: '플랫폼이 입력해주세요',
        os: 'OS를 입력해주세요.',
        browser: '브라우저를 입력해주세요.',
        adFormat: '광고포멧을 입력해주세요.',
        placement: '배치를 입력해주세요.',
        audience: '광고 타깃을 입력해주세요.',
        industry: '카테고리를 입력해주세요.',
        location: '지역을 입력해주세요.',
        zipcode: '우편번호를 입력해주세요.',
        brandName: '브랜드명을 입력해주세요.',
        headline: '타이틀을 입력해주세요.',
        adAssetName: '소재명을 입력해주세요.',
        imageValidations: 'Image is required',
        adAssetNameNotRepeat: '소재명이 중복되었습니다.',
        landingPage: 'LP를 입력해주세요.',
        impURL: '뷰트레킹픽셀을 입력해주세요.',
        landingPageError: 'LP에 스페이스를 포함할 수 없습니다.\ \'、\'、복수\ \'？',
        noAds: '이 계정다음에 검수를 기다리고있는 광고는 없습니다.',
        skipInfo: '저장하지않은 변경내옹이있어 이페이지를 건너뛰면 변경내용이 손실 됩니다.',
        leaveInfo: '저장하지 않은 변경 사항이 있으며 이 페이지를 나가면 손실됩니다.',
        reviewNotComplete: '불안전한 심사',
        advertiser: '광고주 계정을 선택해주세요.',
        utmParams: '추적 URL은 ?, @ , / , 스페이스 줄바꿈을 포함할 수 없습니다.',
        picDuplicated: '동일한 이미지를 업로드하지 말아주세요.',
        // 登录
        email: '유효한 메일주소를 입력해주세요',
        organizationName: '기업명이 필요합니다.',
        companySite: '회사의 웹사이트가 필요합니다.',
        phone: '전화번호가 필요합니다.',
        switchLang: '언어를 바꾸면 입력한 내용이 사라질수도 있습니다. 바꾸시겠습니까?',
        confirmToSwitch: '您有尚有未保存的编辑内容，是否保存更改以继续？',
        confirmTip: '发布操作不可取消，请点击确认按钮以继续。',
        saveTip: '是否保存当前操作？请点击相应按钮以继续。',
        cacheNewCampaign: '캠페인의 편집이 중단된것이 있습니다, 중단했던 부분을 편집하겠습니까?',
        cacheContinue: '편집을 계속하기',
        cacheAgain: '새로만들기',
        cacheSaveNewCampaign: '이페이지에서 나가면 캠페인이 중단되어 중간저장됩니다. 이후에 계속 편집할 수 있습니다.',
        fileParse: 'ファイル解析中、送信ボタンをクリックしてください。5 ~ 15分後に新しいダッシュボードでクリエイティブが正常に作成されたか確認できます。',
        pwd: '문자, 숫자 및 기호가 혼합된 8자 이상 사용',
        pwdNotMatch: '입력한 2개의 패스워드가 일치하지 않습니다.',
        relation: '필터조건을 선택해주세요.',
        pwdRequired: '유효한 패스워드를 입력해주세요.',
        // open account
        company: '유효한 회사명을 입력해주세요.',
        companyName: '유효한 회사명을 입력해주세요.',
        account: 'AD계정명이 필요합니다.',
        budget: '예산을 입력해주세요',
        registerAdd: '유요한 회사주소를 입력해주세요.',
        registerAddress: '유효한 기업주소를 입력해주세요.',
        businessAddress: '유효한 회사용메일주소를 입력해주세요.',
        timezone: '계정 표준시간대를 입력해주세요',
        // country: '나라정보가 필요합니다',
        country: '지역정보가 필요합니다',
        payMethod: '결제방법이 필요합니다',
        adMethod: '송출방법이 필요합니다',
        vCodeNeed: '정확한 인증코드를 입력해주세요',
        // 权限
        noUser: '유저에게 권한이 없습니다.',
        errorCampaign: '죄송합니다. 당신에게는 권한이 없습니다,',
        errorUploadImgSize: '업로드된 이미지 사이즈는 500K를 넘었습니다!',
        blackWhiteType: '블랙리스트야 화이트리스트를 선택해주세요.',
        sspRequire: '매체 계정 를 선택해주세요',
        // pixel
        accountRequired: '계정명이 필요합니다.',
        conversionNameRequired: '변환값이 필요합니다.',
        categoryRequired: '카테고리가 필요합니다.',
        valueRequired: '변환값이 필요합니다.',
        eventTypeRequired: '이벤트 카테고리가 필요합니다.',
        repeatEmail: '이 메일주소는 이미 사용중입니다. 다른 메일주소를 입력해주세요.',
        emailNotExist: '메일주소가 존재하지 않습니다.',
        selectUser: '유저계정이 필요합니다.',
        cpcInfo: '소수점은 세자릿수까지만 표시됩니다',
        cpcJpyInfo: '\'Target Bid\' must have no more than 1 decimal positions',
        targetCpaInfo: '소수점은 두자릿수까지만 표시됩니다',
        cpcInfoTarget: '타겟입찰은 변경되지않았습니다.',
        cpcNumber: '타겟입찰은 수치여야 합니다.',
        invalidFile: '우편번호를 추가할 수없습니다. 템플릿에 따라 올바른 CSV파일을 업로드 해주세요',
        sensitiveTag: 'Sensitive Tag가 필요합니다',
        cpcPercentMin: '输入框数值不能低于-99',                             // zhangpeng    以下
        cpcNumberInteger: '请输入整数',
        currencyCheck: '请选择账户结算币种',
        tradingCurrencyCheck: '请选择账户交易币种',
        legalRepresentativeCheck: '다음 옵션 중 하나를 선택하세요',
        contractCheck: '请选择账户合同主体',
        targetCpa: '请输入第二阶段目标CPA',                         //  zhangpeng    以上
        targetMax: '정수 입력만 지원하며, 최소 입력 값은 100이다.',
        cpcmin: '정수 입력만 지원하며, 최소 입력 값은 10이다.',
        cpcmax: '정수 입력만 지원하며, 최소 입력 값은 100입니다. 150% 이상의 비율을 권장합니다.',
        cpcmaxup: '최대 값은 최소 값보다 커야 한다.',
        cpatype: 'cpa 형식을 선택하십시오.'
    },
    currencySpecies: {
        dollar: 'USD（$）',
        bahtDollar: 'THB（฿）',
        japaneseDollar: 'JPY（￥）',
        RMB: 'CNY（CNY ￥）',
        taiwanDollar: 'TWD（NT$）',
        HKDollar: 'HKD（HK$）',
        singaporeDollar: 'SGD（S$）',
        wonDollar: 'KRW（₩）',
        malaysianRupiah: 'MYR（RM）',
        indonesianRupiah: 'IDR（Rp）'
    },
    infos: {
        info: '힌트',
        total: '개요',
        emptyText: '데이터 없음',
        emptyReadChartText: 'READ태그가 설치되여있지 않거나 기간내의 데이터가 존재하지 않기때문에 표시되지 않습니다.',
        allSelect: '모두',
        selected: '선택',
        dragTip: '(재주문을 위해 드래그하기)',
        conversionTip: '변환데이터 {}를 동기화하려면 MediagoPostBack 링크를 사용해주세요. 또는 Mediago픽셀, 를 설정하기 위한 \'pixel \' 네비게이션바로 이동합니다{}.변환 데이터는 2시간 후에 플랫폼에서 갱신됩니다.',
        DiscoveryConversionTip: '변환데이터 {}동기화하려면 DiscoveryPostBack 링크를 사용해주세요. 또는 Discovery픽셀, 를 설정하기 위한 \'pixel \' 네비게이션바로 이동합니다{}.변환 데이터는 2시간 후에 플랫폼에서 갱신됩니다.',
        conversionTipGo: '다음페이지를 확인',
        conversionTipMore: '상세하게보기',
        postBack: '포스트백 코드',
        sessionInvalid: '시스탬이 갱신되었습니다. 페이지를 새로고침 해주세요',
        fileSizeLimit: '광고 효과를 최적화하기 위해 이미지 해상도는 600*400픽셀 이상을 권장합니다.'
    },
    constants: {
        all: '전부',
        cancel: '취소',
        selectAll: '전부',
        eq: '동일',
        gt: '보다 많은',
        lt: '미만',
        ge: '()보다 많은',
        le: '()에 미치지않는',
        platform: '플렛폼',
        os: 'OS',
        browser: '브라우저',
        smartphone: '스마트폰',
        mobile: '모바일',
        desktop: '데스크탑',
        tablet: '테블릿',
        xbox: 'Xbox',
        android: 'Android',
        ios: 'iOS',
        macOs: 'Mac OS',
        windows: 'Windows',
        linux: 'Linux',
        chrome: 'Chrome',
        firefox: 'Firefox',
        safari: 'Safari',
        ie: 'Internet Explorer',
        samsung: 'Samsung',
        opera: 'Opera',
        uc: 'UC Browser',
        inApp: 'InApp',
        edge: 'Edge',
        est: 'EST',
        utc0: 'UTC+0',
        utc7: 'UTC+7',
        utc8: 'UTC+8',
        usdname: 'USD',
        jpyname: 'JPY',
        cnyname: 'CNY',
        sgdname: 'SGD',
        myrname: 'MYR',
        krwname: 'KRW',
        idrname: 'IDR',
        thbname: 'THB',
        twdname: 'TWD',
        hkdname: 'HKD',
        utc9: 'UTC+9',
        standard: '표준',
        accelerate: '가속',
        other: '그외',
        daily: '매일',
        hourly: '매시간',
        earlier: '보다 빨리',
        later: '보다 늦게',
        at: 'ON',
        white: 'White',
        black: 'Black',
        msn: 'MSN',
        mgid: 'MGID',
        // 合同主体
        mediago: 'mediago',
        jpSubject: 'jp.popin.cc',
        krSubject: 'kr.popin.cc',
        twSubject: 'tw.popin.cc',
        docomo: 'docomo',
        au: 'au',
        softbank: 'softbank',
        male: '남성',
        female: '여성',
        // 受众
        car: '자동차',
        election: '선거',
        entertainment: '엔터테인먼트',
        money: '돈',
        finance: '금융',
        foodDrink: '음식&음료',
        homepage: '홈페이지',
        health: '건강',
        lifestyle: '라이프스타일',
        music: '음악',
        movies: '영화',
        news: '뉴스',
        sports: '스포츠',
        travel: '여행',
        TV: 'TV',
        video: '비디오',
        weather: '날씨',
        education: '교육',
        gadgetsAutos: '자동차',
        business: '비지니스',
        beautyFashion: '미용&패션',
        economyMoney: '경제&돈',
        homeLifestyle: '집&생활',
        loveMarriage: '사랑&결혼',
        politicsSocial: '정치&사회',
        travelGourment: '관광&맛집',
        autoselectronics: '자동차 및 전자 제품',
        // 广告分类
        auto: '자동차',
        beautyFitness: '미용&피트니스',
        beautyHairCare: '미용&헤어케어',
        businessAndFinance: '비지니스&금융',
        careers: '커리어',
        computerElectronics: '컴퓨터&가전제품',
        eCommerce: 'E커머스',
        eventsAndAttractions: '이벤트&명소',
        familyAndRelationships: '가족&관계',
        financeInsurance: '금융&보험',
        food: '음식',
        gaming: '게임',
        Gaming: '게임',
        healthcare: '건강관리',
        homeGarden: '집&정원',
        newsAndPolitics: '뉴스&정치',
        pets: '펫',
        personalCareOralCare: '개인관리&구취관리',
        popCulture: '팝 컬처',
        realEstate: '부동산',
        religionSpiritualty: '종교&영성',
        science: '화학',
        travelHospitality: '여행&관광',
        weightLoss: '다이어트',
        wellness: '건강',
        others: '기타',
        content: '콘텐츠',
        leadGen: '잠재고객',
        search: '검색',
        insurance: '보험',
        recruitment: '채용',
        marriage: '결혼',
        medicalIndustry: '의료 산업',
        diet: '다이어트',
        beautyCosmetic: '화장품',
        homeAppliances: '가전제품',
        computer: '컴퓨터',
        communication: '커뮤니케이션',
        media: '매체',
        game: '게임',
        physicalEducation: '스포츠',
        beautyCosmetics: '아름다움',
        industry: '카테고리'
    },
    requestMsg: {
        requestError: '네트워크 에러가 발생했으니 재시도해주세요.',
        exportError: '데이터 내보내기에 실패했습니다. 다시시도해 주세요',
        exportSuccess: '데이터 내보내기가 정상적으로 되었습니다.',
        statusUpdated: '정상으로 갱신되었습니다.',
        updatedFailed: '데이터 업로드에 실패했습니다.',
        createError: '작성에 실패했습니다.',
        uploadFailed: '업로드에 실패했으니 재시도해주세요.',
        uploadOverSize: '이미지의 사이즈는 xxxMB를 넘을 수 없습니다.',
        uploadSuccess: '업로드에 성공했습니다.',
        gifSizeInfo: '적절한 사이즈로 새로운 이미지를 업로드 해주세요.',
        logoutSuccess: '정상으로 로그아웃되었습니다.',
        logoutFailed: '로그아웃에 실패했습니다.',
        modified: '패스워드가 정장적으로 리셋되었습니다.',
        loginFailed: '계정명 또는 패스워드가 일치하지 않습니다.',
        copySuccess: '복사되었습니다.',
        previewFirst: '심사화면을 확인해주세요'
    },
    backendMsg: {
        fail: '실패',
        success: '성공',
        sendCodeSucs: '인증코드를 확인해주세요.',
        alreadyExist: '이 메일주소는 이미 사용중입니다. 다른것으로 입력해주세요',
        verificateFail: '정확한 인증코드를 입력해주세요',
        busy: '서버 실행중.',
        noPermission: '권한이없는 조작',
        gifError: 'GIF의 사이즈가 요구사항에 부족합니다. GIF를 새로 업로드 해주세요',
        loginError: '계정 혹은 패스워드가 일치하지 않습니다.',
        registError: '이 메일주소는 이미 등록되어있습니다.',
        repeatCreate: '이미 같은광고가 존재합니다.',
        operateTooMuch: '조작빈도가 너무 높습니다.',
        amountError: '금액은 0이상으로 입력해주세요.',
        accountExist: '이광고의 계정은 이미 존재합니다.',
        companyExist: '법인명이 중복되었습니다.',
        campaignNameExist: '캠페인명이 중복되었습니다.',
        paramsError: '파라미터가 틀립ㄴ다.',
        addRepeat: 'Cannot Add Repeatedly',
        landingpageOrUtmIsInvalid: '랜딩페이지 또는 추적코드가 잘못되었습니다.',
        conversionExist: 'CV명이 이미 존재합니다.',
        requestError: '네트워크 에러가 발생했습니다. 다시 새로 시도해주세요.',
        linkInvalid: '무효한 쿠폰',
        roleExist: '역할명이 이미 존재합니다.',
        noResult: '확인제한시간을 초과되었거나 사서함이 확인코드를 전송하지 않았습니다.',
        ecommercePageNameRepeated: '입력한 페이지명은 이미 존재합니다.',
        ecommerceSeoUrlRepeated: 'SEO URL은 이미 존재합니다.',
        auditTypeNameExist: '审计类型名称已经存在',
        tagExist: '标签已存在',
        readExist: '이 Read 코드가 이미 존재합니다',
        completedDataAccumulation: '이 활동은 데이터가 축적되어 있어 일괄 상속 기능을 사용할 수 없습니다',
        sizeTooLarge: '최대 이미지 크기는 1MB를 초과할 수 없습니다',
        gifSizeTooLarge10: 'GIF 크기는 최대 10MB를 초과할 수 없습니다',
        imgTypeError: 'JPG, JPEG, PNG, GIF 등 이미지 유형만 업로드를 지원합니다'
    },
    // paypal充值
    paymentList: {
        time: '시간',
        id: '결제ID',
        status: '결제상황',
        actualAmount: '실제금액',
        transferFee: '수수료',
        prepaidAmount: '결제금액',
        method: '결제방법',
        payer: 'PayPal계정',
        receipt: '영수증',
        accountName: '광고계좌',
        totalCoupon: '쿠폰',
        couponBalance: '쿠폰잔액',
        totalQuotaTip: '총 잔액= 총 재중전+ 신용한도 +총 쿠폰- 총지출',
        fold: '계정 리스트 접기',
        expand: '계정 리스트 펼치기',
        recharge: '충전',
        autoBilling: '자동충전',
        operate: '상세',
        balanceLess0: '이 계정의 잔고는 0입니다.'
    },
    // stripe支付
    stripeList: {
        status: '상태',
        account: '광고계좌',
        method: '결제방법',
        date: '최종청구일',
        amount: '최종청구량',
        spend: '계정청구',
        card: '결제카드',
        ABAmount: '자동충전금액',
        edit: '편집',
        paymentDetails: '결제정보',
        history: '결제 내역'
    },
    // geoEdge
    geoEdge: {
        type: '트리거 종류',
        time: '시간',
        detail: '상세',
        detailLink: '상세URL'
    },
    // bidAnalysis
    bidAnalysis: {
        adId: '광고ID',
        placementId: '위치ID',
        avgRank: '랭크',
        bidMode: '입찰모드',
        picpm: 'P-ICPM',
        pvcpm: 'P-VCPM',
        pictr: 'P-ICTR',
        pvctr: 'P-VCTR',
        pvwr: 'P-VWR',
        pvctrLevel: 'P-VCTRf레벨',
        pvwrLevel: 'P-VWR레벨',
        cpc: 'CPC',
        campaignId: '캠페인ID',
        title: '광소스타일',
        linkTo: '입찰분석'
    },
    // 电商
    'nativeE-commerceTool': {
        createFormAccountName: '계정명',
        createFormProductName: '상품명',
        createFormSampleLPUrl: '샘플 LP URL',
        createFormParseBtn: '분석',
        createFormPageName: '페이지 명',
        createFormPageNameLength: '300자 이하로 입력해주세요.',
        createFormImages: '이미지',
        createFormImagesBtn: '전체이미지보기',
        createFormreplaceKeyword: '키워드 바꾸기',
        createFormreplaceKeyword1: '전부 바꾸기',
        createFormreplaceKeyword2: '로',
        createFormAddRules: '룰 추가하기',
        createFormMatchCase: '일치한 케이스',
        createFormCTAURL: 'CTA URL.',
        createFormSEOURL: 'SEO URL',
        createFormSeoTagTitle: 'SEO 제목',
        createFormseoDescription: 'SEO 설명',
        createLPBtn: '신규LP를 추가',
        createOPBtn: '신규OP를 추가',
        parseSuccess: '분석성공',
        parseFail: '분석실패',
        searchLPFilter: '검색페이지명',
        searchButton: '검색',
        productMultipleChoice: '단일 제품만 선택 가능',
        coppyOPHTML: 'HTML 복사',
        productChoice: '제품을 선택하세요',
        opCreatedPageName: '페이지 이름을 입력하세요',
        coppyLPLinkButton: '링크를 복사하기',
        selectDomain: '도메인명을 선택해주세요',
        addTemplate: '새 이메일 템플릿 추가',
        searchtemplate: '이메일 템플릿 검색',
        emailTemplateName: '이메일 템플릿 이름',
        status: '상태',
        domain: '도메인',
        emailAddress: '메일주소',
        emailPassword: '패스워드',
        lastModifiedDate: '최종갱신일',
        creationUser: '작성 유저',
        lastModifiedUser: '마지막 수정 사용자',
        operation: '조작',
        domainName: '도메인명',
        templateName: '템플릿 이름',
        conditions: '상태',
        storeLogo: '스토어 로고',
        storeName: '스토어 이름',
        emailTitle: '이메일 제목',
        emailContent: '이메일 내용',
        emailImage: '이메일 이미지',
        emailFooter: '꼬리말',
        addNewEmailTemplate: '새 이메일 템플릿 추가',
        editEmailTemplate: '이메일 템플릿 편집',
        copyEmailTemplate: '이메일 템플릿 복사',
        deleteEmailTemplate: '이메일 템플릿 삭제',
        recommendedSize: '추천 사이즈'
    },
    supplement: {
        bulkUpload: '일괄 업로드',
        regionEurope: '지역:GE、FR、IT、ES、AT',
        editCompany: '회사 편집',
        agentAccount: '대항사 계정',
        selectAgentAccount: '대항사 계정을 선택하세요',
        externalWebsite: '회사 정보를 입력하는 외부 웹사이트 링크',
        change: '수정',
        editAccount: '계정 편집',
        addAccount1: '계정 추가',
        pleaseEnterContent: '내용을 입력하세요',
        openingInformation: '계좌개설 정보',
        agencyPublisher: '선택한 계정이 대행사인 경우 한 번 선택하면 수정할 수 없습니다',
        specifiedSSP: '지정된 SSP',
        operationType: '작업 유형',
        accountManager1: '계정/관리자',
        progressInDescription: '진행 설명',
        descriptionOfCurrentProgress: '현재 진행에 대한 설명',
        currentProgress: '현재 진행 상태에 대한 설명을 입력하세요',
        pleaseSelect: '선택해 주세요',
        quantityAllotted: '할당 수량',
        discoveryOnline: 'Discovery온라인 계약 검색',
        seleced: '선택됨',
        editRemark: '설명 편집',
        bid: '입찰',
        noBid: '입찰 없음',
        fixedBid: '고정 입찰',
        smartBid: '스마트 입찰',
        noAdBid: '심사 대기중인 광고틀 입찰 설정이 없습니다',
        EntNumber: '-1에서 1 사이의 숫자를 입력하세요',
        trainPrice: '입찰 훈련',
        floorPrice: '최소 가격',
        maxPrice: '최대 가격',
        limitCPC: 'CPC 제한',
        atLeast: '최소 한 시간 또는 하루가 필요합니다',
        noCreateAPI: '계정이 API를 생성하기 위한 조건을 충족하지 않습니다',
        eventArea: '이벤트 영역을 선택하세요',
        pleaseenterrecipientemail: '수신자 이메일을 입력하세요',
        billingInformation: '청구 정보',
        cooperationMode: '협력 모드',
        viewRS: 'RS 수정 내역 보기',
        setRS: '새 RS 설정',
        onceEnter: '입력 후 수정할 수 없습니다',
        billingCycle: '청구 주기',
        financialEmail: '재무 이메일',
        sendInvoice: '인보이스를 보내는 데 사용되며 여러 이메일을 추가하려면 ;로 구분하세요',
        mediaInformation: '매체 정보',
        trafficType: '트래픽 유형',
        adTool: '광고 도구',
        addUser1: '사용자 추가',
        userBackground: '사용자 배경',
        authorizationConfig: '권한 설정',
        roleConfig: '역할 설정',
        remenberUser: '사용자가 성공적으로 추가되었습니다. 아래의 사용자 계정 비밀번호를 기록하세요.',
        userName: '사용자 이름',
        userPassword: '사용자 비밀번호',
        advertiserLogin: '사용자가 성공적으로 추가되었습니다. 광고주 플랫폼 비밀번호로 로그인하세요.',
        done: '완료',
        accountORcompany: '계정/회사명 입력',
        selectRole: '역할을 선택하세요',
        selectBMaccount: 'BM 계정을 선택하세요',
        selectWritable: '쓰기 가능한 계정을 선택하세요',
        selectReadable: '읽기 가능한 계정을 선택하세요',
        accAuthorization: '계정 권한',
        enterAmountCoupon: '충전할 쿠폰 금액을 입력해주세요',
        enterBM: '비즈니스 매니저를 입력하세요',
        enterIndustryClassification: '업종 카테고리를 입력하세요',
        enterIndustry: '업종을 입력하세요',
        selectAM: '계정 관리자를 선택하세요',
        selectContractType: '계약 유형을 선택하세요',
        selectAccountLanguage: '계정 언어를 선택하세요',
        selectCompanyType: '회사 유형을 선택하세요',
        addressCompany: '회사 운영 주소를 입력하세요',
        enterAddress: '업체등록주소를 입력하세요',
        enterValid: '유효한 환불율을 입력하세요',
        selectAppropriate: '적절한 대행사 계정을 선택하세요',
        onHold: '처리 대기',
        cVROPC1: '이 CVR최적화는 캠페인 만들기중의',
        cVROPC2: 'OCPC 연동되어 신중하게 조정하세요',
        cVROPC3: '1) 캠페인 입찰이 고정 입찰인 경우 이 CVR 최적화 구성은 광고주가 인식할 수 없습니다. 광고주가 고정 입찰을 OCPC로 수정하는 경우, 이 CVR 최적화 구성은 OCPC의 기본 구성으로 사용됩니다.',
        cVROPC4: '2) 캠페인 입찰이 OCPC인 경우, 이 CVR 최적화 구성은 OCPC와 연동되어 광고주가 인지하게 되어 신중히 사용하세요.',
        algorithmic: '알고리즘 최적화 개체, ',
        changedAfterSetting: '설정 후 변경 불가',
        cpa: '0보다 큰 숫자로 입력하세요',
        completed: '완료됨',
        pleaseEnter1: '입력하세요',
        profitRatio: '이익률은 숫자로 입력하세요',
        yueBao1: '(내부자료, 유출하지 마세요)',
        yueBao3: '운영 레드라인, 소재 및 상세 데이터 표시기는 내부적으로만 확인할 수 있으며 광고주한테 공유하지 마세요',
        dailySubsidy: '보조 스카이 레벨 손실의 상한은 0 이상의 숫자여야 합니다.',
        totalSubsidy: '보충 총 손실의 상한은 0 이상의 숫자여야 합니다.',
        accelerate: '가속 훈련 배수는 반드시 0-20 사이의 정수여야 합니다.',
        impBound: '가속 훈련 배수는 0-10000 사이의 정수여야 합니다.',
        contains: '포함',
        percentage: '비율',
        creation: '새로운',
        processing: '처리',
        accepting: '수락',
        clear: '삭제',
        mon: '월',
        tue: '화',
        wed: '수',
        thu: '목',
        fri: '금',
        sat: '토',
        sun: '일',
        selected: '선택된',
        unselected: '미선택',
        less1: 'Stripe 수수료가 부과되어 MediaGo로 충전되는 금액이',
        less2: '보다 작게 됩니다.',
        whenRecharge: '설정된 한도 소진되면 Stripe는 자동적으로 저장된 신용카드에서 일정 금액을 충전됩니다. 자동충전 금액은 자동충전에서 설정할 수 있습니다.',
        whenAmount: '계정소진금액에 설정한 금액만큼 계좌가 소진되면 Stripe는 저장된 신용카드에서 설정한 금액을 차감합니다. 설정된 자동충전금액이 설정된 계정소진금액보다 낮을 수 없음을 유의하시기 바랍니다.',
        autoBillingAmount: '자동 청구 금액',
        paymentDetails: '결제 상세 내역',
        dueAmount: '송금 수수료로 인해 실제 수령 금액은 결제 금액보다 적을 것으로 예상됩니다.',
        selectAdAccount: '광고 계정 선택',
        minimum: '최소 금액은 $50.00입니다.',
        transferAmount: '송금 수수료전액 지불(PayPal 수수료 포함)',
        paypal1: 'MediaGo에서',
        paypal2: '전액을 받기 위해서는 페이팔에서 청구하는 수수료 포함하여',
        paypal3: '를 지불하셔야 합니다.',
        amountPayable: '미지급액',
        transferFee: '송금 수수료',
        rechargeAmount: '충전 금액의 2.9% + 거래당 $0.30',
        actualAmount: '실제금액',
        cancel: '취소',
        payNow: '지금 결제',
        paymentHistoryDetails: '결제 내역 세부 사항',
        paymentAccount: '결제 계정',
        rechargeAmount1: '충전 금액',
        amountSpend: 'Amount Spend',
        account: '계정',
        PayPalmore1: '페이팔을 통한 기본 거래 수수료는 거래 금액의 2.9%에 건당 0.30달러가 추가되니 참고 바랍니다. ',
        PayPalmore2: '자세한 내용은 여기를 방문하세요.',
        automaticBilling: '자동 청구',
        automaticBilling1: '이 기능을 설정하면 Stripe는 다음 자동 결제를 위해 신용카드 정보를 자동으로 저장합니다.',
        monthlyOperationalReport: '월간 리포트',
        currentIncome: '당기 매출',
        percentageOfConsumption: '소진 비율',
        lastPeriodIncome: '상기 매출',
        yearOnYearLastWeek: 'WoW',
        yearOnYear: 'YoY',
        comparisonFunction1: '비교 기능 사용 팁:',
        comparisonFunction2: '1단계: 왼쪽에서 현재 기간 간격 선택',
        comparisonFunction3: '2단계: 오른쪽에서 비교 시작 시간 선택',
        display: '피드맵 데이터 펼치기',
        comparison: '비교',
        categoryOfEvent: '이벤트 카테고리',
        adAccount: '광고 계정',
        category: '카테고리',
        campaignName: '캠페인명',
        priority: '우선순위',
        status: '상태',
        accountManager: '계정 관리자',
        currentPersonInCharge: '현재담당자',
        addTracker: 'New Tracker',
        uploadImage: '이미지 업로드',
        blackFormat: 'Black Format',
        blackPageType: 'Black Page Type',
        forstyle: '스타일 필터링의 경우 ";"로 구분합니다. 예: infopane;river',
        forpage: 'PageType 필터링의 경우 ";"로 구분됩니다. 예: edgechrmtp;25;36',
        startDate: '고객 소스',
        accountSource: '계정 소스',
        accountNature: '계정 종류',
        contractTitle: '계약 제목',
        aPIToken: 'APIToken',
        serviceType: '서비스 유형',
        businessManagement: '비즈니스 관리',
        advertiser: '광고주',
        aPIhasinstalled: 'API 설정됨',
        withoutSettingAPI: 'API 미설정',
        openAccountIndependently: '계정 만들기',
        inviteToOpenAccount: '계정 만들기 초대',
        searchName: '계정/회사명 검색',
        previewImgTitle: '삭제',
        accountName: '계정명',
        accountID: '계정 ID',
        companyName: '회사명',
        // country: '나라',
        country: '지역',
        billingEmail: '청구 이메일',
        organizationName: '조직명',
        businessRebate: '비즈니스 환불',
        email: '계정 소유자의 이메일',
        paymentMethod: '결제 방법',
        deliverymethod: '송출 방법',
        accountTimeZone: '계정 시간대',
        businessManager: '비즈니스 관리자',
        industryCategory: '산업 카테고리',
        creationTime: '작성 시간(EST)',
        operate: '조작',
        accountDetails: '계정 상세 정보',
        accountInformation: '계정 정보',
        organizationWebsite: '홈페이지 링크',
        accountHolderInformation: '계정 소유자 정보',
        totalAccountBudget: '계정 총 예산',
        timeZone: '시간대',
        company: '회사',
        mediaGoNon: '',
        registeredAddressOfCompany: '등록된 회사 주소',
        companyAddress: '회사 주소',
        emailaddress: '이메일 주소 입력하세요',
        prepay: '선불',
        postpay: '후불',
        mark1: '계정 생성 API 조건:1. 계정의 유형은 광고주 (2번째 확인) 입니다2. 계정의 상태가 열려 있습니다3. 계정의 소진은 5000달러에 달성 했습니다.',
        generateToken: 'Token 생성',
        mark2: '주의: Taboola에 송출된 광고는 API를 사용하여 데이터를 추출할 수 없습니다.',
        addUserRW: '사용자 추가 시 계정에 대한 사용자의 읽기 권한이 자동으로 활성화됩니다.',
        otherUsers: '기타 사용자',
        operator: '오퍼레이터',
        operatingTime: '가동 시간',
        specificIndustry: '구체적인 산업',
        add: '추가',
        automaticReporting: '자동 리포팅',
        reportTimeZone: '시간별 리포트',
        recipient: '수신인',
        sendingFrequency: '송출빈도',
        stopsending: '종료후 메일을 수신하지않기.',
        consistent: '계정 시간대와 일치',
        mark4: '복수의 메일을 , (콤마)로 나누어 입력 가능합니다.',
        daily: '매일',
        monthly: '매월',
        advertiserName: '광고주 이름',
        advertiserEmail: '광고주 이메일',
        advertiserApplication: '광고주 신청',
        loginInfo: '로그인 정보',
        applicationCompleted: '신청이 완료되었습니다',
        postpay1: '후불',
        accountType: '계정 유형',
        selfService: '셀프서비스',
        managed: '대행',
        reportingTimezone: '보고 시간대',
        selectthetimezone: '캠페인을 관리할 시간대를 선택하세요. 설정 후 수정할 수 없습니다.',
        organizationName1: '조직명',
        asthiswil: '청구서에 직접적인 영향을 미치기 때문에 공식 등록 서류를 반영할 수 있도록 조직 내역을 신중하고 정확하게 입력해주세요.',
        organizationAddress: '조직 주소',
        asthiswilldirectly: '청구서에 직접적인 영향을 미치기 때문에 공식 등록 서류를 반영할 수 있도록 조직 내역을 신중하고 정확하게 입력해주세요.',
        organizationWebsite1: '조직 홈페이지',
        initialCreditLine: '초기신용한도',
        ifyou: '초기신용한도를 0 이상으로 설정하면 AM 그룹과 사용자 모두에게 알림 이메일이 전송됩니다.',
        advertisernameisrequired: '광고주 이름이 필요합니다',
        registeraddressisrequired: '등록 주소를 입력해야 합니다',
        pleaseEnter: 'http: 또는 https:로 시작하는 올바른 회사 웹 사이트를 입력하세요',
        workEmail: '업무용 이메일',
        workEmail1: '(사용자 이름으로 사용됨)',
        firstName: '이름',
        lastName: '성',
        password: '비밀번호',
        pleaseenteravalidemailaddress: '올바른 전자 메일 주소를 입력하세요',
        pleaseenteravalidfirstname: '올바른 이름을 입력하세요',
        pleaseenteravalidlastname: '올바른 성을 입력하세요',
        specialcharacterandnumber: '문자, 특수 문자 및 숫자를 조합하여 8자 이상 입력하세요',
        next: '다음 페이지',
        previous: '이전 페이지',
        submit: '제출',
        officialWebsite: '공식 웹사이트',
        pleaseenteryouremail: '이메일을 입력하세요',
        pleaseentercompanyURL: '회사 URL을 입력하세요',
        pleaseentertheaccountname: '계정 이름을 입력하세요',
        pleaseenterbudget: '예산을 입력하세요',
        deliveryMethod: '송출 방법',
        pleaseselectacompany: '회사를 선택하세요',
        addCompany: '회사 추가',
        ifthere: '옵션에 귀사가 없는 경우 추가 후 다시 선택하세요',
        customerSource: '고객 소스',
        terminalclient: '터미널 클라이언트',
        agent: '대행사',
        contractType: '계약 유형',
        contractType2: '계약 유형',
        adding: '사용자를 추가하면 열람 권한이 자동으로 활성화됩니다.',
        pleasechooseanotherrole: '다른 역할을 선택하세요',
        registeredaddressofthecompany: '회사등록주소',
        companyBAddress: '회사주소',
        bysubmitt: '본 양식을 제출함으로써 MediaGo의 개인정보 보호정책 및 약관에 동의합니다.',
        authorizedUser: '인증된 사용자',
        unauthorizedUser: '인증되지 않은 사용자',
        name: '이름',
        usersource: '사용자 소스',
        loginMethod: '로그인 방법',
        workEmail2: '업무용 이메일',
        companyName1: '회사명(가입시)',
        position: '직위',
        numberOfWritableAccounts: '작성 가능한 계정 수',
        numberOfReadableAccounts: '열람 가능한 계정 수',
        role: '역할',
        searchCompanyName: '회사명+이름+이메일 검색',
        userInvitationLink: '사용자 초대 링크',
        details: '세부 사항',
        userRegistrationInformation: '사용자 등록 정보',
        userSource: '사용자 소스',
        lastName1: '성',
        name1: '이름',
        companyWebsite: '회사 웹 사이트',
        telephone: '전화',
        userRole: '사용자 역할',
        accountAllocation: '계정 나누기',
        bMAccount: 'BM 계정',
        writableAccount: '작성 가능한 계정',
        readableAccount: '열람 가능한 계정',
        addingordeletinga: 'BM 계정을 추가하거나 삭제하면 BM 계정으로 모든 대행사 계정에 대한 사용자의 읽기 권한에 영향을 미칩니다. 주의하세요.',
        edit: '편집',
        ordinal: '순번',
        roleName: '역할명',
        assignedNumber: '분배 인원수',
        changeTime: '시간 변경',
        createRole: '역할 만들기',
        pleaseentertherolename: '역할명을 입력하세요',
        mediaGoAdvertiser: 'MediaGo 광고주',
        baiduReviewer: 'Baidu 심사원',
        microsoftReviewer: 'MSN 심사원',
        baiduRD: 'Baidu RD',
        baiduBD: 'Baidu BD',
        baiduAM: 'Baidu AM',
        financialStaff: '재무',
        admin: 'Admin',
        dataAnalyst: '데이터 분석가',
        mGIDReviewer: 'MGID 심사원',
        parentologyReviewer: 'Parentology 심사원',
        helpCenter编辑: '도움말 센터 편집',
        financialReceivable: '재무미수',
        roleManager: '역할 관리자',
        prebidTestReviewer: 'Prebid 테스트 심사원',
        rTBDemandReviewer: 'RTB 심사위원',
        zipporReviewer: 'Zippor 심사원',
        adPushupReviewer: 'AdPushup 심사위원',
        advertiser1: '대행사 광고주',
        pubGeniusReviewer: 'PubGenius 심사원',
        insticatorReviewer: 'Insticator 심사원',
        streamAmpReviewer: 'StreamAmp 심사원',
        financialStaffPaypal: '재무결제-PayPal',
        eCommerceAM: '전자상거래 AM',
        eCommerceROIReport: '전자상거래 ROI 리포트',
        adminR: 'Admin-R (읽기)',
        internalTestAdvertiser2: '내부 테스트 광고주2',
        offlineProject: '재무-오프라인 프로젝트',
        bulkReview: '일괄심사',
        domainWhitelist: 'mobile domain 화이트리스트',
        financialReport: '재무리포트',
        stripeAutoBilling: '내부 테스트 광고주 - 스트립 자동발급',
        discoveryAdvertiser: 'Discovery 광고주',
        discoveryFinancalTest: 'Discovery 재무 테스트',
        discoveryOldAdvertisers: 'Discovery 구 광고주',
        discoveryOldAgency: 'Discovery 구 대행사광고주',
        oldDiscoveryAgency: 'Discovery 구 대행사',
        discoveryNewadvertisers: 'Discovery 신규 광고주',
        platformExperienceRead: '플랫폼 체험 전용 - Read',
        bidbydomain: '도메인 별 비딩',
        newDiscoveryAdvertiser: 'Discovery 신규 광고주',
        dashboard: '대시보드',
        reviewDetails: '심사 내역',
        campaignDetails: '캠페인 세부 사항',
        campaignCopy: '캠페인 복사',
        campaignEdit: '캠페인 편집',
        adStatus: '광고 송출 상태',
        domainBlock: '도메인 블록',
        domainTabsspColumn: 'ssp유형 도메인 페이지',
        reviewDetails1: '심사내역 매체분류',
        adColumnSize: '광고 설정열 크기 옵션',
        dimensionFilter: '멀티필터',
        conversiontype: '전환 유형',
        oCPC: 'OCPC',
        domainSetCampaignCpc: '도메인별 CPC',
        eCPC: 'ECPC',
        adOperation: '운영 플랫폼',
        analysis: '운영분석',
        blackwhiteconfig: '블랙화이트 리스트 설정',
        campaignOperationConfig: '캠페인 운영 설정',
        taboolaAdPull: 'Taboola Ad Pull',
        blacklistconfig: '캠페인 PageId 블랙리스트 설정',
        reminder: '캠페인 이상 알림',
        log: '캠페인 조작기록',
        advertiserOperationRecord: '광고주 운영기록',
        multiDimensionalreport: '멀티 리포트',
        tracker: 'Tracker',
        bidAnalysis: '입찰 분석',
        diagnosis: '광고 진단',
        innerFAQ: '내부 FAQ',
        sample: '샘플',
        newCampaign: '새 캠페인',
        win10adTargeting: 'win10 ad-targeting',
        userPackage: 'userPackage',
        more: 'more',
        // domainwhitelist: '도메인 화이트리스트',
        impURL: 'impURL',
        // locationJapan: 'locationJapan',
        // monthlyCapStatus: '월예산',
        // objective: '목표',
        // audience: '타겟 오디언스',
        pixel: 'Pixel',
        conversionlist: '전환 리스트',
        readlist: 'Read 리스트',
        newConversion: '전환 만들기',
        dataReport: '데이터 리포트',
        lPManagement: 'LP 관리',
        oPManagement: 'OP 관리',
        productManagement: '제품관리',
        domainManagement: '도메인 관리',
        customerManagement: '고객관리',
        rOIAnalysis: 'ROI 보고서',
        paymentManagement: '결제 관리',
        reviewAds: '광고심사',
        startReviewing: '심사 시작',
        detail: '세부 사항',
        category1: '카테고리',
        geoEdge: 'Geo Edge',
        sensitiveCategory: 'Sensitive 카테고리',
        auditLabel: '리뷰 태그',
        displayallauditedimg: '검토된 이미지 모두 표시',
        authorization: '권한관리',
        accountList: '계정관리',
        addAccount: '계정 추가',
        link: '가입 초대 링크',
        authorize: '권한 승인',
        ignore: '무시하기',
        copyInvitationLink: '초대 링크 복사',
        bDAccountOpening: 'BD 계정 만들기',
        userList: '사용자 권한',
        roleList: '역할 관리',
        delete: '삭제',
        create: '생성',
        billing: '계정 충전',
        recharge: '충전',
        stripe: 'Stripe',
        autoBilling: '자동 충전',
        multiCurrencySwitcher: '멀티 통화 전환',
        finance: '재무관리',
        creditLine: 'Credit Line',
        creditLine1: 'Credit Line',
        coupon: 'Coupon',
        rOIReport: 'ROI 리포트',
        receivable: '미수금',
        payable: '미지급금',
        offline: 'offline',
        bannerViewableTest: 'Banner Viewable Test',
        viewableTest: 'Viewable Test',
        hibernation: '휴면 상태',
        aDCallback: 'AD Callback',
        adBidConfig: '광고 비딩 설정',
        review: '심사',
        history: '내역',
        generateLPWrapper: '렌딩 페이지 생성',
        getEmail: '이메일 인증번호 발송요청',
        write: '쓰기',
        read: '읽기',
        currencyType: '통화 유형',
        open: '개인계좌등록',
        inviteTo: '계좌공유신청',
        uSD: 'USD',
        cNY: 'CNY',
        jPY: 'JPY',
        sGD: 'SGD',
        hKD: 'HKD',
        tWD: 'TWD',
        tHB: 'THB',
        iDR: 'IDR',
        kRW: 'KRW',
        mYR: 'MYR',
        totalBalance: '총 잔액',
        totalRecharge: '충전 금액',
        availablequota: '사용 가능한 한도',
        creditLineSetting: 'Credit Line 설정',
        creditLineRecord: 'Credit line 수정기록',
        rechargeRecord: '충전 기록',
        pleaseentertheamounttorecharge: '충전할 금액을 입력해주세요',
        asof: '마감',
        validAmount: '올바른 금액을 입력해 주세요',
        remark: '설명',
        pleaseenteraremark: '설명을 입력해주세요',
        uTSDataUpdateTime: 'UTS 데이터 업데이트 시간',
        totalCoupon: 'Coupon 총액',
        couponConsumption: 'Coupon 소진',
        effectiveCouponBalance: '유효 Coupon 잔액',
        effectiveTime: '유효시간',
        expireDate: '만료시간',
        offlineTime: '오프라인 시간',
        profitMarginSetting: '이익률설정',
        pleaseenterprofitmargin: '이익률을 입력하세요',
        profitMarginRecord: '이익률수정기록',
        currentIncome1: '당기수익',
        currentCost: '당기지출',
        currentProfit: '당기이익',
        profitMargin: '이익률',
        impression: '표시',
        clicks: '클릭수',
        totalHistory: '전체 내역',
        totalHistoricalIncome: '과거 총 매출',
        totalHistoricalCost: '총 과거 비용',
        historicalProfitMargin: '과거 이익률',
        lastUpdateTime: '마지막 업데이트 시간',
        enterAccountCompanyName: '계정회사명 입력',
        media: '송출 매체',
        currentIncome2: '당기매출=당기소진(coupon포함)',
        pullAds: 'Pull Ads',
        interfaceSetting: '인터페이스 설정',
        adInterface: '광고 인터페이스',
        testField: '테스트 필드',
        campaignId: '캠페인 ID',
        aDId: '광고ID',
        onlineEnvironment: '온라인 환경',
        testEnvironment: '테스트 환경',
        developmentEnvironment: '개발 환경',
        testNoBilling: '1-Test-No Billing',
        realBilling: '0-Real-Billing',
        multipleids: 'ID여러 개의 경우 콤마로 구분하세요. 예: 111,222',
        multipleids1: 'ID여러 개의 경우 콤마로 구분하세요. 예: 111,223',
        pageParameters: 'Page Parameters',
        placement: '플랫폼',
        pageURL: '페이지 URL',
        category2: '카테고리',
        adTypeSetting: '광고 유형 설정',
        thereserveprice: '준비 가격은 0이며 기본값은 0.43입니다.',
        fixedIndex: '고정 인덱스: 콤마로 구분, 기본 인덱스 기입 안 함',
        geographicalConfig: '위치설정',
        zipCode: '우편번호',
        iP: 'IP',
        language: '언어',
        unitedStatesState: '미국-주',
        noneMSNMayNotTransmit: '없음-MSN 전송 불가',
        stateData: '주별 데이터',
        deviceInformation: '장치 정보',
        userAgent: 'userAgent',
        personalInformation: '개인 정보',
        microsoftUID: 'Microsoft UID',
        copymuid: 'muid 복사',
        refreshmuid: 'muid 새로 고침',
        fuzzyMatch: 'Fuzzy Match',
        sspId: 'Ssp Id',
        selectRecallStatus: '리콜 상태 선택',
        notrecallable: '리콜 불가',
        recallable: '리콜 가능',
        inquire: '조회',
        column: '열 설정',
        campaign: '캠페인',
        ad: '광고',
        generate: '생성',
        getVerificationCode: '인증번호 발송요청',
        verificationCode: '인증번호',
        none: '없음',
        obtain: '요청',
        pid: 'Pid',
        priceStatus: '가격 상태',
        trainPriceStatus: '테스트 가격 상태',
        sSPId: 'SSP ID',
        imageSize: '이미지 크기',
        format: '서식',
        pidProfit: 'Pid 이익',
        cTRSwitch: 'CTR 스위치',
        priceMode: '가격 모드',
        toBeReviewed: '심사 대기',
        belowReservePrice: '최저가 미만',
        reset: '리셋',
        search: '검색',
        addPid: 'Pid 추가',
        startReview: '심사 시작',
        adId: '광고 ID',
        mediaAccount: '매체',
        mediaAccountName: '매체명',
        site: '사이트',
        creativeID: '광고소재ID',
        browser: '브라우저',
        platform: '플랫폼',
        oS: 'OS',
        stateRegion: '주 지역',
        cost: '원가',
        revenue: '수익',
        click: '클릭',
        vCPM: 'VCPM',
        cTR: 'CTR',
        avgCPC: '평균 CPC',
        reqAdNum: '광고 요청수',
        reqNum: '요청수',
        resNum: '응답수',
        conversions: 'CV',
        conversionRate: '전환율',
        conversionPrice: 'CV 단가',
        viewContent: '콘텐츠 보기',
        appInstall: '앱 설치',
        completeRegistration: '등록 완료',
        addToCar: '카트에 추가',
        addPaymentInfo: '결제정보를 추가해주세요',
        startCheckout: '체크아웃 시작',
        purchase: '구입',
        addToWishlist: '위시리스트에 추가',
        lead: '잠재적 사용자',
        other: '기타',
        addTitle: '제목 추가',
        benchmark1: 'Benchmark 선별 규칙:',
        benchmark2: '모든 카테고리의 캠페인을 내보내고 원시 데이터로 사용',
        benchmark3: '원시 캠페인 데이터 필터링',
        benchmark4: '승인된 캠페인 유지(거부 및 보관 제외)',
        benchmark5: '이달의 Vimp 10,000 이상, 클릭 100개 이상, 비용 100달러 이상의 캠페인 유지',
        benchmark6: '나머지 캠페인을 CPC, CTR별로 분류하여 CPC, CTR 범위를 파악합니다.',
        benchmark7: '우수한 소재 선별 규칙:',
        benchmark8: '모든 카테고리의 캠페인을 내보내고 원시 데이터로 사용',
        benchmark9: '원시 캠페인 데이터 필터링',
        benchmark10: '승인된 캠페인 유지(거부 및 보관 제외)',
        benchmark11: '카테고리별로 이번달 소진 Top10 캠페인 선별',
        benchmark12: 'Top 캠페인의 소재를 선별하여 vimp가 20,000 이상인 소재를 선별',
        benchmark13: '카테고리별로 Top 소재 선별: creative가 중복되지 않도록 creative가 중복되면 순위가 순연됩니다.',
        benchmark14: 'Content: 선별된 소재는 vctr 정렬에 따라 Top 10의 소재를 정렬합니다.',
        benchmark15: 'E-commerce: 선별된Cvr과 vctr에 따라 소재를 정렬합니다.',
        benchmark16: 'cvr 정렬 Top5의 소재를 선별',
        benchmark17: 'vctr 정렬 Top5의 소재를 선별',
        benchmark18: 'Finance: 선별된 소재는 vctr 정렬에 따라 Top 10의 소재를 정렬합니다.',
        locationJapan: '위치일본',
        monthlyCapStatus: '월예산',
        domainwhitelist: '도메인 화이트리스트',
        objective: '목표',
        audience: '타겟 /오디언스',
        remarkisrequired: '비고란은 비워둘 수 없습니다.',
        amountRequired: '전체 조정 금액을 입력하십시오',
        addUser: '사용자 추가',
        privacyPolicy1: '본 양식을 제출함으로써 MediaGo의',
        privacyPolicy2: '개인정보 보호정책',
        privacyPolicy3: '및',
        privacyPolicy4: '약관',
        privacyPolicy5: '에 동의합니다.',
        previous1: 'Thank you for registering!',
        previous2: 'If you have any further questions,',
        previous3: 'please contact',
        previous4: 'We’ll reply to you as soon as possible.',
        startAuthorizing: '승인 시작',
        searchbyAccountType: '계정 유형 검색',
        youImageHere: '여기에 귀하의 이미지',
        targetMCPA: '목표 MCPA',
        targetCPA: '목표 CPA',
        confirmSubmission: '제출된 후, 알고리즘 최적화 객체를 변경할 수 없다.제출 확인?',
        accountName1: '계정명',
        campaginID: '캠페인ID',
        currentManager: '현재 관리자',
        addRole: '역활추가',
        loginAddress: '로그인 주소',
        permanentlyDelete: '현재 작업은 해당 역할을 영구적으로 삭제합니다. [계속하기]를 클릭하세요',
        goon: '계속하기',
        verifyThat: 'API token 생성 확인',
        applicationTitle: '신청완료! 아래의 광고로그인 정보를 저장하세요',
        noApecificAccount: '특정 계정 없음',
        noMoreThan: '초과할 수 없음',
        pleaseSelectItemCategory: '카테고리를 선택하세요',
        pleaseSelectStatus: '상태를 선택하세요',
        pleaseSelectPriority: '우선순위를 선택하세요',
        pleaseSelectResponsiblePeople: '현재 담당자를 선택하세요',
        theAbnormalExposure: '비정상노출',
        blackFormat1: 'Black Format을 입력하세요',
        blackPlacement: 'Black Placement을 입력하세요',
        keyGoal: '핵심 타겟 길이는 100문자 이상일 수 없습니다',
        advertisingCategory: '송출 제품 길이 100문자 초과 불가',
        communicationChannel: '소통 채널 길이는 100문자 이상일 수 없습니다',
        otherInfo: '기타 정보 길이는 200자를 초과할 수 없습니다',
        expireTime: '만료 시간을 선택하세요',
        profitRate: '수정할 이익률을 입력하세요',
        amount: '충전할 금액을 입력하세요',
        editCreditline: '설정할 credit line을 입력하세요',
        recordValue: '현재 Credit Line',
        totalRecharge1: '누적충전금액',
        cannotBeModified: '확인 후 수정할 수 없습니다. 확인하시겠습니까?',
        checkNumber: '유효한 숫자를 입력하세요',
        password1: '최소 하나의 대/소문자',
        password2: '최소 하나의 특수 문자 (#@!~%^&*)',
        password3: '최소 하나의 숫자',
        password4: '8글자 이상',
        yueBao2: '크리에이티브 추천',
        undef: '정의되지 않음',
        affiliate: '제휴사',
        affiliateName: '제휴사명',
        billingPeriod: '청구기간',
        conversionDeduction: '전환공제',
        income: '수익',
        offerId: 'Offer ID',
        offerName: 'Offer 명',
        benchmark: '기준',
        content: '콘테츠',
        ecommerceCtr: '전자상거래CTR',
        ecommerceCvr: '전자상거래CVR',
        'lead gen': '잠재고객',
        noMore: '운영 기록 설명 길이 800문자 초과할 수 없습니다',
        noUndef: '운영 기록 설명은 비워 둘 수 없습니다',
        comparison1h: '1시간 비교',
        comparison24h: '24시간 비교',
        comparison1w: '1주일 비교',
        now: '지금',
        optimizationType: '알고리즘 최적화 대상을 설정하세요. 설정 후에는 변경할 수 없습니다',
        generateAnalysisReport: '분석 리포트 생성',
        reportEmail: '(리포트는 로그인 메일로 발송됩니다)',
        confirmSendingReport: '리포트 발송 확인',
        lineChart1: '표시할 수 있는 데이터가 없습니다',
        lineChart2: '광고ID 및 위치ID를 선택하여 데이터를 확인하시기 바랍니다',
        searchPlacementID: '위치ID 검색',
        sampleId: '샘플 ID',
        sampleName: '샘플명',
        bucketId: 'Bucket ID',
        description: '설명',
        conf: '설정',
        type: '유형',
        flow: 'flow',
        correctFormat: '올바른 형식을 입력하세요. 예: 12-20',
        noNumberLR: '왼쪽 또는 오른쪽은 숫자가 아님으로 주의하세요!',
        defaultHomePage: '기본 홈페이지',
        homePage: '홈페이지',
        articlePage: '문서 페이지',
        galleryPage: '갤러리 페이지',
        videoPlayerPage: '비디오 재생 페이지',
        kids: 'Kids',
        forGood: 'For Good',
        basePrice: '최저 가격, 미입력시 기본값 0로 설정됩니다. 예: 0.43',
        fixedIndex1: '고정 인덱스: 쉼표로 구분, 미입력시 기본 인덱스 1씩 증가, 예: 2, 3',
        'river-half': 'river-half',
        river: 'river',
        rivertall: 'rivertall',
        'river-dbl': 'river-dbl',
        infopane: 'infopane',
        flexaside: 'flexaside',
        copyDone: '복사 완료',
        checkStatus1: 'CPC Cap 상세 데이터',
        checkStatus2: '일별 지출',
        checkStatus3: '일별 Cap',
        checkStatus4: '총비용',
        checkStatus5: '총 Cap',
        checkStatus6: '특수 갬페인은 휴면상태로 설정할 수 없습니다',
        adStatus1: 'Ad상태 ON',
        dormancy: '휴면상태 ON | OFF',
        recallStatus: '리콜 가능 상태',
        clickCapStatus: 'CPC Cap 상태',
        impCapStatus: 'CPM Cap 상태',
        under: '예산 미소진',
        full: '예산 소진됨',
        timeStatus: '스케줄 상태',
        platoonPeriod: '스케줄 내',
        outside: '스케줄 외',
        uniformlyStatus: '표준 상태',
        uniformlyStatus1: '표준 속도로 소진됨, 통제중',
        campaignStatus: '캠페인 상태',
        allRejected: '전부 NG',
        allThrough: '전부 통과',
        'Approved+pendingPeview': '통과 + 심사대기',
        'Pass+Reject': '통과 + NG',
        currentMediaPass: '현재 매체 통과',
        currentMediaPendingReview: '현재 매체 삼사대기',
        currentMediaRejection: '현재 매체 NG',
        totalAccountBalanceStatus: '총 계정 잔액 상태',
        accountStatus: '계정 상태',
        auditPassSsps: '심사 통과 매체',
        index: '인덱스',
        newAdvertisingSpace: '광고 위치 추가',
        json: '본 입력창은 JSON 형식을 지원합니다',
        json1: '(다중 입력 지원)',
        json2: '올바른 JSON 수를 입력하세요',
        pidProfit1: '${i + 1}번째 줄 Pid Profit 값이 1보다 크거나 -1보다 작을 수 없습니다',
        beforeTheChange: '수정 전',
        afterTheChange: '수정 후',
        modifier: '수정자',
        modifiedTime: '수정 시간',
        newURL: '새 링크',
        taboolaLP: 'Taboola LP',
        pageId: 'Page Id',
        creationDate: '생성 시간',
        product: '제품',
        onLp: '팝업창 보유하기 위해 LP 열림 여부',
        onBanner: '고정 우측 배너 열림 여부',
        addNewProduct: '새로운 제품 추가',
        editProuduct: '제품 편집',
        deleteProduct: '제품 삭제',
        addNewDomain: '새 도메인명 추가',
        editDomain: '도메인명 편집',
        domainId: '도메인 ID',
        favicon: '아이콘',
        operations: '조작',
        tips: '권장 크기: 90*90',
        tips1: 'JPG / JPEG / PNG 형식의 이미지만 업로드 지원합니다',
        tips2: '이미지 크기가 2MB를 초과하지 않도록 주의하세요',
        enterDomainName: '도메인을 입력하세요',
        enterValidDomainName: '유효한 도메인을 입력하세요',
        pleaseUploadPictures: '이미지 업로드하세요',
        pleaseEnterFooter: 'Footer 업로드하세요',
        emailTemplate1: '주문서에서',
        emailTemplate2: '및',
        emailTemplate3: '주문서',
        domain: '도메인명을 선택하세요',
        emailAddress: '이메일 주소를 입력하세요',
        emailAddress1: '정확한 이메일 주소를 입력하세요',
        enterPassword: '이메일 비밀번호를 입력하세요',
        templateName: '이메일 템플릿명을 입력하세요',
        time: '시간주기를 선택하세요',
        storeLogo: '스토어 로고를 업로드하세요',
        storeName: '스토어명을 입력하세요',
        emailTitle: '메일 제목을 입력하세요',
        emailContent: '메일 본문을 입력하세요',
        emailImage: '메일 포스터를 업로드하세요',
        footer: '메일 Footer를 입력하세요',
        time1: '시간을 선택하세요',
        lastUpdateDate: '마지막 업데이트 시간',
        selectAccountName: '계정 이름 선택',
        selectProductName: '제품명 선택',
        selectCampaignName: '갬페인명 선택',
        onlineDate: '온라인 날짜',
        order: '주문',
        adSpendBudget: '광고예산소진',
        adSpend: '광고비용',
        atv: 'ATV',
        enterLandingPage: '랜딩 페이지 입력',
        enterOfferPage: 'Offer 페이지 입력',
        impCost: 'Imp Cost',
        merchandise: 'Merchandise',
        numberOfProduct: '제품 수',
        numberOfOrders: '주문 수',
        logisticFee: '배송비',
        productPrice: '제품 가격',
        startPurchase: '구매 시작',
        avgCTR: '평균 CTR',
        vimpCost: 'vimpCost',
        prodcutName: '제품명',
        totalAmount: '총합계',
        disputeAmount: '분쟁 금액',
        disputeRate: '분쟁율',
        disputeRate1: '분쟁율(분쟁일 기준)',
        disputeRate2: '분쟁율(청구일 기준)',
        paymentCount: '결제 건수',
        disputeCount: '분쟁 건수',
        disputeCount1: '중복된 분쟁 건수',
        disputeCount2: '사기성 분쟁 건수',
        disputeCount3: '일반 분쟁 건수',
        disputeCount4: '신용 미처리 분쟁 건수',
        notRecCount: '제품누락 분쟁 건수',
        productUnacceptableCount: '제품불량 건수',
        subscriptionCanceledDisputeCount: '구독 취소 수',
        unrecognizedDisputeCount: '기타 분쟁 건수',
        notReceivedDisputeAmount: '제품누락 분쟁금액',
        notAsDescribedDisputeAmount: '제품 불일치 분쟁금액',
        unauthorizedDisputeAmount: '미승인 분쟁금액',
        creditNotProcessedDisputeAmount: '신용미처리 분쟁금액',
        duplicateTransactionDisputeAmount: '중복거래 분쟁금액',
        incorrectAmountDisputeAmount: '잘못된 금액 분쟁금액',
        paymentByOtherMeansDisputeAmount: '기타 결제방식 분쟁금액',
        canceledRecurringBillingDisputeAmount: '구독취소 분쟁금액',
        problemWithRemittanceAmount: '송금금액 문제',
        otherDisputeAmount: '기타 분쟁금액',
        fraudAmount: '사기 분쟁금액',
        fraudRate: '사기 분쟁율',
        // 需要翻译
        comparison1d: '1d对比',
        comparison7d: '7d对比',
        comparisons: '周期对比'
    },
    serviceRecord: {
        tracker: 'Tracker',
        backgroundInformation: '배경 정보',
        edit: '편집',
        accountName: '계정 이름',
        accountHoldersEmail: '계정 소유자의 이메일',
        accountTimeZone: '계정 시간대',
        // country: '나라',
        country: '지역',
        industryCategory: '산업 카테고리',
        accountManager: '계정 관리자',
        businessManager: '비즈니스 관리자',
        coreTarget: '핵심 타겟',
        launchProduct: '제품 송출',
        communicationChannel: '통신 채널',
        otherInformation: '다른 정보',
        serviceRecord: '서비스 기록',
        serviceType: '서비스 유형',
        deliveryStatus: '송출 상태',
        meetingCommunication: '회의/소통',
        changeOfManager: '관리자 변경',
        other: '기타',
        timeline: '타임라인',
        operationRecordDescription: '운영 기록 설명',
        add: '추가',
        config: '계정 차원의 설정은 새 캠페인에 대해서만 적용됩니다. 이전 캠페인의 화이트리스트는 변경되지 않습니다.',
        whitelist: '화이트리스트',
        individualChoice: '개별 선택',
        supportbatch: '일괄 작성 지원,구분하려면;를 사용하세요',
        percentageOfMedia: '매체 비율',
        pleaseenter: '1-99를 입력하세요. 이 번호는 매체에서 발생한 캠페인 클릭률(단위: %) 입니다.',
        clientid: '클라이언트 ID:',
        clientSecret: '클라이언트 비밀번호:',
        accountid: '계정 ID:',
        added1: '추가됨:',
        profitMarginOperation: '이익률 운영',
        profitMargin: '이익률',
        replenishmentday: '보충일 레벨 손실 상한',
        topUptotallosscap: '총 손실 상한 보충',
        trainingOperations: '교육 운영',
        speedUpTrainingMultiple: '교육 속도 향상 다중화',
        retrainingthreshold: '재교육 임계값',
        cVROptimization: 'CVR 최적화',
        switch: '전환'
    },
    tracker: {
        trackerCategory: '사항 카테고리',
        priority: '우선순위',
        status: '상태',
        industryCategory: '산업 카테고리',
        onlyShow: '담당 Tracker만 표시',
        include: '이전 이력을 포함',
        afterchecking: '확인 후 관리자만 검색할 수 있고 계정은 검색할 수 없습니다.',
        newItem: '새 사항',
        ordinal: '순번',
        adAccount: '광고 계정',
        updateTime: '업데이트 시간',
        accountManager: '계정 관리자',
        currentManager: '현재 관리자',
        creationTime: '작성 시간',
        operate: '조작',
        update: '갱신',
        consumptionRiseAnalysis: '소진 증가 분석',
        consumptionFallAnalysis: '소진 감소 분석',
        lowBudget: '낮은 예산 소진',
        bugFeedback: '버그 피드백',
        functionOptimization: '기능 최적화 제안사항',
        material: '소재 디자인 요구사항',
        strategicSupport: '전략적 지원',
        technicalSupport: '기술 지원',
        conversionRelated: '전환 관련',
        other: '기타',
        request: '요청수',
        callback: '콜백수',
        imp: 'Imp',
        vimp: 'Vimp',
        click: '클릭',
        revenue: '매출',
        selectPublisher: '매체 선택',
        selectPage: '페이지 선택',
        selectFormat: '형식 선택',
        selectAccount: '계정 선택',
        selectCampaign: '캠페인 선택',
        search: '검색',
        reset: '리셋',
        byPublisher: '매체별',
        byPage: '페이지별',
        byFormat: '형식별',
        byAccount: '계정별',
        byCampaign: '캠페인별',
        more: '더 보기',
        columns: '열',
        publisherName: '매체명',
        page: '페이지',
        format: '서식',
        accountName: '계정명',
        campaignName: '캠페인명',
        callbackRate: '콜백율',
        winRate: 'Win Rate',
        viewableRate: 'Viewable Rate',
        iCTR: 'iCTR',
        vCTR: 'vCTR',
        callbackRateROC: '콜백율 ROC',
        winRateROC: 'Win Rate ROC',
        viewableRateROC: '시청 가능 요금 ROC',
        vCTRROC: 'vCTR ROC',
        iCTRROC: 'ICTR ROC',
        cost: '비용',
        rOI: 'ROI',
        icpm: 'iCPM',
        cRpm: 'cRPM',
        iRpm: 'iRPM',
        revenueROC: 'Revenue ROC',
        revenueDiff: '매출 차이',
        requestDiff: '요청 차이',
        callbackDiff: '콜백 차이',
        impDiff: 'imp 차이',
        vimpDiff: 'vimp 차이',
        clickDiff: '클릭 차이',
        profit: '이익',
        profitDiff: '이익 차이',
        accountID: '계정 ID',
        campaignID: '캠페인 ID',
        accountNameData: 'accountNameData',
        conversion: 'CV',
        conversionDiff: 'CV 차이',
        conversionRate: 'CVR',
        conversionRateROC: 'CV ROC',
        costPerConversion: 'CPA',
        costPerConversionDiff: 'CV당 비용 차이',
        viewContent: '콘텐츠 보기',
        appInstall: '앱 설치',
        completeRegistration: '등록 완료',
        addToCar: '카트에 추가',
        addPaymentInfo: '결제 정보 추가',
        startCheckout: '체크아웃 시작',
        purchase: '구매',
        addToWishlist: '위시리스트에 추가',
        lead: '잠재적 사용자',
        submissionDate: '소재 송출 날짜',
        reviewDate: '심사 날짜',
        startDate: '시작 날짜',
        endDate: '종료 날짜',
        geoEdgeAlert: 'GeoEdge Alert'
    },
    monthlyBill: {
        downloadTemplate: '템플릿 다운로드',
        initData: '초기 데이터',
        currentData: '현재 데이터',
        operationLog: '조작 기록',
        placeholderTip: 'Company Name/User Name을 입력하여 검색하십시오',
        uploadErrorTip: '업로드에 실패했습니다. 수정 후 다시 업로드하십시오.',
        uploadTip: '편집 가능한 부분만 업로드 지원',
        uploadTypeTip: 'csv형식만 지원',
        selectFlie: '파일 선택',
        uploadResult: '업로드 결과',
        editTip: ' 는 ',
        editFormTip: ' 를 ',
        editAfterTip: ' 로 수정했습니다. ',
        editToTip: ' 에서 ',
        remark: '비고',
        upload: '가져오기',
        line: '제'
    },
    // AI-CreativeGo
    aiCreativeGo: {
        Japan: 'Japan',
        twd: '台湾',
        hk: '香港',
        my: '马来西亚',
        Japanese: 'Japanese',
        chinese: '简体中文',
        zhHant: '繁体中文',
        // title
        hearderTitle: 'Title Generation',
        contentTitle: 'AI Generated Title',
        // form-labels
        landingPage: 'Landing Page',
        location: 'Location',
        language: 'Language',
        sellingPoint: 'Selling Point',
        titleSample: 'Title Sample',
        audience: 'Audience',
        gender: 'Gender',
        age: 'Age',
        productName: 'Product Name',
        campaignCategory: 'Campaign Category',
        // btns
        createBtn: 'Generate Title',
        copyAllBtn: 'Copy All Titles',
        // tips
        landingPageNoneTip: 'Landing Page can not be empty',
        landingPageRuleTip: 'Landing Page input box does not support <>',
        languageNoneTip: 'Please select a language',
        sellingPointTip: 'Support batch writing, separated by ,',
        titleSampleTip: 'Please provide 3 titles for popIn Creative Maker to learn, 1 line for each title',
        copied: 'Copied',
        requestErrorTip: 'The page is busy, please try again later',
        all: 'All',
        // 目标受众-option:
        officeWorkers: 'Office Workers',
        Homemakers: 'Homemakers',
        elderlyPeople: 'Elderly people',
        sportsEnthusiasts: 'Sports enthusiasts',
        investors: 'Investors',
        entrepreneurs: 'Entrepreneurs',
        artistsAndDesigners: 'Artists and designers',
        technologyEnthusiasts: 'Technology enthusiasts',
        travelEnthusiasts: 'Travel enthusiasts',
        // gender-option:
        male: 'Male',
        female: 'Female',
        // age-option:
        ageRange1: 'Under 18',
        ageRange2: '18-24',
        ageRange3: '25-44',
        ageRange4: '45-64',
        ageRange5: '65-85',
        ageRange6: '85+',
        // campaignCategory-option
        // aiCreativeGo下所对应的日文较constants的更为准确
        autoselectronics: '자동차 및 전자 제품',
        business: '비지니스',
        beautyFashion: '미용&패션',
        entertainment: '엔터테인먼트',
        economyMoney: '경제&돈',
        election: '선거',
        education: '교육',
        finance: '금융',
        foodDrink: '음식&음료',
        homepage: '홈페이지',
        health: '건강',
        homeLifestyle: '집&생활',
        loveMarriage: '사랑&결혼',
        music: '음악',
        movies: '영화',
        news: '뉴스',
        politicsSocial: '정치&사회',
        sports: '스포츠',
        travel: '여행',
        TV: 'TV',
        video: '비디오',
        weather: '날씨'
    },
    ...state,
    ...country
};